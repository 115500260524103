import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ErrorMessage, Form, Formik } from "formik";
import { TbPoint } from "react-icons/tb";
import BreadCrumb from "../../components/BreadCrumb";
import Button, { SecondaryButton } from "../../components/Button";
import SidebarField from "../../components/Sidebar/SidebarField";
import Lable from "../../components/Lable";
import TextInput from "../../components/TextInput";
import ToggleField from "../../components/ToggleField";
import { GoDotFill } from "react-icons/go";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import LoaderContext from "../../components/loader";
import { modifier, modifierCategory } from "../../apis/ProductListing.apis";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import Tooltip from "../../components/Tooltip/Tooltip";

const ModifierCategory = () => {
  const { handleLoading } = useContext(LoaderContext);
  const { theme } = useSelector((state) => state.theme);

  const [visible, setVisible] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addModifier, setAddModifier] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [modifierCategorys, setModifierCategorys] = useState("");
  const [modifiers, setModifiers] = useState("");
  const [editModifierCategoryData, setEditModifierCategoryData] = useState("");
  const [editModifierData, setEditModifierData] = useState("");
  const [modifierCategoryId, setModifierCategoryId] = useState(null);

  const handleDeleteOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    setEditModal(false);
  };
  const handleEditModalClose = () => {
    setEditModal(false);
  };
  const handleAddModifierOpen = (item = false) => {
    console.log(item);
    if (item) {
      setEditModifierData(item);
    }

    setAddModifier(true);
  };
  const handleAddModifierClose = () => {
    setAddModifier(false);
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Item List values", values);
    handleLoading(true);
    if (addModifier) {
      values.modifier_category = editModifierCategoryData._id;
      try {
        const createRes =
          editModifierData && editModifierData._id
            ? await modifier.updateModifie(values, editModifierData._id)
            : await modifier.createModifier(values);
        console.log(createRes.data?.data);
        getModifierCategoryById(modifierCategoryId);
        getAllModifierCategory();
      } catch (err) {
        console.log(err);
      } finally {
        resetForm();
        setEditModifierData("");
        setAddModifier(false);
        handleLoading(false);
      }
      return;
    }
    if (visible || editModifierCategoryData !== "") {
      try {
        const createRes = editModifierCategoryData
          ? await modifierCategory.updateModifierCategory(
              values,
              editModifierCategoryData._id
            )
          : await modifierCategory.createModifierCategory(values);
        console.log(createRes.data?.data);
        getAllModifierCategory();
      } catch (err) {
        console.log(err);
      } finally {
        resetForm();
        setEditModifierCategoryData("");
        handleClose();
      }
    }
    handleLoading(false);
  };

  const handleModifiercategoryEdit = (item) => {
    console.log("item", item);
    setEditModifierCategoryData(item);
    getModifierCategoryById(item._id);
    setEditModal(true);
  };

  const handleModifiercategoryDelete = (item) => {
    console.log("item", item);
    if (item.modifier) {
      setEditModifierCategoryData(item);
    } else {
      setEditModifierData(item);
    }
    handleDeleteOpen();
  };

  const handleDelete = async () => {
    console.log("editModifierCategoryData", editModifierCategoryData);
    handleLoading(true);

    if (editModal) {
      try {
        const Res = await modifier.deleteModifie(editModifierData._id);
        console.log(Res.data?.data);
        getModifierCategoryById(editModifierData.modifier_category?._id);
        getAllModifierCategory();
      } catch (err) {
        console.log(err);
      } finally {
        setEditModifierData("");
        handleLoading(false);
        handleDeleteClose();
      }
      return;
    }

    if (!editModal) {
      try {
        const Res = await modifierCategory.deleteModifierCategory(
          editModifierCategoryData._id
        );

        console.log(Res.data?.data);
        getAllModifierCategory();
      } catch (err) {
        console.log(err);
      } finally {
        setEditModifierCategoryData("");
        handleLoading(false);
        handleDeleteClose();
      }
    }
  };

  // @Get All API @Start
  const getAllModifierCategory = async () => {
    handleLoading(true);
    try {
      const res = await modifierCategory.getModifierCategory();
      console.log("modiferCategoryData: ", res);
      setModifierCategorys(res.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      handleLoading(false);
    }
  };

  const getModifierCategoryById = async (id = false) => {
    handleLoading(true);
    setModifierCategoryId(id);
    const ModifierId = id ? id : editModifierCategoryData._id;
    try {
      const res = await modifierCategory.getModifierCategoryById(ModifierId);
      setModifiers(res.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      handleLoading(false);
    }
  };
  // @Get All API @end

  useEffect(() => {
    getAllModifierCategory();
  }, []);

  return (
    <>
      <div className="p-4">
        <div className="flex justify-between items-center flex-wrap mb-2">
          <BreadCrumb title={"Modifier Category"} />
          <Button
            text="Add New"
            type="button"
            className="float-end"
            onClick={handleOpen}
          />
        </div>
        <div
          className={`${
            theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
          } shadow-2xl rounded-xl w-full p-4`}
        >
          {modifierCategorys
            ? modifierCategorys.map((item, i) => (
                <div className="border-b px-2 py-4" key={i}>
                  <p className="flex gap-2 justify-between">
                    <span className="flex gap-2 items-center text-buttonColor font-semibold text-md">
                      <TbPoint className="text-green-800 text-xl" />
                      {item.name}
                    </span>
                    <div className=" flex gap-2">
                      <SecondaryButton
                        onClick={() => handleModifiercategoryEdit(item)}
                        text="Edit"
                      />
                      <SecondaryButton
                        onClick={() => handleModifiercategoryDelete(item)}
                        borderColor="border-red-500"
                        text="Delete"
                      />
                    </div>
                  </p>
                  <div>
                    {item.modifier && item.modifier.length
                      ? item.modifier.map((value, j) => (
                          <span className="text-[12.5px]" key={j}>
                            {value.name}&nbsp;|&nbsp;
                          </span>
                        ))
                      : ""}
                  </div>
                </div>
              ))
            : ""}
        </div>

        {visible && (
          <Formik
            initialValues={{ name: null, is_visible: false }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, handleSubmit }) => (
              <Form>
                <SidebarField
                  button1={
                    <Button text="Cancel" type="button" onClick={handleClose} />
                  }
                  button2={
                    <Button type="submit" text="Save" onClick={handleSubmit} />
                  }
                  handleClose={handleClose}
                  title="Add New Modifier Category"
                >
                  <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                    <div>
                      <div className="flex">
                        <Lable lable="Modifier Category Name" />
                        <Tooltip text="Give a name to your Modifier Category" />
                      </div>
                      <TextInput
                        name="name"
                        type="text"
                        placeholder="Modifier Category Name"
                        onChange={handleChange}
                        required={true}
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div>
                      <ToggleField
                        text="Is modifier category visible to user ?"
                        name="is_visible"
                        onChange={handleChange}
                        value={values.is_visible}
                      />
                    </div>
                  </div>
                </SidebarField>
              </Form>
            )}
          </Formik>
        )}
        {editModal && (
          <Formik
            initialValues={{
              name: editModifierCategoryData.name || null,
              is_visible: editModifierCategoryData.is_visible || false,
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, handleSubmit }) => (
              <SidebarField
                button1={
                  <Button
                    text="Add Modifier"
                    type="button"
                    onClick={handleAddModifierOpen}
                  />
                }
                button2={
                  <Button text="Save" type="submit" onClick={handleSubmit} />
                }
                handleClose={handleEditModalClose}
                title="Edit Modifier Category"
              >
                <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                  <div>
                    <div className="flex">
                      <Lable lable="Modifier Category Name" />
                      <Tooltip text="Enter a name for Modifier Category" />
                    </div>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="Modifier Category Name"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <ToggleField
                      text="Is modifier category visible to user?"
                      name="is_visible"
                      onChange={handleChange}
                      value={values.is_visible}
                    />
                  </div>
                  <div>
                    <div className="flex">
                      <Lable lable={"Modifiers"} />
                      <Tooltip text="add more details of modifier" />
                    </div>
                    <div className=" w-full">
                      {editModifierCategoryData?.modifier?.map((item, i) => (
                        <div
                          key={i}
                          className=" border  flex justify-between mb-1 items-center text-[12px] "
                        >
                          <p className="w-1 border-r px-1 py-2  flex justify-center items-center">
                            {i + 1}
                          </p>
                          <p className="w-full  px-1 py-2 capitalize flex justify-left items-center">
                            <GoDotFill
                              style={{ color: "green" }}
                              className="text-md"
                            />{" "}
                            &nbsp;&nbsp;{item.name}
                          </p>
                          <p
                            className="w-1 border-l px-1 py-2  cursor-pointer  flex justify-center items-center"
                            onClick={() => handleAddModifierOpen(item)}
                          >
                            <MdEdit className="text-sm" />
                          </p>
                          <p
                            className="w-1 border-l px-1 py-2  cursor-pointer  flex justify-center items-center"
                            onClick={() => handleModifiercategoryDelete(item)}
                          >
                            <MdDeleteOutline className="text-sm" />
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </Form>
              </SidebarField>
            )}
          </Formik>
        )}
        {addModifier && (
          <Formik
            initialValues={{
              name: editModifierData.name || "",
              price: editModifierData.price?.$numberDecimal || "",
              sequence: editModifierData.sequence || "",
              is_visible: editModifierData.is_visible || false,
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, handleSubmit }) => (
              <SidebarField
                button1={
                  <Button
                    text="Back"
                    type="button"
                    onClick={handleAddModifierClose}
                  />
                }
                button2={<Button text="Save" onClick={handleSubmit} />}
                handleClose={handleAddModifierClose}
                title="Add New Modifier Item"
              >
                <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                  <div>
                    <Lable lable="Modifier Name" />
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="Modifier Name"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <Lable lable="Price" />
                    <TextInput
                      name="price"
                      type="text"
                      placeholder="Price"
                      onChange={handleChange}
                      value={values.price}
                    />
                    <ErrorMessage
                      name="price"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <Lable lable="Sequence Number" />
                    <TextInput
                      name="sequence"
                      type="text"
                      placeholder="Sequence Number"
                      onChange={handleChange}
                      value={values.sequence}
                    />
                    <ErrorMessage
                      name="sequence"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div>
                    <ToggleField
                      text="Is modifier visible to user ?"
                      name="is_visible"
                      onChange={handleChange}
                      value={values.is_visible}
                    />
                  </div>
                </Form>
              </SidebarField>
            )}
          </Formik>
        )}
      </div>
      <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
        getAllModifierCategory={getAllModifierCategory}
      />
    </>
  );
};

export default ModifierCategory;
