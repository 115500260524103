import Api from "../utils/axios";

export const CategoryApi = {
  getCategory: (payload, search = "") =>
    Api.post(`product/get-all-category?search=${search}`, payload),
  getAllCategory: () => Api.get(`product/all-approved-category`),
  getSingleCategory: (id) => Api.get(`product/get-all-category/${id}`),
  createCategory: (payload) =>
    Api.post(`product/create-category`, payload, {
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    }),
  updateCategory: (payload, id) =>
    Api.put(`product/update-category/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteCategory: (id) => Api.delete(`product/delete-category/${id}`),

  // Food Apis @Start
  getAllFoodCategory: () => Api.get(`product/all-food-category`),
  // Food Apis @end
};

export const SubCategoryApi = {
  // getSubCategory: () => Api.post(`product/get-all-subcategory`),
  getSubCategory: () => Api.get(`product/all-approved-subcategory`),
  getFoodSubCategory: () => Api.get(`product/all-food-subcategory`),
  getSubSingleCategory: (id) => Api.post(`/get-subcategory-by-id/${id}`),
  createSubCategory: (payload) =>
    Api.post("product/create-subcategory", payload),
  updateSubCategory: (payload, id) =>
    Api.put(`product/update-subcategory/${id}`, payload),
  deleteSubCategory: (id) => Api.delete(`product/delete-subcategory/${id}`),

  // Food Apis @Start
  getAllFoodSubCategory: () => Api.get(`product/all-food-subcategory`),
  // Food Apis @end
};

export const modifierCategory = {
  createModifierCategory: (payload) =>
    Api.post("food-product/create-modifier-category", payload),
  updateModifierCategory: (payload, id) =>
    Api.put(`food-product/update-modifier-category/${id}`, payload),
  getModifierCategory: () => Api.get(`food-product/get-all-modifier-category`),
  getModifierCategoryById: (id) =>
    Api.get(`food-product/get-modifier-category-by-id/${id}`),
  deleteModifierCategory: (id) =>
    Api.delete(`food-product/delete-modifier-category/${id}`),
};

export const modifier = {
  createModifier: (payload) =>
    Api.post("food-product/create-modifier", payload),
  updateModifie: (payload, id) =>
    Api.put(`food-product/update-modifier/${id}`, payload),
  getModifie: () => Api.get(`vendor/get-all-modifier`),
  getModifierById: (id) => Api.get(`vendor/get-modifier-by-id/${id}`),
  deleteModifie: (id) => Api.delete(`food-product/delete-modifier/${id}`),
};

export const productApi = {
  createProduct: (payload) =>
    Api.post("product/create-product", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateProduct: (payload, id) =>
    Api.put(`product/update-product/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getAllProducts: (payload, search = "") =>
    Api.post(`product/get-all-products?search=${search}`, payload),
  getAllApprovedProduct: () => Api.get(`product/all-approved-product`),
  getProductById: (id) => Api.get(`product/get-product-by-id/${id}`),
  deleteProduct: (id) => Api.delete(`product/delete-product/${id}`),
  getSingleProduct: (productId) => Api.get(`user/get-product/${productId}`),
  getReviewForProduct: (id, payload) =>
    Api.post(`user/get-all-products-rating-review/${id}`, payload),

  // Food Apis @Start
  getAllFoodProduct: () => Api.get(`food-product/all-approved-product`),
  // Food Apis @end
}; // Fetching the data without JWT token

export const productVariantApi = {
  createProductVariant: (payload) =>
    Api.post("product/create-product-variant", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateProductVariant: (payload, id) =>
    Api.put(`product/update-product-variant/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getProductVariantById: (id) => Api.get(`product/get-product-variant/${id}`),
  getAllProductVariants: (productId) =>
    Api.get(`product/get-all-product-variant/${productId}`),
  deleteProductVariant: (id) =>
    Api.delete(`product/delete-product-variant/${id}`),
};

export const productImageApi = {
  createProductImages: (payload) =>
    Api.post("product/create-product-images", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getProductImageById: (id) => Api.get(`product/get-product-image/${id}`),
  getAllProductImages: (id) => Api.get(`product/get-all-product-images/${id}`),
  updateProductImage: (payload, imageId) =>
    Api.put(`product/update-product-image/${imageId}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteProductImage: (id) => Api.delete(`product/delete-product-image/${id}`),
};

export const TaxRegimeApi = {
  getTaxRegimeById: (id) => Api.get(`master/get-tax-regime/${id}`),
  getAllRegimeApi: () => Api.get(`master/get-all-tax-regime`),
  getAllTaxMaster: () => Api.get(`master/get-all-tax`),
};

export const ProductmodifierApi = {
  createModifierMapping: (payload) =>
    Api.post("food-product/create-modifier-mapping", payload),
  updateModifierMapping: (payload, id) =>
    Api.put(`food-product/update-modifier-mapping/${id}`, payload),
  getAllProductModifier: (productId) =>
    Api.get(`food-product/get-all-modifier-mappings/${productId}`),
  getModifierMappingById: (id) =>
    Api.get(`food-product/get-modifier-mapping-by-id/${id}`),
  deleteModifierMapping: (id) =>
    Api.delete(`food-product/delete-modifier-mapping/${id}`),
};
