import React from "react";
import { useSelector } from "react-redux";

function OrderInvoice({ invoiceData }) {
  const { theme } = useSelector((state) => state.theme);
  console.log('invoiceData',invoiceData);
  

  // Safe default values
  const orderId = invoiceData?.order_id || "N/A";
  const totalItemPrice = parseFloat(
    invoiceData?.total_item_price?.$numberDecimal || 0
  ).toFixed(2);
  const deliveryPrice = parseFloat(
    invoiceData?.total_delivery_price?.$numberDecimal || 0
  ).toFixed(2);
  const tipAmount = parseFloat(
    invoiceData?.tip_amount?.$numberDecimal || 0
  ).toFixed(2);
  const netTotal = parseFloat(
    invoiceData?.net_total?.$numberDecimal || 0
  ).toFixed(2);

  return (
    <div
      className={`p-6 rounded-lg shadow-lg transition-all duration-300 ${
        theme === "light"
          ? "bg-secondaryColor text-textColorLight"
          : "bg-darkThirsary text-darkSecondary"
      }`}
    >
      <div className="text-lg font-semibold mb-4 border-b pb-2">
        <span>Total: </span>
        <span className="text-xl text-accent">₹ {netTotal}</span>
        <span className="text-gray-500 text-sm float-right">
          #Invoice {orderId}
        </span>
      </div>

      <div className="border-t border-gray-200 pt-4">
        <table className="w-full text-left text-sm">
          <thead>
            <tr className="bg-gray-100">
              <th className="font-medium text-gray-600 py-2">ORDER DETAILS</th>
              <th className="font-medium text-gray-600 py-2">COUNT</th>
              <th className="font-medium text-gray-600 py-2">PRICE</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData?.orderDetails?.length > 0 ? (
              invoiceData.orderDetails.map((order) => (
                <tr key={order._id} className="hover:bg-gray-50">
                  <td className="py-2 pr-8">{order.product?.name || "N/A"}</td>
                  <td>
                    {order.quantity} x ₹{" "}
                    {parseFloat(order.price?.$numberDecimal || 0).toFixed(2)}
                  </td>
                  <td className="text-right">
                    ₹{" "}
                    {parseFloat(
                      order.price?.$numberDecimal * order.quantity || 0
                    ).toFixed(2)}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="py-2 text-center text-gray-500">
                  No order details available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="border-t border-gray-200 mt-4 pt-4">
        <div className="flex justify-between text-sm mb-2">
          <span>Cart total :</span>
          <span>₹ {totalItemPrice}</span>
        </div>
        <div className="flex justify-between text-sm mb-2">
          <span>VAT/tax :</span>
          <span>₹ 0</span>
        </div>
        <div className="flex justify-between text-sm mb-2">
          <span>Delivery price :</span>
          <span>₹ {deliveryPrice}</span>
        </div>
        <div className="flex justify-between text-sm mb-2">
          <span>Tip amount :</span>
          <span>₹ {tipAmount}</span>
        </div>
        <div className="flex justify-between text-sm font-semibold mt-2 border-t border-gray-200 pt-2">
          <span>Total item price :</span>
          <span>
            ₹{" "}
            {parseFloat(
              totalItemPrice + parseFloat(deliveryPrice) + parseFloat(tipAmount)
            ).toFixed(2)}
          </span>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <span>Discount :</span>
          <span>₹ 0</span>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <span>Store profit :</span>
          <span>₹ 0</span>
        </div>
        <div className="flex justify-between text-sm font-semibold mt-2 border-t border-gray-200 pt-2">
          <span>Total:</span>
          <span>₹ {netTotal}</span>
        </div>
      </div>
    </div>
  );
}

export default OrderInvoice;
