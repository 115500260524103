const userData = localStorage.getItem("persist:user");
let deliveryBusiness;

if (userData) {
  const user = userData && JSON.parse(userData);
  // console.log('----->',JSON.parse(user.store));
  const currentUser = JSON.parse(user.store);
  console.log("----->", currentUser?.currentUser?.delivery_business);
  deliveryBusiness = currentUser?.currentUser?.delivery_business;
}

const data = [
  {
    icon: "BsShop",
    title: "Dashboard",
    path: "dashboard",
  },
  {
    icon: "SlNotebook",
    title: "Order",
    path: "order/all-orders",
    submenu: [],
    accordian: [
      {
        accordianTitle: "Order",
        accordianmenu: [
          {
            title: "All Orders",
            path: "order/all-orders",
            icon: "MdContentPaste",
          },
          {
            title: "Pending Order",
            path: "order/pending-orders",
            icon: "GiSandsOfTime",
          },
          {
            title: "Accepted Order",
            path: "order/accepted-orders",
            icon: "MdToday",
          },
          {
            title: "Delivered",
            path: "order/delivered-orders",
            icon: "CiDeliveryTruck",
          },
          // {
          //   title: "Dispatch",
          //   path: "order/dispatch",
          //   icon: "BsCartCheckFill",
          // },
          {
            title: "Return Order",
            path: "order/return-order",
            icon: "TbTruckReturn",
          },
          {
            title: "Cancel Order",
            path: "order/cancel-order",
            icon: "MdOutlineCancel",
          },
          {
            title: "Replacement",
            path: "order/replacement",
            icon: "TbZoomReplace",
          },
        ],
      },
      {
        accordianTitle: "Review",
        accordianmenu: [
          // {
          //   "title": "History",
          //   "path": "history/history-list",
          //   "icon": "RiFolderHistoryFill"
          // },
          {
            title: "Store Reviews",
            path: "order/store-reviews",
            icon: "CiStar",
          },
          {
            title: "Product Reviews",
            path: "order/product-reviews",
            icon: "CiStar",
          },
        ],
      },
    ],
  },
  // {
  //   icon: "GiCoins",
  //   title: "Earning",
  //   path: "earning/earnings",
  //   submenu: [
  //     // {
  //     //   "icon": "SlCalender",
  //     //   "title": "Current Week",
  //     //   "path": "earning/current-week"
  //     // },
  //     // {
  //     //   "icon": "VscCalendar",
  //     //   "title": "Current Month",
  //     //   "path": "earning/current-month"
  //     // },
  //     {
  //       icon: "AiOutlineProduct",
  //       title: "Earning",
  //       path: "earning/earnings",
  //     },
  //   ],
  //   accordian: [],
  // },
  {
    icon: "SlNotebook",
    title: "Menu",
    path:
      deliveryBusiness?.name === "Ecommerce"
        ? "menu/item-list"
        : "menu/item-food-list",
    submenu: [],
    accordian: [
      {
        accordianTitle: "Menu",
        accordianmenu: [
          {
            icon: "FaSitemap",
            title: "Category",
            path: "menu/category",
          },
          deliveryBusiness?.name === "Ecommerce"
            ? {
                icon: "CgNotes",
                title: "Item",
                path: "menu/item-list",
              }
            : {
                icon: "CgNotes",
                title: "Food Item",
                path: "menu/item-food-list",
              },
        ],
      },
      // {
      //   accordianTitle: "Modifier",
      //   accordianmenu: [
      //     {
      //       icon: "SiModin",
      //       title: "Modifier Category",
      //       path: "menu/modifier-group",
      //     },
      //     {
      //       icon: "MdImportExport",
      //       title: "Import Export",
      //       path: "menu/import-data",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   "icon": "ImUsers",
  //   "title": "Users",
  //   "path": "users/sub-stores",
  //   "submenu": [
  //     {
  //       "icon": "FaStore",
  //       "title": "Sub Stores",
  //       "path": "users/sub-stores"
  //     }
  //   ],
  //   "accordian": []
  // },
  {
    icon: "AiOutlineTag",
    title: "Offers",
    path: "offers/promocode",
    submenu: [
      {
        icon: "FaStore",
        title: "Create Promo",
        path: "offers/promocode",
      },
      {
        icon: "FaStore",
        title: "Create Sale",
        path: "offers/sale",
      },
    ],
    accordian: [],
  },
  {
    icon: "RiAdvertisementLine",
    title: "Advertisement",
    path: "Promotion/advertisement-management",
    submenu: [
      {
        icon: "RiAdvertisementLine",
        title: "Advertise Management",
        path: "Promotion/advertisement-management",
      },
      // {
      //   "icon": "FaStore",
      //   "title": "Create Sale",
      //   "path": "offers/sale"
      // }
    ],
    accordian: [],
  },
  {
    icon: "CiSettings",
    title: "Settings",
    path: "settings/store-settings",
    submenu: [
      {
        icon: "CiSettings",
        title: "Store Settings",
        path: "settings/store-settings",
      },
      // {
      //   "icon": "MdOutlineDeliveryDining",
      //   "title": "Delivery Settings",
      //   "path": "settings/delivery-settings"
      // },
      // {
      //   "icon": "TfiAlarmClock",
      //   "title": "Store Time Settings",
      //   "path": "settings/store-time-settings"
      // }
    ],
    accordian: [
      // {
      //   "accordianTitle": "Table Booking",
      //   "accordianmenu": [
      //     {
      //       "icon": "VscSettings",
      //       "title": "Basic Settings",
      //       "path": "settings/table-booking-basic-settings"
      //     },
      //     {
      //       "icon": "TbSettingsX",
      //       "title": "Cancellation Policy",
      //       "path": "settings/table-booking-cancellation-policy"
      //     }
      //   ]
      // }
    ],
  },
];

export default data;
