import React, { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import OrderComp from "./OrderComp";

const PendingOrder = () => {
 
  const [pagination,setPagination]=useState({
    "page": 1,
    "limit": 5,
    "sortOrder": "desc",
    "filter": {
      "delivery_status": "WAITING"
    }
  })

  const handlePagination=(data)=>{
    console.log(data);
    
    if (data.clearPagination) {
      setPagination(data.clearPagination)
      return
    }
    
    setPagination((prev)=>{
        let copyfilter={...prev.filter}
        // copyfilter[]
       
        if (data.filter) {
          // console.log("Object.keys[copyfilter]",Object.entries(copyfilter))
          // console.log("Object.keys[copyfilter]",Object.keys(data.filter))
          
          if (copyfilter[Object.keys(data.filter)[0]]) {
            copyfilter[Object.keys(data.filter)[0]]=Object.values(data.filter)[0]
            
          }else{

            copyfilter={...data?.filter,...copyfilter}
          }
        
        }
       
  
        return{
          page:data.page?data.page:prev.page,
          limit:data.limit?data.limit:prev.limit,
          sortOrder:data.sortOrder?data.sortOrder:prev.sortOrder,
          filter:copyfilter
        }
  
      })
    }
  

  return (
    <div className="p-5">
      <BreadCrumb title={"Pending Order"} />
      {/* <BasicTable
        data={value?.data}
        // data={[]}
        columns={columns}
        SearchBy={searchBy}
        filters={filters}
        WeekRange={true}
        MonthRange={true}
      /> */}

      <OrderComp disabledFilters={["delivery_status"]} pagination={pagination} handlePagination={handlePagination}/>
    </div>
  );
};

export default PendingOrder;
