import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteForever, MdEdit, MdOutlineDateRange, MdOutlineEdit } from "react-icons/md";
import { RiDiscountPercentFill } from "react-icons/ri";
import promoimg from "../../assest/offer_promo.png";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import Dropdown, { SelectMultipleFields } from "../../components/Dropdown";
import Lable from "../../components/Lable";
import SidebarField from "../../components/Sidebar/SidebarField";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import ToggleField from "../../components/ToggleField";
import CalendarComp from "../../components/CalendarComp/CalendarComp";
import moment from "moment/moment";
import ImagePreview from "../../components/ImagePreview";
import LoadingContext from "../../components/loader/index";
import { saleApi } from "../../apis/Offer.api";
import { toast } from "react-toastify";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import { CategoryApi, productApi, SubCategoryApi } from "../../apis/ProductListing.apis";
import './styles.css'
import Tooltip from "../../components/Tooltip/Tooltip";
import { useSelector } from "react-redux";


const Sale = () => {
  const { handleLoading } = useContext(LoadingContext);
  const [saleData, setSaleData] = useState([]);
  const [editSaleData, setEditSaleData] = useState({});
  const [visible, setVisible] = useState(false);
  const [isMinOrderAmount, setIsMinOrderAmount] = useState(false);
  const [isMaxDiscountAmount, setIsMaxDiscountAmount] = useState(false);
  const [isMinItemCart, setIsMinItemCart] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [subcategoryData, setSubCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);

  const handleOpen = () => {
    setVisible(true);
  };
  const handleEditOffer=(value)=>{
    handleClose()
    setTimeout(()=>{
      console.log("value?.max_discount_amount?.$numberDecimal",value?.max_discount_amount?.$numberDecimal);
      console.log("value?.max_discount_amount?.$numberDecimal",Boolean(value?.max_discount_amount?.$numberDecimal));
      
      handleSaleForOption(value.sale_for)
      setIsMinOrderAmount(Boolean(Number(value?.min_order_amount?.$numberDecimal)))
      setIsMaxDiscountAmount(Boolean(Number(value?.max_discount_amount?.$numberDecimal)))
      setIsMinItemCart(Boolean(value?.min_item_on_cart))
      setEditSaleData(value)
      setVisible(true);
    },100)
  console.log(value);
  }

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const handleDeleteOffer=(value)=>{
    console.log(value);
    setEditSaleData(value)
    setDeleteModal(true);
  }

  const handleDelete = async () => {
    handleLoading(true);
  

    try {
      let res;

      if (editSaleData._id) {
        res = await saleApi.deleteSale(editSaleData._id);
        toast.success(`${res.data.data}`);
        beforeMount()
      }

      console.log(res);
    } catch (err) {
      console.log(err);
      // toast.error(`${err.message}`)
    } finally {
      setEditSaleData({});
      handleLoading(false);
      handleDeleteClose();
    }
  };

  const handleClose = () => {
    setVisible(false);
    setEditSaleData({})
  };

  const handleCheckBox=(e,setFieldValue,cb) => {
    const isChecked = e.target.checked;
    console.log("isChecked",isChecked);
    

    if (isChecked) {
      setFieldValue(e.target.name, e.target.value); 
    } else {
      setFieldValue(e.target.name,0 );
    }

    cb(isChecked);
  }

  const handleSubmit = async(values) => {
    console.log("Offers Value", values);
    handleLoading(true);
    try {
      const res = editSaleData._id? await saleApi.updateSale(values,editSaleData._id): await saleApi.createSale(values)
      console.log("res",res);
      beforeMount()
      toast.success(editSaleData._id ?'Promo Code updated':'Promo Code Created')
      
    } catch (error) {
      console.log(error);
      toast.error(error.message)
      
    }
    handleClose()
    handleLoading(false);
  };

  const {currentUser} = useSelector((state) => state.store);
  const fetchingSaleForOptions = async (promoFor) => {
    handleLoading(true);

    try {
      if (promoFor === "Category") {
        const resCategory =currentUser?.delivery_business?.name === "Restourant "? await CategoryApi.getAllFoodCategory(): await CategoryApi.getAllCategory();
        // setCategoryData(resCategory.data?.data);
        return resCategory.data?.data;
      }
      if (promoFor === "Sub Category") {
        const resSubCategory =currentUser?.delivery_business?.name === "Restourant "? await SubCategoryApi.getAllFoodSubCategory(): await SubCategoryApi.getSubCategory();
        // setSubCategoryData(resSubCategory.data?.data)
        return resSubCategory.data?.data;
      }

      if (promoFor === "Product") {
        const resProduct =currentUser?.delivery_business?.name === "Restourant "? await productApi.getAllFoodProduct(): await productApi.getAllApprovedProduct();
        // setProductData(resProduct.data?.data)
        return resProduct.data?.data;
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    } finally {
      handleLoading(false);
    }
  };
  const [optionSaleFor,setOptionSaleFor]= useState([])
  

  const handleSaleForOption=async(saleFor)=>{
    console.log("SaleFor",saleFor);
    
    if (saleFor!=="Store") {
      const data = await fetchingSaleForOptions(saleFor)
    // console.log("data",data);
    const option= data.length && data.map((d)=>{
        d.label=d.name
        d.value=d._id
        return d
    })
    console.log(option);
   
    setOptionSaleFor(option)
    }
    
  }
  

  const beforeMount=async()=>{
    try {
      const resPromocode = await saleApi.getAllSales()
      console.log("resPromocode",resPromocode);
      setSaleData(resPromocode.data.data)      
    } catch (error) {
      console.log(error);
      toast.error(error.message)
      
    }
  }

  const getMultiselectValue=(value)=>{
    console.log("value",value);
    
    
    if (value && value.length) {
      // values.promo_for==="Category"?"category":values.promo_for==="Sub Category"?"sub_category":"product"
      const selectedId=value.map((item)=>{
        if (item._id) {
          return item._id
        }else{
          return item
        }
      })
      return selectedId
    }else{
      
      return null
    }
  }
  
  
  
  useEffect(()=>{
    handleLoading(true);
    beforeMount()
    handleLoading(false);
    
  },[])

  return (
    <div className="p-5 w-[100%]">
      <div className="flex justify-between items-center flex-wrap mb-2 border-b">
        <BreadCrumb title="Sales" />

        <Button
          text="Add New"
          type="button"
          className="float-end"
          onClick={handleOpen}
        />
      </div>

      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-4 mt-5">
        {saleData.length &&
          saleData.map((item) => {
            return <Card item={item} handleDelete={handleDeleteOffer} handleEditOffer={handleEditOffer} />;
          })}
      </div>

      {visible && (
        <Formik
          initialValues={{
            image:editSaleData?.image || "",
            sale_name:editSaleData?.sale_name || null,
            discount_type:editSaleData?.discount_type|| null,
            discount: editSaleData?.discount?.$numberDecimal|| 0,
            description:editSaleData?.description|| null,
            sale_for:editSaleData?.sale_for|| null,            
            category:editSaleData?.category|| null,
            sub_category:editSaleData?.sub_category|| null,
            product:editSaleData?.product|| null,
            active:editSaleData?.active|| false,
            is_validation_date:editSaleData?.is_validation_date|| false,
            start_date:editSaleData?.start_date|| null,
            end_date:editSaleData?.end_date||null,
            repeat:editSaleData?.repeat||null,            
            min_order_amount:Number(editSaleData?.min_order_amount?.$numberDecimal)|| 0,
            max_discount_amount:Number(editSaleData?.max_discount_amount?.$numberDecimal)|| 0,
            min_item_on_cart:editSaleData?.min_item_on_cart|| 0,           
          }}
          onSubmit={handleSubmit}
          // validationSchema={}
        >
          {({ handleChange,setFieldValue, values, handleSubmit }) => (
            <Form>
            <SidebarField
              handleClose={handleClose}
              title={editSaleData._id?"Update Sale":"Add New Sale"}
              button1={
                <Button text="Cancel" type="button" onClick={handleClose} />
              }
              button2={<Button text="Submit" type="submit" onClick={handleSubmit} />}
            >
              <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                <div className="relative h-fit w-[95%] shadow-xl rounded-xl m-auto">
                  <label
                    htmlFor="image-upload"
                    className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                  >
                    <input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      onChange={(e)=>setFieldValue("image",e.target.files[0])}
                      // onChange={(e)=>imageCheck(e)}
                      // value={values.image}
                      name="image"
                      style={{ display: "none" }}
                    />
                    <MdEdit />
                  </label>
                  {typeof(values.image)==="object"?(<ImagePreview file={values.image} Id="promo"/>):(<img
                    src={values.image ? values.image : promoimg}
                    alt="Promo Not Found"
                  />)}
                </div>
                <div>
                  <div className="flex">
                  <Lable lable="Sale Name" />
                  <Tooltip text="enter sales name here"/>
                  </div>
                  <TextInput
                    name="sale_name"
                    type="text"
                    placeholder="Name of sale"
                    onChange={handleChange}
                    value={values.sale_name}
                  />
                  <ErrorMessage
                    name="sale_name"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="flex justify-between gap-2">
                  <div className="w-[90%]">
                    <div className="flex">
                    <Lable lable="Discount type" />
                    <Tooltip  text="select Discount type from dropdown"/>
                    </div>
                    <Dropdown
                      options={[{label:'Absolute',value:'Absolute'},{label:'Percentage',value:'Percentage'}]}
                      name="discount_type"
                      value={values.discount_type}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="discount_type"
                      component="div"
                      className="error"
                    />
                  </div>
                  <div className="w-[90%]">
                    <div className="flex">
                    <Lable lable="Discount" />
                    <Tooltip text="enter Discount amount here"/>
                    </div>
                    <TextInput
                      name="discount"
                      type="number"
                      placeholder="0"
                      onChange={handleChange}
                      value={values.discount}
                    />
                    <ErrorMessage
                      name="discount"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex">
                  <Lable lable="Description" />
                  <Tooltip text="write a brief description of sales offer"/>
                  </div>
                  <Textarea
                    name="description"
                    type="text"
                    placeholder=""
                    onChange={handleChange}
                    value={values.description}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error"
                  />
                </div>
                <div>
                  <div className="flex"><Lable lable="Sale for" />
                  <Tooltip text="select the area of sales"/>
                  </div>
                  <Dropdown
                    options={[{label:'Store',value:'Store'},{label:'Category',value:'Category'},{label:'Sub Category',value:'Sub Category'},{label:'Product',value:'Product'}]}
                    name="sale_for"
                    value={values.sale_for}
                    handleOnchange={(value)=>{
                      console.log(value);
                      setFieldValue("category",null)
                      setFieldValue("sub_category",null)
                      setFieldValue("product",null)
                      setFieldValue("sale_for",value.value)
                      handleSaleForOption(value.value)
                      // handleChange
                    }}
                  />
                  <ErrorMessage
                    name="sale_for"
                    component="div"
                    className="error"
                  />
                </div>
                {values.sale_for && values.sale_for!=="Store" && (<div>
                  <Lable lable={`${values.sale_for}`} />                 
                  <SelectMultipleFields
                      options={optionSaleFor.length?optionSaleFor:[]}
                      name={values.sale_for==="Category"?"category":values.sale_for==="Sub Category"?"sub_category":"product"}
                      value={values[values.sale_for==="Category"?"category":values.sale_for==="Sub Category"?"sub_category":"product"]!==null?getMultiselectValue(values[values.sale_for==="Category"?"category":values.sale_for==="Sub Category"?"sub_category":"product"]):null}                    
                      handleOnchange={(v)=>{
                          console.log("value",v);
                          setFieldValue(`${values.sale_for}`,v)

                      }}
                      className={["w-full"]}
                      sx={{width:"100%"}}
                    />                  
                  <ErrorMessage
                    name="sale_for"
                    component="div"
                    className="error"
                  />
                </div>)}
                <div>
                  <ToggleField
                    text="Is your offer running ?"
                    name="active"
                    onChange={handleChange}
                    value={values.active}
                  />
                  <ErrorMessage
                    name="active"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="border-b">Offer Advance settings</div>
                <div>
                  <ToggleField
                    text="Do you want to set offer validation date ?"
                    name="is_validation_date"
                    value={values.is_validation_date}
                    toggaleHandleChange={(e)=>{
                      console.log("e.target.value",e.target.value);
                      
                      if (!e.target.value) {
                        setFieldValue("start_date",null)
                        setFieldValue("end_date",null)
                      }
                      handleChange(e)
                    }}
                  />
                  <ErrorMessage
                    name="is_validation_date"
                    component="div"
                    className="error"
                  />
                </div>
                {values.is_validation_date && (
                  <div>
                    <div className="flex">
                    <Lable lable="Offer Apply Date Range" />
                    <Tooltip text="select date range to apply offer"/>
                    </div>
                    {/* <TextInput
                      name="dateRange"
                      type="text"
                      placeholder="Date Range"
                      onChange={handleChange}
                      value={values.dateRange}
                    /> */}
                    <CalendarComp 
                    // numberOfMonths={2}
                    className="w-full h-12 border-[#e5e7eb]"
                    showTime  
                    hourFormat="12" 
                    sx={{width:"100%",borderRadius:"5px",height:"4rem"}}
                    handleFilter={(type,v)=>{
                      console.log("Type",type)
                      console.log("value",v)
                      const formattedDates = v.map(date => moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
                      console.log(formattedDates);
                      setFieldValue("start_date",formattedDates[0])
                      setFieldValue("end_date",formattedDates[1])
                      
                    }} />
                    <ErrorMessage
                      name="dateRange"
                      component="div"
                      className="error"
                    />
                  </div>
                )}
                <div>
                  <div className="flex">
                  <Lable lable="Recursion type" />
                  <Tooltip text="select reperatation type for offer"/>
                  </div>
                  <Dropdown
                    options={[
                      { id: "1", label: "No Recursion", value: "No" },
                      { id: "2", label: "Daily Recursion", value: "Daily" },
                      { id: "3", label: "Weekly Recursion", value: "Weekly" },
                      { id: "4", label: "Monthly Recursion", value: "Monthly" },
                      { id: "5", label: "Annually Recursion", value: "Annually" },
                    ]}
                    name="repeat"
                    value={values.repeat}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="repeat"
                    component="div"
                    className="error"
                  />
                </div>
               
                <div>
                  <div className="flex">
                  <Lable
                    lable={"Do you want to set min order amount for apply ?"}
                  />
                  <Tooltip text="you can set the minimum order amount to apply offer"/>
                  </div>
                  <div className="flex items-center ">
                    <div className="border px-2.5 py-2">
                      <input
                        type="checkbox"
                        name="min_order_amount"
                        id="min_order_amount"
                        value={values.min_order_amount}    
                        checked={Boolean(values.min_order_amount)?Boolean(values.min_order_amount):isMinOrderAmount}
                        onChange={(e) => handleCheckBox(e,setFieldValue,setIsMinOrderAmount)}
                        
                      />
                    </div>
                    <TextInput
                      name="min_order_amount"
                      type="number"
                      placeholder="0"
                      onChange={handleChange}
                      value={values.min_order_amount}
                      disabled={!isMinOrderAmount}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex">
                  <Lable lable={"Do you want to set max discount amount ?"} />
                  <Tooltip text="you can set the Maximum discount amount"/>
                  </div>
                  <div className="flex items-center ">
                    <div className="border px-2.5 py-2">
                      <input
                        type="checkbox"
                        name="max_discount_amount"
                        id="max_discount_amount"
                        // checked={isMaxDiscountAmount}
                        // onChange={(e) =>
                        //   setIsMaxDiscountAmount(e.target.checked)
                        // }
                        value={values.max_discount_amount}
                        checked={Boolean(values.max_discount_amount)?Boolean(values.max_discount_amount):isMaxDiscountAmount}
                        onChange={(e) => handleCheckBox(e,setFieldValue,setIsMaxDiscountAmount)}
                      />
                    </div>
                    <TextInput
                      name="max_discount_amount"
                      type="number"
                      placeholder="0"
                      onChange={handleChange}
                      value={values.max_discount_amount}
                      disabled={!isMaxDiscountAmount}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex">
                  <Lable
                    lable={"Do you want to set min item on cart for apply ?"}
                  />
                  <Tooltip text="you can set the Minimum discount amount"/>
                  </div>
                  <div className="flex items-center ">
                    <div className="border px-2.5 py-2">
                      <input
                        type="checkbox"
                        name="min_item_on_cart"
                        id="min_item_on_cart"
                        value={values.min_item_on_cart}
                        checked={Boolean(values.min_item_on_cart)?Boolean(values.min_item_on_cart):isMinItemCart}
                        onChange={(e) => handleCheckBox(e,setFieldValue,setIsMinItemCart)}
                      />
                    </div>
                    <TextInput
                      name="min_item_on_cart"
                      type="number"
                      placeholder="0"
                      onChange={handleChange}
                      value={values.min_item_on_cart}
                      disabled={!isMinItemCart}
                    />
                  </div>
                </div>
              </div>
            </SidebarField>
            </Form>
          )}
        </Formik>
      )}
        <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
      />
    </div>
  );
};

export default Sale;


const Card = ({ key, handleEditOffer, handleDelete, item }) => {
  return (
    <div key={key} className="bg-[#4444442c] text-white rounded-lg shadow-lg p-4 relative">
      <div className="discount-tag">
        <RiDiscountPercentFill className="text-white mx-2 text-xl" />
        <p className="text-white font-semibold uppercase">Sale</p>
        <p className="text-white font-semibold">           
          {item.discount.$numberDecimal}
          {item.discount_type === "Percentage" ? "%" : ""}
        </p>
      </div>
      <img
        src={item.image ? item.image : promoimg}
        alt="Product"
        className="w-full h-40 object-cover mb-4"
      />
      <h2 className="text-xl font-semibold mb-1 text-[#145388] capitalize">{item.sale_name}</h2>
      <p className="text-sm text-gray-400 mb-3">
        ({moment(item.start_date).utc().format('DD MMM YYYY')} to {moment(item.end_date).utc().format('DD MMM YYYY')})
      </p>
      <div className="flex items-start">
        <span className="text-l font-bold text-[#145388]">{item.description}</span>
      </div>
      <div className="flex justify-end gap-2">
        <button
          className="bg-[#0F3D64] text-white p-2 rounded-full font-semibold transition hover:bg-[#145388]"
          onClick={()=>handleEditOffer(item)}
        >
          <MdOutlineEdit className="text-white group-hover:text-[#fff]" />
        </button>
        <button
          className="bg-red-500 text-white p-2 rounded-full font-semibold transition hover:bg-red-600"
          onClick={()=>handleDelete(item)}
        >
          <MdDeleteForever className="text-[#fff] group-hover:text-red-700 text-lg" />
        </button>
      </div>
    </div>
  );
};


