import moment from "moment";
import DropdownMenubar from "../components/Menubar/DropdownMenubar";
import { SlCheck } from "react-icons/sl";
import { MdDeliveryDining, MdOutlineCancel } from "react-icons/md";
import { RiProgress2Fill } from "react-icons/ri";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { Badge } from "primereact/badge";


const vendorActions=['WAITING','ACCEPT', 'REJECT', 'PREPARE', 'READY','ASSIGN', 'CANCEL']
const orderStatus=['WAITING', 'ACCEPTED','PREPARED', 'READY', 'ASSIGN', 'CANCELLED', 'REPLACED', 'RETURNED', 'DELIVERED', 'REJECTED']

const DD = (MenuItems) => {
  return (
    <div className="p-3">

      <DropdownMenubar
        menuItem={[
          {
            label: "Action",
            items: MenuItems,
          },
        ]}
        />
    </div>
  );
};
const getVendorInfo = (row,handleAction) => {
  const status = row?.delivery_status;
  switch (status) {
    case "WAITING":
      return DD([
        {
          label: "ACCEPT",
          icon: <SlCheck style={{color:" rgb(15 61 100)"}} />,
          command: () => {
            console.log("Accept", row);
            handleAction && handleAction(row,{vendor_action:"ACCEPT"})
          },
        },
        {
          label: "REJECT",
          icon: <MdOutlineCancel style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("Reject", row);
            handleAction&&handleAction(row,{vendor_action:"REJECT"})
          },
        },
      ]);
    case "REJECTED":
      return <>Rejected</>;
    case "ACCEPTED":
      return DD([
        {
          label: "PREPARE",
          icon: <RiProgress2Fill style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("PREPARE", row);
            handleAction&&handleAction(row,{vendor_action:"PREPARE"})
          },
        },
        {
          label: "CANCEL",
          icon: <MdOutlineCancel style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("CANCEL", row);
            handleAction&&handleAction(row,{vendor_action:"CANCEL"})
          },
        },
      ]);
    case "PREPARED":
      return DD([
        {
          label: "READY",
          icon: <IoCheckmarkDoneCircleOutline style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("Accept", row);
            handleAction&&handleAction(row,{vendor_action:"READY"})
          },
        },
        {
          label: "CANCEL",
          icon: <MdOutlineCancel style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("CANCEL", row);
            handleAction&&handleAction(row,{vendor_action:"CANCEL"})
          },
        },
      ]);
    case "READY":
      return DD([
        {
          label: "ASSIGN",
          icon: <MdDeliveryDining style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("Accept");
            handleAction&&handleAction(row,{vendor_action:"ASSIGN"})
          },
        },
        {
          label: "CANCEL",
          icon: <MdOutlineCancel style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("CANCEL");
            handleAction&&handleAction(row,{vendor_action:"CANCEL"})
          },
        },
      ]);
    case "ASSIGNED":
      return DD([
        {
          label: "ASSIGN",
          icon: <MdDeliveryDining style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("Accept");
            handleAction&&handleAction(row,{vendor_action:'ASSIGN'})
          },
        },
        {
          label: "CANCEL",
          icon: <MdOutlineCancel style={{color:" rgb(15 61 100)"}}/>,
          command: () => {
            console.log("CANCEL");
            handleAction&&handleAction(row,{vendor_action:'CANCEL'})
          },
        },
      ]);
    default:
      return <></>;
  }
};

export const columns = [
  {
    field: "order_id",
    header: "#",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"order_id")}  className="cursor-pointer text-blue-600 hover:text-blue-800">
          <p>{row?.order_id}</p>
          <p className="w-[6rem]">
            {moment(row?.order_date).utc().format("DD MMM YYYY hh:mma")}
          </p>
        </div>
      </>
    ),
  },
  {
    field: "name",
    header: "Name",
    accessor: (row) => (
      <>
        <div className="">
          <p>{row?.delivery_address && row?.delivery_address.name}</p>
        </div>
      </>
    ),
  },
  {
    field: "delivery_address",
    header: "Address",
    accessor: (row) => (
      <>
        <div className="">
          <p>
            {row.delivery_address &&
              `${row.delivery_address?.flat_no || ""} ${
                row.delivery_address?.street || ""
              } ${row.delivery_address?.landmark || ""} ${
                row.delivery_address?.city?.name || ""
              } ${row.delivery_address?.country?.name || ""}`}
          </p>
        </div>
      </>
    ),
  },
  { field: "order_type", header: "Order type" },

  {
    field: "item_price",
    header: "Item Price",
    accessor: (row) => row?.item_price?.$numberDecimal || "",
  },
  { field: "pay_type", header: "Payment Type" },
  {
    field: "payment_status",
    header: "Payment Status",
    accessor: (row) =>
      row.payment_status ? (
        <Badge
          className="w-[6rem]"
          severity={
            row.payment_status === "PAID"
              ? "success"
              : row.payment_status === "UNPAID"
              ? "warning"
              : "danger"
          }
          value={row.payment_status}
        ></Badge>
      ) : null,
  },
  {
    field: "delivery_status",
    header: "Delivery Status",
    accessor: (row) => {
      let severity;
      if (row.delivery_status === "DELIVERED") {
        severity = "success";
      } else if (row.delivery_status === "WAITING") {
        severity = "warning";
      } else if (row.delivery_status === "ACCEPTED") {
        severity = "success";
      } else if (row.delivery_status === "CANCELLED") {
        severity = "danger";
      }

      return row.delivery_status ? (
        <Badge        
          className="w-[9rem]"
          severity={severity}
          value={row.delivery_status}
        />
      ) : null;
    },
  },
  {
    field: "vendor_action",
    header: "Action",
    accessor: (row,handleAction) => getVendorInfo(row,handleAction),
  },
];


export const data = [
  {
    id: "1000",
    name: "Bamboo Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1001",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Waiting For Delivery",
  },
  {
    id: "1002",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Accept",
  },
  {
    id: "1003",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1004",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Waiting For Delivery",
  },
  {
    id: "1005",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1006",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Accept",
  },
  {
    id: "1007",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1008",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Waiting For Delivery",
  },
  {
    id: "1009",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1010",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Accept",
  },
  {
    id: "1011",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Waiting For Delivery",
  },
  {
    id: "1012",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1013",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1014",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1015",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1016",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1017",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1018",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1019",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
  {
    id: "1020",
    name: "Neelesh Watch",
    address: "Bilaspur Chhattisgarh India, 495001",
    price: "590.00 (cash)",
    type: "Delivery",
    status: "Accepted",
    info: "Prepared",
  },
];


export const searchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

export const filters = [
  {
    filterType: "Delivery Type",
    inputName: "order_type",
    options: [
      {
        name: "Local delivery",
        code: "LOCAL DELIVERY",
      },
      {
        name: "Intercity delivery",
        code: "INTERCITY DELIVERY",
      },
    ],
  },
  {
    filterType: "Payment Mode",
    inputName: "pay_type",
    options: [
      {
        name: "Cash",
        code: "CASH",
      },
      {
        name: "Card",
        code: "CARD",
      },
      {
        name: "Online",
        code: "ONLINE",
      },      
    ],
  },
  {
    filterType: "Payment Status",
    inputName: "payment_status",
    options: [
      {
        name: "UNPAID",
        code: "UNPAID",
      },
      {
        name: "PAID",
        code: "PAID",
      },
      {
        name: "FAILED",
        code: "FAILED",
      },      
      {
        name: "PENDING",
        code: "PENDING",
      },      
    ],
  },
  {
    filterType: "Delivery Status",
    inputName: "delivery_status",
    options: [
      {
        name: "WAITING",
        code: "WAITING",
      },
      {
        name: "ACCEPTED",
        code: "ACCEPTED",
      },
      {
        name: "PREPARED",
        code: "PREPARED",
      },      
      {
        name: "READY",
        code: "READY",
      },      
      {
        name: "ASSIGNED",
        code: "ASSIGNED",
      },      
      {
        name: "CANCELLED",
        code: "CANCELLED",
      },      
      {
        name: "REPLACED",
        code: "REPLACED",
      },      
      {
        name: "RETURNED",
        code: "RETURNED",
      },      
      {
        name: "DELIVERED",
        code: "DELIVERED",
      },      
      {
        name: "REJECTED",
        code: "REJECTED",
      },      
    ],
  },
];


export const canlenderFilter={
  filterType:"Order Date",
  inputName:"order_date",  
  maxDate :new Date()
}
