// vendor/get-document-master

import { user_role } from "../config/apiConfig";
import Api from "../utils/axios";

const masterApi = {
  getDocumentMaster: () => Api.get(`vendor/get-document-master`),
  getAllCountry: () => Api.get("master/get-all-country"),
  getAllCity: (countryId) => Api.get(`master/get-all-city/${countryId}`),
  getAllBuisnessType: () => Api.get("master/get-all-business-type"),
  getAllTax: () => Api.post("master/get-all-tax-master"),
  getDeliveryFeeById: (id) => Api.get(`master/get-delivery-fee-by-id/${id}`),

};

export default masterApi;
