// components/Dropdown.jsx
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { MultiSelect } from "primereact/multiselect";
import "./dropdown.css";

// const Dropdown = ({ options, name, value,handleOnchange, ...restProps }) => {
//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       border: state.isFocused
//         ? "none "
//         : theme === "light"
//         ? "1px solid #cccccc"
//         : "1px solid #fff",
//       color: "#fff",
//       padding: "0px 10px",
//       backgroundColor: theme === "light" ? "#fff" : "#232223",
//       borderRadius: "none",
//       "&:hover": {
//         color: "#fff",
//       },
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isFocused
//         ? "#00365a"
//         : theme === "light"
//         ? "white"
//         : "#313131",
//       color: state.isFocused ? "#fff" : theme === "light" ? "#000" : "#fff",
//       padding: "10px",

//       "&:hover": {
//         backgroundColor: "#00365a",
//         color: "#fff",
//       },
//     }),
//   };
//   const { theme } = useSelector((state) => state.theme);

//   const { setFieldValue } = useFormikContext();

//   const handleSelectChange = (selectedOption) => {
//     handleOnchange && handleOnchange(selectedOption)
//     setFieldValue(name, selectedOption ? selectedOption.value : null);
//   };
//   console.log("options",options);
//   console.log("value", value);

//   return (
//     <Select
//       options={options}
//       onChange={handleSelectChange}
//       value={value && options.find((option) => option.value === value)}
//       // value={options.find((option) => option.value === values[name]) || null}
//       className="w-full text-sm"
//       styles={customStyles}
//       {...restProps}
//     />
//   );
// };
const Dropdown = ({ options, name, value, handleOnchange, disabled }) => {
  const { theme } = useSelector((state) => state.theme);

  const { setFieldValue } = useFormikContext();

  const handleSelectChange = (selectedOption) => {
    setFieldValue(name, selectedOption ? selectedOption.value : null);
    handleOnchange && handleOnchange(selectedOption);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused
        ? "none "
        : theme === "light"
        ? "1px solid #cccccc"
        : "1px solid #fff",
      color: "#fff",
      padding: "0px 10px",
      backgroundColor: theme === "light" ? "#fff" : "#232223",
      borderRadius: "none",
      "&:hover": {
        color: "#fff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused
        ? "#00365a"
        : theme === "light"
        ? "white"
        : "#313131",
      color: state.isFocused ? "#fff" : theme === "light" ? "#000" : "#fff",
      padding: "10px",

      "&:hover": {
        backgroundColor: "#00365a",
        color: "#fff",
      },
    }),
  };

  return (
    <Select
      options={options}
      onChange={handleSelectChange}
      value={options.find((option) => option.value === value)}
      // value={options.find((option) => option.value === values[name]) || null}
      className="w-full text-sm"
      styles={customStyles}
      isDisabled={disabled}
    />
  );
};

export default Dropdown;

// multi select dropdown
export function MultiSelectDropDown({
  sx,
  options,
  name,
  value,
  setVal,
  placeholder,
}) {
  const { theme } = useSelector((state) => state.theme);
  const { setFieldValue } = useFormikContext();

  const [selectedOptions, setSelectedOptions] = useState(value || []);

  useEffect(() => {
    // Only update selectedOptions when value changes, and ensure the value is not cleared unintentionally
    if (JSON.stringify(selectedOptions) !== JSON.stringify(value)) {
      setSelectedOptions(value || []);
    }
  }, [value]);

  useEffect(() => {
    setVal(selectedOptions);
  }, [value]);

  useEffect(() => {
    setFieldValue(name, selectedOptions);
  }, [name, selectedOptions, setFieldValue]);

  const subCategoryTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = selectedOptions ? selectedOptions.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <div
      style={sx ? sx : {}}
      className="card flex justify-content-center w-[90%] "
    >
      <MultiSelect
        value={selectedOptions}
        filter
        onChange={(e) => setSelectedOptions(e.value)}
        options={options}
        optionLabel="name"
        optionValue="_id"
        maxSelectedLabels={4}
        display="chip"
        itemTemplate={subCategoryTemplate}
        panelFooterTemplate={panelFooterTemplate}
        className="md:w-20rem"
        placeholder={placeholder}
      />
    </div>
  );
}

// multi select dropdown
export function SelectMultipleFields({
  sx,
  options,
  name,
  value,
  handleOnchange,
  ...restProps
}) {
  const { theme } = useSelector((state) => state.theme);
  const { setFieldValue } = useFormikContext();

  console.log("options", options);
  console.log("value", value);

  const handleSelectChange = (selectedOption) => {
    handleOnchange && handleOnchange(selectedOption.value);
    setFieldValue(name, selectedOption ? selectedOption.value : null);
  };

  const subCategoryTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    const length = value ? value.length : 0;

    return (
      <div className="py-2 px-3">
        <b>{length}</b> item{length > 1 ? "s" : ""} selected.
      </div>
    );
  };

  return (
    <div
      style={sx ? sx : {}}
      className="card flex justify-content-center w-[90%] "
    >
      <MultiSelect
        value={value}
        filter
        onChange={(e) => handleSelectChange(e)}
        options={options}
        optionLabel="name"
        optionValue="_id"
        ariaLabelledBy="name"
        maxSelectedLabels={4}
        display="chip"
        itemTemplate={subCategoryTemplate}
        panelFooterTemplate={panelFooterTemplate}
        className="md:w-20rem"
        {...restProps}
      />
    </div>
  );
}
