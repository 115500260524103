import React from 'react'
import '../Tooltip/Tooltip.css';
import { IoInformationCircleOutline } from 'react-icons/io5';
import propTypes from 'prop-types';

const Tooltip=({text,position='top',size=18})=> {
  return (
    <div className={`tooltip-container tooltip-${position}`}>
        <IoInformationCircleOutline size={size}/>
        <div className="tooltip-text">
            {text}
        </div>
    </div>
  );
};
Tooltip.propTypes={
    text:propTypes.string.isRequired,
    position:propTypes.oneOf(['top','right','bottom','left']),
    children:propTypes.node.isRequired,

};
Tooltip.defaultProps = {
    position: 'top',
    size: 18, // Default size for the icon
  };

export default Tooltip