// AdvertisementCard.js
import React, { useEffect, useRef, useState } from "react";
import style from "./AdvertisementCard.module.css";
import { CiHeart } from "react-icons/ci";
import { LuEye } from "react-icons/lu";
import { PiShareFat } from "react-icons/pi";
import {
  MdDeleteForever,
  MdOutlineEdit,
  MdDownloadDone,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { Form, Formik } from "formik";
import SidebarField from "../../components/Sidebar/SidebarField";
import Tooltip from "../../components/Tooltip/Tooltip";
import { Chart, Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { productApi } from "../../apis/ProductListing.apis";

const AdvertisementCard = ({
  adData,
  // handleClose,
  handleEditOpen,
  handleDelete,
  // key,
  // isAdvertisementModel,
}) => {
  // const [singleProduct,setSingleProduct]=useState();
  const { theme } = useSelector((state) => state.theme);
  const chartRef = useRef(null);
  console.log("adData:",adData);

  // useEffect(() => {
  //   console.log("add Id",adData._id);
  //   getSingleProduct(adData.product);
  // }, [adData._id]);

  // const getSingleProduct=async(product_id)=>{
  //   try{
  //     const productData= await productApi.getSingleProduct(product_id);
  //     console.log("single Product Details:",productData.data.data.product);
  //     setSingleProduct(productData.data.data.product);
  //   } catch (error) {
  //     console.log(error);
  //     // toast.error(error.message);
  //   }
  // }

  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Views",
        data: [35000, 22000, 80000, 15000, 56000, 11000, 40000],
        fill: false,
        backgroundColor: "rgba(10,255,10,0.4)",
        borderColor: "rgba(10,255,10,1)",
      },
      {
        label: "Orders",
        data: [32000, 32000, 10000, 95000, 19000, 11800, 10000],
        fill: false,
        backgroundColor: "rgba(255, 0, 0, 0.4)",
        borderColor: "rgba(255, 0, 0,1)",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
      },
    },
  };
  return (
    <>
      <div
        className={`${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        } ${style.advertisement_card}`}
        key={adData.id}
      >
        <div className="relative">
          <img
            className={style.advertisement_image}
            src={adData.image}
            alt={adData.title}
          />

          {/* Edit and Delete buttons */}
          <div className="absolute top-2 right-2 flex gap-2">
            <span
              onClick={() => handleEditOpen(adData)}
              className="p-1 text-blue-700 rounded-full bg-slate-100 hover:bg-blue-100 border border-blue-500 transition-colors duration-200"
            >
              <MdOutlineEdit className="text-lg" />
            </span>
            <span
              onClick={() => handleDelete(adData)}
              className="p-1 text-red-700 rounded-full bg-slate-100 hover:bg-red-100 border border-red-500 transition-colors duration-200"
            >
              <MdDeleteForever className="text-lg" />
            </span>
          </div>
        </div>
        <div className={style.ad_content}>
          <h2 className={style.ad_title}>{adData?.products?.name}</h2>
          <p className={style.ad_product}>Id: {adData.product}</p>
          <p className={style.ad_date}>
            Start Date: {new Date(adData.start_date).toLocaleDateString()}  
          </p>
          <p className={style.ad_date}>
            Exp Date: {new Date(adData.end_date).toLocaleDateString()}
          </p>
          <div className="flex justify-between px-2">
            <span className="flex flex-col items-center justify-center">
              <LuEye fontSize={"1.5rem"} />
              <span>{adData.advertise_views}</span>
            </span>
            <span className="flex flex-col items-center justify-center">
              <MdDownloadDone fontSize={"1.5rem"} />
              <span>{adData.ordered_by_advertise}</span>
            </span>
            <span className="flex flex-col items-center justify-center">
              <MdOutlineShoppingCart fontSize={"1.5rem"} />
              <span>{adData.addToCart_count}</span>
            </span>
            <span className="flex flex-col items-center justify-center">
              <PiShareFat fontSize={"1.5rem"} />
              <span>{adData.share}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvertisementCard;
