import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
 
const StarRating = ({ stars }) => {
   
  return (
    <div className="flex justify-center items-center mt-1">
      {[...Array(5)].map((_, index) => {
        if (index < Math.floor(stars)) {
          return <FaStar key={index} className="text-orange-500 text-xs" />;
        } else if (index < stars) {
          return (
            <FaStarHalfAlt key={index} className="text-orange-500 text-xs" />
          );
        } else {
          return <FaRegStar key={index} className="text-orange-500 text-xs" />;
        }
      })}
      <span className="text-sm text-gray-500 ml-2">
        ({stars ? Number(stars).toFixed(1) : 0})
      </span>
    </div>
  );
};
 
export default StarRating;
 
export const BiggerStar = ({ stars }) => {
  return (
    <div className="flex justify-center items-center mt-1">
      {[...Array(5)].map((_, index) => {
        if (index < Math.floor(stars)) {
          return <FaStar key={index} className="text-orange-500 text-xl" />;
        } else if (index < stars) {
          return (
            <FaStarHalfAlt key={index} className="text-orange-500 text-xl" />
          );
        } else {
          return <FaRegStar key={index} className="text-orange-500 text-xl" />;
        }
      })}
      <span className=" text-black ml-2 text-xl">{stars ? stars : 0}</span>
    </div>
  );
};