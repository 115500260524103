import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext } from "react";
import Lable from "../../components/Lable";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { otpValidationSchema } from "../../validationSchema/ValidationSchema";
import AuthApi from "../../apis/auth.apis";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderContext from "../../components/loader";

const VerifyOtp = () => {
  const location = useLocation();
  const { emailOrPhone } = location.state || {};
  //   console.log("email or phone", emailOrPhone);
  const navigate = useNavigate();
  console.log("emailOrPhone", emailOrPhone);
  const initialValues = {
    otp: "",
  };
  const { handleLoading } = useContext(LoaderContext);

  const handleSubmit = async (values) => {
    console.log("values", values);
    handleLoading(true);
    const payload = {
      emailOrPhone: emailOrPhone?.emailOrPhone,
      otp: values?.otp,
    };

    console.log("payload", payload);
    try {
      const res = await AuthApi?.verfiryOtp(payload);
      console.log("res", res);
      toast.success("Otp Verified successfully");
      navigate("/change-password", {
        state: { emailOrPhone: payload?.emailOrPhone },
      });
      handleLoading(false);
    } catch (error) {
      console.log("error", error);
      handleLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={otpValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <Form className="mt-12">
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="OTP" />
              <div>
                <TextInput
                  name="otp"
                  type="otp"
                  placeholder="Enter Otp"
                  onChange={handleChange}
                  value={values.otp ? values.otp : ""}
                />
                <ErrorMessage name="otp" component="div" className="error" />
              </div>
            </div>

            <div className="mt-8 flex gap-4 justify-between flex-wrap">
              <Button text={"Verify OTP"} type={"submit"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VerifyOtp;
