// import React from "react";
// import { Bar } from "react-chartjs-2";
// import "chart.js/auto";
// import CalendarComp from "../../CalendarComp/CalendarComp";

// // Helper function to generate dates for the current month
// const getDaysInMonth = (month, year) => {
//   const date = new Date(year, month, 1);
//   const days = [];
//   while (date.getMonth() === month) {
//     days.push(new Date(date).toISOString().split("T")[0]); // Format as YYYY-MM-DD
//     date.setDate(date.getDate() + 1);
//   }
//   return days;
// };

// const BarChart = () => {
//   const now = new Date();
//   const daysInMonth = getDaysInMonth(now.getMonth(), now.getFullYear());

//   // Example data for each day of the current month for different categories
//   const generateRandomData = () =>
//     Array(daysInMonth.length)
//       .fill()
//       .map(() => Math.floor(Math.random() * 100));

//   const dataCancelledByAdmin = generateRandomData();
//   const dataCancelledByUser = generateRandomData();
//   const dataCompletedOrder = generateRandomData();
//   const dataRejectedByStore = generateRandomData();
//   const dataTotalDeliveries = generateRandomData();
//   const dataTotalOrders = generateRandomData();

//   const data = {
//     labels: daysInMonth,
//     datasets: [
//       {
//         label: "Cancelled By Admin",
//         backgroundColor: "rgba(255,99,132,0.4)",
//         borderColor: "rgba(255,99,132,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(255,99,132,0.6)",
//         hoverBorderColor: "rgba(255,99,132,1)",
//         data: dataCancelledByAdmin,
//       },
//       {
//         label: "Cancelled By User",
//         backgroundColor: "rgba(54,162,235,0.4)",
//         borderColor: "rgba(54,162,235,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(54,162,235,0.6)",
//         hoverBorderColor: "rgba(54,162,235,1)",
//         data: dataCancelledByUser,
//       },
//       {
//         label: "Completed Order",
//         backgroundColor: "rgba(75,192,192,0.4)",
//         borderColor: "rgba(75,192,192,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(75,192,192,0.6)",
//         hoverBorderColor: "rgba(75,192,192,1)",
//         data: dataCompletedOrder,
//       },
//       {
//         label: "Rejected By Store",
//         backgroundColor: "rgba(153,102,255,0.4)",
//         borderColor: "rgba(153,102,255,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(153,102,255,0.6)",
//         hoverBorderColor: "rgba(153,102,255,1)",
//         data: dataRejectedByStore,
//       },
//       {
//         label: "Total Deliveries",
//         backgroundColor: "rgba(255,206,86,0.4)",
//         borderColor: "rgba(255,206,86,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(255,206,86,0.6)",
//         hoverBorderColor: "rgba(255,206,86,1)",
//         data: dataTotalDeliveries,
//       },
//       {
//         label: "Total Orders",
//         backgroundColor: "rgba(75,192,192,0.4)",
//         borderColor: "rgba(75,192,192,1)",
//         borderWidth: 1,
//         hoverBackgroundColor: "rgba(75,192,192,0.6)",
//         hoverBorderColor: "rgba(75,192,192,1)",
//         data: dataTotalOrders,
//       },
//     ],
//   };

//   const options = {
//     maintainAspectRatio: false,
//     scales: {
//       y: {
//         beginAtZero: true,
//       },
//       x: {
//         ticks: {
//           maxTicksLimit: 15, // Adjust this value for better readability if needed
//         },
//       },
//     },
//   };

//   const handleFilter = (value) => {
//     console.log("value", value);
//   };

//   return (
//     <div
//       className="chart-container  no-flex-grow-card"
//       style={{
//         position: "relative",
//         height: "400px",
//         width: "100%",
//         marginBottom: "1rem",
//       }}
//     >
//       <div className="d-flex gap-1 align-items-center">
//         <h1>Order Bar Chart</h1>
//         <CalendarComp
//           view="month"
//           sx={{ border: "1px solid rgb(33, 100, 145)", borderRadius: "20px" }}
//           handleFilter={handleFilter}
//         />
//       </div>
//       <Bar data={data} options={options} className=" p-2" />
//     </div>
//   );
// };

// export default BarChart;


import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import CalendarComp from '../../CalendarComp/CalendarComp';

// Helper function to generate dates for the current month
const getDaysInMonth = (month, year) => {
  const date = new Date(year, month, 1);
  const days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date).toISOString().split('T')[0]); // Format as YYYY-MM-DD
    date.setDate(date.getDate() + 1);
  }
  return days;
};

const getDaysInWeek = (date) => {
  const startOfWeek = new Date(date);
  startOfWeek.setDate(date.getDate() - date.getDay()); // Set to Sunday of the week
  
  const days = [];
  for (let i = 1; i <= 7; i++) {
    const currentDate = new Date(startOfWeek);
    currentDate.setDate(startOfWeek.getDate() + i);
    days.push(currentDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
  }
  
  return days;
};

const BarChart = ({data,handleFilter}) => { 
  // const now = new Date();
  // const daysInMonth = getDaysInMonth(now.getMonth(), now.getFullYear());
  // const daysInWeek = getDaysInWeek(now);
  // console.log("daysInMonth",daysInMonth);
  // console.log("daysInWeek",daysInWeek);
  

  // // Example data for each day of the current month for different categories
  // const generateRandomData = () => Array(daysInWeek.length).fill().map(() => Math.floor(Math.random() * 100));

  // const dataCancelledByAdmin = generateRandomData();
  // const dataCancelledByUser = generateRandomData();
  // const dataCompletedOrder = generateRandomData();
  // const dataRejectedByStore = generateRandomData();
  // const dataTotalDeliveries = generateRandomData();
  // const dataTotalOrders = generateRandomData();

  // console.log("dataCancelledByVendor",dataCancelledByAdmin);
  console.log('data',data);
  


let date=[], cancelByVendor=[],cancelByUser=[],completeOrder=[],rejectedByVendor=[],totalDeliveries=[], totalOrders=[]
data && data.length && data.forEach((d,i)=>{
    date.push(d.date)
    cancelByVendor.push(d.cancelByVendor)
    cancelByUser.push(d.cancelByUser)
    completeOrder.push(d.completeOrder)
    rejectedByVendor.push(d.rejectedByVendor)
    totalDeliveries.push(d.totalDeliveries)
    totalOrders.push(d.totalOrders)
})
  
  const BarData = {
    labels: date,
    datasets: [
      {
        label: 'Cancelled By Vendor',
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.6)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        data: cancelByVendor,
      },
      {
        label: 'Cancelled By User',
        backgroundColor: 'rgba(54,162,235,0.4)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(54,162,235,0.6)',
        hoverBorderColor: 'rgba(54,162,235,1)',
        data: cancelByUser,
      },
      {
        label: 'Completed Order',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: completeOrder,
      },
      {
        label: 'Rejected By Vendor',
        backgroundColor: 'rgba(153,102,255,0.4)',
        borderColor: 'rgba(153,102,255,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(153,102,255,0.6)',
        hoverBorderColor: 'rgba(153,102,255,1)',
        data: rejectedByVendor,
      },
      {
        label: 'Total Deliveries',
        backgroundColor: 'rgba(255,206,86,0.4)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,206,86,0.6)',
        hoverBorderColor: 'rgba(255,206,86,1)',
        data: totalDeliveries,
      },
      {
        label: 'Total Orders',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: totalOrders,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        ticks: {
          maxTicksLimit: 15, // Adjust this value for better readability if needed
        },
      },
    },
  };

  
  

  return (
    <>
    <div className='flex gap-2 no-flex-grow-card flex-col'>
        <div className='flex items-center gap-2'>
        <h1 className='text-[1rem] font-bold'>Order Bar Chart</h1>
        <CalendarComp view="date" sx={{ border: "1px solid rgb(33, 100, 145)", borderRadius: "20px" }} handleFilter={handleFilter} />
        </div>
    <div className="chart-container  " style={{ position: 'relative', height: '400px', width: '100%' }}>

      <Bar data={BarData} options={options} />
    </div>
        </div>
    </>
  );
};

export default BarChart;
