import Api from "../utils/axios";

export const dashboardApis = {
  getDashboardCounts: () => Api.get("vendor/get-dashboard-data"),
  getOrderAnalytics: (payload) => Api.post("vendor/get-order-analytics",payload),

  
};


