import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthApi from "../../apis/auth.apis";
import { toast } from "react-toastify";
import { isValidToken } from "../../utils/jwtConfig";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "../../firebase";

export const storeRegister = createAsyncThunk(
  "register",
  async (payload, { rejectWithValue }) => {
    console.log("slice register payload", payload);
    try {
      const response = await AuthApi.register(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Registration failed"
      );
    }
  }
);
export const updateCurrentUser = createAsyncThunk(
  "updateCurrentUser",
  async (payload, { rejectWithValue }) => {
    console.log("Payload", payload);

    try {
      const response = payload.cover_image
        ? await AuthApi.updateCoverImage(payload)
        : await AuthApi.updateUser(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Registration failed"
      );
    }
  }
);

export const storeLogin = createAsyncThunk(
  "login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await AuthApi.login(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Registration failed"
      );
    }
  }
);

export const storeLogout = createAsyncThunk("logout", async (payload) => {
  try {
    const response = await AuthApi.logoutUser(payload);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Logout failed");
  }
});

export const initialize = createAsyncThunk(
  "initialize",
  async (_, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken") || "";

      if (accessToken && isValidToken(accessToken)) {
        const response = await AuthApi.currentUser();
        const { user } = response.data;

        return {
          isAuthenticated: true,
          user,
        };
      } else {
        return {
          isAuthenticated: false,
          user: null,
        };
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue("Initialization failed");
    }
  }
);

export const googleLogin = async (firebaseUser) => {
  const { email, accessToken, phoneNumber } = firebaseUser;

  const payload = {
    emailOrPhone: email ? email : phoneNumber,
    token: accessToken,
    provider: true,
  };

  try {
    const res = await AuthApi.loginWithGoogleProvider(payload);
    return res.data;
  } catch (err) {
    console.log(err);
  } finally {
  }
};

export const loginWithGoogle = createAsyncThunk(
  "loginWithGoogle",
  async (payload, { rejectWithValue }) => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;

      const payload = googleLogin(user);
      return payload;
    } catch (err) {
      return rejectWithValue(err.response.data.message || "Login failed");
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState: {
    token: false,
    currentUser: null,
    loading: false,
    error: false,
    success: false,
    isInitialized: false,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Initialize builder
      .addCase(initialize.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(initialize.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialized = true;
        state.isAuthenticated = action.payload.isAuthenticated;
        state.currentUser = action.payload.user;
      })
      .addCase(initialize.rejected, (state, action) => {
        state.loading = false;
        state.isInitialized = true;
        state.isAuthenticated = false;
        state.currentUser = null;
        toast.error(action.payload || "Initialization failed");
      })
      // store register builder
      .addCase(storeRegister.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(storeRegister.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.success = true;
        state.currentUser = action.payload;
        toast.success("User Registered Successfully");
      })
      .addCase(storeRegister.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })
      // store getCurentUser builder
      .addCase(updateCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(updateCurrentUser.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);

        state.loading = false;
        state.error = false;
        state.success = true;
        state.currentUser = action.payload.data || null;
        toast.success("User updated Successfully");
      })
      .addCase(updateCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })

      // store login builder
      .addCase(storeLogin.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(storeLogin.fulfilled, (state, action) => {
        console.log("Login fulfilled action payload:", action.payload);
        state.loading = false;
        state.error = false;
        state.success = true;

        if (
          action.payload.data.user.user_role.role_id === 2 &&
          action.payload.data.user.user_role.name === "vendor" &&
          action.payload.data &&
          action.payload.data.accessToken
        ) {
          state.token = true;
          state.currentUser = action.payload.data.user || null;
          localStorage.setItem("accessToken", action.payload.data.accessToken);
          localStorage.setItem(
            "refreshToken",
            action.payload.data.refreshToken
          );
          toast.success(
            `Welcome Back, ${action.payload.data?.user?.first_name}`
          );
        } else {
          console.error("Invalid payload structure:", action.payload);
          if (
            action.payload.statusCode === 200 &&
            action.payload.data.user.user_role.role_id !== 2 &&
            action.payload.data.user.user_role.name !== "vendor"
          ) {
            toast.error("Unautherized user");
          } else {
            toast.error("Unexpected response format from server.");
          }
        }
      })
      .addCase(storeLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })

      // store login with google builder
      .addCase(loginWithGoogle.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(loginWithGoogle.fulfilled, (state, action) => {
        console.log("Login fulfilled action payload:", action.payload);
        state.loading = false;
        state.error = false;
        state.success = true;

        if (action.payload && action.payload?.data.accessToken) {
          state.token = true;
          state.currentUser = action.payload?.data.user;
          state.isAuthenticated = true;
          localStorage.setItem("accessToken", action.payload?.data.accessToken);
          localStorage.setItem(
            "refreshToken",
            action.payload?.data.refreshToken
          );
          toast.success(
            `Welcome Back,${action.payload?.data.user?.first_name} `
          );
        } else {
          console.error("Invalid payload structure:", action.payload);
          toast.error("Unexpected response format from server.");
        }
      })
      .addCase(loginWithGoogle.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      })

      // store logout builder
      .addCase(storeLogout.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(storeLogout.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.success = true;
        state.currentUser = null;
        state.token = false;
        state.isAuthenticated = false;
        window.location.href = "/";
        localStorage.clear();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      })
      .addCase(storeLogout.rejected, (state) => {
        state.loading = false;
        state.error = true;
        state.success = false;
      });
  },
});

export default storeSlice.reducer;
