import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoaderContext from "../../components/loader";
import BreadCrumb from "../../components/BreadCrumb";
import BasicTable from "../../components/Table/BasicTable";
import PaymentEarning from "../../components/DashboardComponents/Earning/PaymentEarning";
import styles from "./dashboard.module.css";
import Card from "../../components/DashboardComponents/Card/Card";
import { FaRegUser } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { MdOutlineStorefront } from "react-icons/md";
import { LiaCitySolid } from "react-icons/lia";
import { IoBasketOutline } from "react-icons/io5";
import { GiStorkDelivery } from "react-icons/gi";
import { MdOutlineCancel } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";
import { FaCoins } from "react-icons/fa6";
import { BsCashCoin } from "react-icons/bs";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import BarChart from "../../components/DashboardComponents/BarChart/BarChart";
import LineChart from "../../components/DashboardComponents/Linechart/LineChart";
import PieChart from "../../components/DashboardComponents/PiChart/PieChart";
import MonthBarChart from "../../components/DashboardComponents/BarChart/MonthBarChart";
import OrderComp from "../Order/OrderComp";
import { useFetchAll } from "../../custom/FetchApiHook";
import { dashboardApis } from "../../apis/dashboard.apis";
import { TbReplaceFilled, TbTruckReturn } from "react-icons/tb";

function Dashboard() {
  const { theme } = useSelector((state) => state.theme);

  const orderAnalytics = useFetchAll(() =>
    dashboardApis.getOrderAnalytics(
      dashboardPagination ? dashboardPagination : {}
    )
  );

  const [dashboardPagination, setDashboardPagination] = useState({
    filter: {},
  });

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
    sortOrder: "desc",
    filter: {},
  });

  const [productDetailCounts, setProductDetailCounts] = useState([
    {
      heading: "Product Details",
      contents: [
        {
          Icon: FaRegUser,
          label: "Total products",
          count: null,
        },
        {
          Icon: GrUserManager,
          label: "Total categories",
          count: null,
        },
        {
          Icon: MdOutlineStorefront,
          label: "Total subcategories",
          count: null,
        },
        // {
        //   Icon: LiaCitySolid,
        //   label: "City",
        //   count:null,
        // },
      ],
    },
    {
      heading: "Order Details",
      contents: [
        {
          Icon: IoBasketOutline,
          label: "Orders",
          count: null,
        },
        {
          Icon: GiStorkDelivery,
          label: "Deliveries",
          count: null,
        },
        {
          Icon: BiSolidPurchaseTag,
          label: "Total Item Sold",
          count: null,
        },
        {
          Icon: MdOutlineCancel,
          label: "Store Cancelled Order",
          count: null,
        },
        {
          Icon: FaRegCircleCheck,
          label: "Completed",
          count: null,
        },
      ],
    },
    {
      heading: "Payment Details",
      contents: [
        {
          Icon: FaHandHoldingUsd,
          label: "Total",
          count: null,
        },
        {
          Icon: FaWallet,
          label: "Wallet",
          count: null,
        },
        {
          Icon: FaCoins,
          label: "Promo",
          count: null,
        },
        {
          Icon: BsCashCoin,
          label: "Cash",
          count: null,
        },
        {
          Icon: RiMoneyDollarCircleFill,
          label: "Other",
          count: null,
        },
      ],
    },
  ]);

  const handlePagination = (data) => {
    console.log("data", data);
    if (data.clearPagination) {
      setPagination(data.clearPagination);
      return;
    }

    setPagination((prev) => {
      let copyPagination = { ...prev.filter };

      if (data.filter) {
        copyPagination = { ...data?.filter, ...copyPagination };
      }

      return {
        page: data.page ? data.page : 1,
        limit: data.limit ? data.limit : 5,
        sortOrder: data.sortOrder ? data.sortOrder : "desc",
        filter: copyPagination,
      };
    });
  };

  const handleFilter = (filter, value) => {
    console.log("filter", filter);
    console.log("value", value);
    if (value) {
      setDashboardPagination({
        filter: {
          startDate: value[0],
          endDate: value[1],
          // "startDate":moment(value[0]).format("DD-MM-YYYY") ,
          // "endDate":moment(value[1]).format("DD-MM-YYYY")
        },
      });
    }
  };

  const progressData = [
    // {
    //   left: {
    //     progress: 10,
    //     label: "Total",
    //     price: "₹ 1414.82",
    //   },
    //   right: {
    //     progress: 60,
    //     label: "Admin Earnings",
    //     price: "₹ 17.19",
    //   },
    // },
    {
      left: {
        progress: 20,
        label: "Order Payment",
        price: "₹ 1243.00",
      },
      right: {
        progress: 50,
        label: "Store Earning",
        price: "₹ 1243.00",
      },
    },
    {
      left: {
        progress: 20,
        label: "Delivery Payment",
        price: "₹ 171.82",
      },
      right: {
        progress: 50,
        label: "Delivery Man Earnings",
        price: "₹ 154.63",
      },
    },
  ];

  const getAllDashboardCount = async () => {
    try {
      const res = await dashboardApis.getDashboardCounts();
      console.log("res::count", res.data.data);
      const dashboardCounts = res.data.data;

      setProductDetailCounts([
        {
          heading: "Product Details",
          contents: [
            {
              Icon: FaRegUser,
              label: "Total products",
              count: dashboardCounts?.totalProduct || null,
            },
            {
              Icon: GrUserManager,
              label: "Total categories",
              count: dashboardCounts?.totalCategory || null,
            },
            {
              Icon: MdOutlineStorefront,
              label: "Total subcategories",
              count: dashboardCounts?.totalSubCategory || null,
            },
            // {
            //   Icon: LiaCitySolid,
            //   label: "City",
            //   count:null,
            // },
          ],
        },
        {
          heading: "Order Details",
          contents: [
            {
              Icon: IoBasketOutline,
              label: "Orders",
              count: dashboardCounts?.totalOrders || null,
            },
            {
              Icon: GiStorkDelivery,
              label: "Delivered",
              count: dashboardCounts?.deliveredOrders || null,
            },
            {
              Icon: BiSolidPurchaseTag,
              label: "Customer Cancelled Order",
              count: dashboardCounts?.userCancelledOrders || null,
            },
            {
              Icon: MdOutlineCancel,
              label: "Store Cancelled Order",
              count: dashboardCounts?.storeCancelledOrders || null,
            },
            {
              Icon: TbTruckReturn,
              label: "Returned Orders",
              count: dashboardCounts?.returnedOrders || null,
            },
            {
              Icon: TbReplaceFilled,
              label: "Replacement Orders",
              count: dashboardCounts?.replacementOrders || null,
            },
          ],
        },
        // {
        //   heading: "Payment Details",
        //   contents: [
        //     {
        //       Icon: FaHandHoldingUsd,
        //       label: "Total",
        //       count: null,
        //     },
        //     {
        //       Icon: FaWallet,
        //       label: "Wallet",
        //       count: null,
        //     },
        //     {
        //       Icon: FaCoins,
        //       label: "Promo",
        //       count: null,
        //     },
        //     {
        //       Icon: BsCashCoin,
        //       label: "Cash",
        //       count: null,
        //     },
        //     {
        //       Icon: RiMoneyDollarCircleFill,
        //       label: "Other",
        //       count: null,
        //     },
        //   ],
        // },
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    getAllDashboardCount();
  }, []);

  useEffect(() => {
    orderAnalytics.fetchAllData();
  }, [dashboardPagination]);

  return (
    <div className="main-content ">
      <BreadCrumb title={"Dashboard"} />
      <OrderComp pagination={pagination} handlePagination={handlePagination} />

      <div
        className={`d-flex flex-column gap-1  mt-5 ${
          theme === "light"
            ? "bg-secondaryColor text-textColorLight"
            : "bg-darkThirsary text-darkSecondary"
        } `}
      >
        <div className={`${styles.dashboardProgress}  `}>
          {/* <BasicTable /> */}
          {productDetailCounts.length
            ? productDetailCounts.map((data, i) => <Card key={i} data={data} />)
            : ""}
        </div>

        <div
          className={`${styles.dashboardProgress} ${
            theme === "light" ? styles.bgSecondaryColor : styles.bgDarkThirsary
          }`}
        >
          {progressData.map(({ left, right }, i) => (
            <PaymentEarning key={i} left={left} right={right} />
          ))}
        </div>
        <BarChart
          data={orderAnalytics?.value?.data}
          handleFilter={handleFilter}
        />
        {/* <LineChart /> */}
        {/* <div className="d-flex gap-2">
          <PieChart />
          <MonthBarChart />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
