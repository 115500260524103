import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { passwordValidationSchema } from "../../validationSchema/ValidationSchema";
import Button from "../../components/Button";
import Lable from "../../components/Lable";
import PasswordInput from "../../components/PasswordInput";
import { useSelector } from "react-redux";
import LoaderContext from "../../components/loader";
import AuthApi from "../../apis/auth.apis";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { handleLoading } = useContext(LoaderContext);
  const { loading } = useSelector((state) => state.store);

  

  const navigate = useNavigate()
  const location = useLocation();
  const { emailOrPhone } = location.state || {};

  useEffect(() => {
    handleLoading(loading);
  }, [handleLoading, loading]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    console.log("values", values);
    handleLoading(true)
    const payload = {
      emailOrPhone: emailOrPhone,
      newPassword: values?.password,
    };
    console.log("payload", payload);
    try {
      const res = await AuthApi?.resetPassword(payload);
      console.log("res", res);
      toast.success("Password reset successfully");
      navigate("/", );
      handleLoading(false)
    } catch (error) {
      console.log("error", error);
      handleLoading(false)
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <Form className="mt-12">
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="New Password" />
              <div>
                <PasswordInput
                  name="password"
                  type="password"
                  placeholder="New Password"
                  onChange={handleChange}
                  value={values.password}
                  autoComplete
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
            </div>

            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Confirm Password" />
              <div>
                <PasswordInput
                  name="confirmPassword"
                  type="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  autoComplete
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
              </div>
            </div>

            <div className="mt-8 flex gap-4 justify-between flex-wrap">
              <Button text={"Update Password"} type={"submit"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
