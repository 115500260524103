import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Formik, Form } from "formik";
import { registerValidationSchema } from "../../validationSchema/ValidationSchema";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Phone from "../../components/Phone";
import PasswordInput from "../../components/PasswordInput";
import Dropdown from "../../components/Dropdown";
import Lable from "../../components/Lable";
import { useDispatch, useSelector } from "react-redux";
import { loginWithGoogle, storeRegister } from "../../store/slices/storeSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoaderContext from "../../components/loader";
import { user_role } from "../../config/apiConfig";
import masterApi from "../../apis/master.api";
import { useFetchAll } from "../../custom/FetchApiHook";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider, facebookProvider } from "../../firebase";
import AuthApi from "../../apis/auth.apis";
import GoogleMaps from "../../components/GoogleMap/GoogleMaps";
import ArticleModel from "../Account/ArticleModel";
import { ArticlesApi } from "../../apis/profile.apis";

const Register = () => {
  const dispatch = useDispatch();
  const { handleLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.store);
  const [countryId, setCountryId] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [storeCoords, setStoreCoords] = useState();
  const [clickedAddress, setClickedAddress] = useState("");
  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const [articleData, setArticleData] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const countryData = useFetchAll(() => masterApi.getAllCountry());
  const cityData = useFetchAll(
    () => countryId && masterApi.getAllCity(countryId)
  );
  const BuisnessTypeData = useFetchAll(() => masterApi.getAllBuisnessType());
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    handleLoading(loading);
  }, [handleLoading, loading]);

  const googleRegister = (firebaseUser, defaultCountry) => {
    const { displayName, email, phoneNumber, accessToken } = firebaseUser;
    const [first_name, last_name] = displayName
      ? displayName.split(" ")
      : ["", ""];
    return {
      first_name,
      token: accessToken,
      provider: true,
      last_name,
      email,
      phone_number: phoneNumber || "",
      user_role: user_role.vendor,
      country: defaultCountry,
      password: "",
      referral_code: "",
    };
  };

  const signUpWithGoogle = async (setValues) => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      setValues(googleRegister(user));
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      alert("Google Sign-In failed. Please try again.");
    }
  };

  const initialValues = {
    email: "",
    user_role: user_role.vendor,
    first_name: null,
    last_name: null,
    phone_number: "",
    address: "",
    gender: "",
    profile_image: null,
    country: null,
    city: null,
    delivery_business: null,
    referral_code: "",
    password: "",
  };

  const country = countryData.value.data
    ? countryData.value.data.map((c, i) => {
        c.label = c.name;
        c.value = c._id;
        return c;
      })
    : [];

  const city = cityData.value.data
    ? cityData.value.data.map((c, i) => {
        c.label = c.name;
        c.value = c._id;
        return c;
      })
    : [];

  const deliveryBusiness = BuisnessTypeData.value.data
    ? BuisnessTypeData.value.data.map((c, i) => {
        c.label = c.name;
        c.value = c._id;
        return c;
      })
    : [];
  const getAddress = (add) => {
    console.log("add", add);
    setClickedAddress(add);
  };

  const handleSubmit = async (values) => {
    try {
      const res = await dispatch(storeRegister(values));

      if (res.type === "register/fulfilled") {
        navigate("/");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleReferral = async (values) => {
    console.log("Applying referral code:", values.referral_code);
    const payload = {
      referral_code: values.referral_code,
    };
    console.log(values);
    handleLoading(true);
    try {
      const res = await AuthApi.referralCode(payload);
      setReferralCode(values.referral_code);
      toast.success("Referral code added successfully");
      setIsApplied(true);
      console.log(res.data);
    } catch (err) {
      console.log(err.response?.data?.message);
      toast.error(err.response?.data?.message);
    } finally {
      handleLoading(false);
    }
  };

  const handleDeleteCode = () => {
    setReferralCode("");
    setIsApplied(false);
  };

  useEffect(() => {
    cityData.fetchAllData();
  }, [countryId]);

  const handleArticle = async (type) => {
    setIsArticleOpen(true);
    setArticleTitle(type);
    handleLoading(true);

    try {
      const data = await ArticlesApi.getArticlesDetails();
      console.log("API Article data:", data.data.data);
      // Choose which article content to display based on the type
      const articleContent =
        type === "TNC"
          ? data.data.data[0].storeTermsAndConditions
          : data.data.data[0].storePrivacyPolicy;

      setArticleData(articleContent);
    } catch (error) {
      console.error("Error fetching article data:", error);
      toast.error(error.message);
    } finally {
      handleLoading(false);
    }
  };

  // Closing the modal
  const handleClose = () => {
    setIsArticleOpen(false);
    setArticleData("");
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleChange, setFieldValue, values, setValues }) => (
        <>
          <div
            className={`flex flex-col items-center justify-center gap-5 my-10  ${
              theme === "light" ? "" : " text-darkSecondary"
            }`}
          >
            <div className="flex gap-5 items-center justify-center ">
              <span className=" flex items-center justify-center gap-3 cursor-pointer w-full rounded-full bg-[#efeeee]  p-2 shadow-2xl">
                <FcGoogle
                  className="text-4xl cursor-pointer"
                  onClick={() => signUpWithGoogle(setValues)}
                />
                {/* <span className=" font-semibold text-lg text-[#303030] "></span> */}
              </span>
              <span className=" flex items-center justify-center gap-3 cursor-pointer w-full rounded-full bg-[#506dc0] p-2 shadow-2xl ">
                <FaFacebookF
                  className="text-3xl cursor-pointer "
                  style={{ color: "white" }}
                />
                {/* <span
                      className=" font-semibold text-lg text-[#303030] "
                      // onClick={signUpWithGoogle}
                    ></span> */}
              </span>
            </div>
            <span>Or</span>
          </div>
          <Form className="mt-12">
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Store Name" />
              <div>
                <TextInput
                  name="first_name"
                  type="text"
                  placeholder="Store Name"
                  onChange={handleChange}
                  value={values.first_name}
                />
                <ErrorMessage
                  name="first_name"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="E-Mail" />
              <div>
                <TextInput
                  name="email"
                  type="email"
                  placeholder="E-mail"
                  onChange={handleChange}
                  value={values.email}
                />
                <ErrorMessage name="email" component="div" className="error" />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Country" />
              <div>
                <Dropdown
                  options={country}
                  name="country"
                  value={values.country}
                  handleOnchange={(v) => {
                    setCountryId(v._id);
                    setFieldValue("country", v._id);
                  }}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="City" />
              <div>
                <Dropdown
                  options={city}
                  name="city"
                  value={values.city}
                  handleOnchange={(v) => {
                    setFieldValue("city", v._id);
                  }}
                />
                <ErrorMessage name="city" component="div" className="error" />
              </div>
              {/* <div className="w-80 h-40 overflow-hidden">
                    <GoogleMaps
                      storeLocation={storeCoords}
                      getAddress={(v)=>getAddress(v)}
                      className="w-full h-full object-cover"
                    />
                  </div> */}
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Delivery Business" />
              <div>
                <Dropdown
                  options={deliveryBusiness}
                  name="delivery_business"
                  value={values.delivery_business}
                />
                <ErrorMessage
                  name="delivery_business"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Phone" />
              <div>
                <Phone value={values.phone_number} name="phone_number" />
                <ErrorMessage
                  name="phone_number"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Password" />
              <div>
                <PasswordInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  autoComplete
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            {isApplied && (
              <div className="flex items-center justify-between bg-green-100 text-green-800 p-2 rounded-md mb-3">
                <span>Referral Code: {referralCode}</span>
                <button
                  onClick={handleDeleteCode}
                  className="text-red-500 font-bold"
                >
                  x
                </button>
              </div>
            )}
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Referral Code" />
              <div>
                <div className="flex">
                  <TextInput
                    name="referral_code"
                    type="text"
                    placeholder="Referral Code"
                    onChange={handleChange}
                    value={values.referral_code}
                    isApplied={isApplied}
                  />
                  {!isApplied && (
                    <button
                      className=" bg-buttonColor text-secondaryColor uppercase px-4 text-xs font-bold rounded-r-full"
                      onClick={() => handleReferral(values)}
                    >
                      Apply
                    </button>
                  )}
                </div>
                <ErrorMessage
                  name="referral_code"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Address" />
              <div>
                <TextInput
                  name="address"
                  type="text"
                  placeholder="Address"
                  onChange={handleChange}
                  value={values.address}
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            <div className="flex gap-2 mt-4 items-start">
              <input type="checkbox" className="mb-2" required />

              {/* Updated Label with clickable Terms and Privacy links */}
              <p className="text-sm">
                I agree to the{" "}
                <span
                  className="text-buttonColor cursor-pointer hover:underline"
                  onClick={() => handleArticle("TNC")}
                >
                  Terms & Conditions
                </span>{" "}
                and{" "}
                <span
                  className="text-buttonColor cursor-pointer hover:underline"
                  onClick={() => handleArticle("PP")}
                >
                  Privacy Policy
                </span>
              </p>
            </div>

            <div className="mt-3">
              <Button text={"Signup"} type={"submit"} />
            </div>

            
          </Form>
          {isArticleOpen && (
            <ArticleModel
              data={articleData}
              title={
                articleTitle === "TNC"
                  ? "Terms and Conditions"
                  : "Privacy Policy"
              }
              onClose={handleClose}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default Register;
