import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import Button, { SecondaryButton } from "../../components/Button";
import Lable from "../../components/Lable";
import SidebarField from "../../components/Sidebar/SidebarField";
import TextInput from "../../components/TextInput";
import { FormField } from "../../components/FormField";
import { RxCross2, RxReload } from "react-icons/rx";
import Dropdown from "../../components/Dropdown";
import { ErrorMessage, Form, Formik } from "formik";
import promoimg from "../../assest/offer_promo.png";
import { MdEdit } from "react-icons/md";
import Phone from "../../components/Phone";
import PasswordInput from "../../components/PasswordInput";
import DeleteModal from "../../components/DeleteModal";
import cartImage from "../../assest/card_not_found.png";
import AddCardDetailModal from "../../components/AddCardDetailModal";
import LoadingContext from "../../components/loader/index";
import { toast } from "react-toastify";
import { updateCurrentUser } from "../../store/slices/storeSlice";
import ImagePreview from "../../components/ImagePreview";
import { LazyLoadImage } from "react-lazy-load-image-component";
import masterApi from "../../apis/master.api";
import { useFetchAll } from "../../custom/FetchApiHook";
import { FaRegAddressCard } from "react-icons/fa";
import {
  AdminContectApi,
  ArticlesApi,
  documentApi,
} from "../../apis/profile.apis";
import { bankDetailApi } from "../../apis/profile.apis";
import { compressImage } from "../../custom/compressImage";
import AuthApi from "../../apis/auth.apis";
import LoaderContext from "../../components/loader/index";
import GoogleMaps from "../../components/GoogleMap/GoogleMaps";
import ArticleModel from "./ArticleModel";
const Profile = () => {
  const { theme } = useSelector((state) => state.theme);
  const { handleLoading, isLoading } = useContext(LoadingContext);
  //// console.log("isLoading",isLoading);

  return (
    <div className="p-4">
      <BreadCrumb title={"Profile"} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
        <StoreDetails theme={theme} />
        <Documents theme={theme} />
        {/* <PersonalDetails theme={theme} /> */}
        <BankDetails theme={theme} />
        <Wallet theme={theme} />
        <Redeem theme={theme} />
        <ContactAdmin theme={theme} />
      </div>
    </div>
  );
};

export default Profile;

const StoreDetails = ({ theme }) => {
  const { handleLoading } = useContext(LoaderContext);
  const [visible, setVisible] = useState(false);
  const [islatitude, setLatitude] = useState("");
  const [islongitude, setLongitude] = useState("");
  const [storeCoords, setStoreCoords] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [countryId, setCountryId] = useState(null);
  const [clickedAddress, setClickedAddress] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [location,setLoation]=useState({ type: 'Point', coordinates: [] })
  const dispatch = useDispatch();
  const userDetail = useFetchAll(() => AuthApi.currentUser());
  useEffect(() => {
    setCurrentUser(userDetail.value.data);
    setLoation(userDetail.value?.data?.location)
    // console.log("currentUser:", currentUser);
  }, [userDetail.value]);

  const countryData = useFetchAll(() => masterApi.getAllCountry());
  const cityData = useFetchAll(
    () => countryId && masterApi.getAllCity(countryId)
  );

  const formatDate = (isoDate) => {
    if (!isoDate) return "";
    return new Date(isoDate).toISOString().split("T")[0];
  };

  const countryOption = countryData.value.data
    ? countryData.value.data.map((c, i) => {
        c.label = c.name;
        c.value = c._id;
        return c;
      })
    : [];

  const getAddress = (add) => {
    console.log("add", add);
    setClickedAddress(add);
  };

  const cityOption = cityData.value.data
    ? cityData.value.data.map((c, i) => {
        c.label = c.name;
        c.value = c._id;
        return c;
      })
    : [];

  const initialValues = {
    first_name: currentUser?.first_name || "",
    owner_name: currentUser?.owner_name || "",
    dob: currentUser?.dob || "",
    gender: currentUser?.gender || "",
    address: clickedAddress || currentUser?.address ||"",
    email: currentUser?.email || "",
    phone_number: currentUser?.phone_number || "",
    country: currentUser?.country?._id || "",
    city: currentUser?.city?._id || "",
    referral_code: currentUser?.referral_code || "",
    location: location || { type: 'Point', coordinates: [] },
  };

  const handleSubmit = async (values, resetForm) => {
    console.log("Update values", values);
    try {
      const res = await AuthApi.updateUser(values);
      console.log("API response:", res.data);
      userDetail.fetchAllData()
      toast.success("Store details Updated successfully");
      // dispatch(updateCurrentUser(values));
    } catch (error) {
    } finally {
      resetForm();
      setVisible(false);
    }
  };

  const handleAddMultipleImages = async (e, id = false) => {
    const { name } = e.target;
    const images = e.target.files;
    const formData = new FormData();
    compressImage(images && images[0], (ci) => {
      // formData.append("profile_image",ci)
      let payload = {};
      payload[name] = ci;
      if (images.length) {
        try {
          // const res = await AuthApi.updateUser(formData)
          //// console.log("res::Profile Image Updated", res.data.data);
          dispatch(updateCurrentUser(payload));
        } catch (error) {
          console.error("Error uploading images:", error);
          toast.error(error.message);
        }
      }
    });
  };

  handleLoading(false);

  useEffect(() => {
    // console.log("country id:", countryId);
    cityData.fetchAllData();
  }, [currentUser, countryId]);

  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <div className="flex justify-between mb-3 border-b py-4 flex-wrap">
          <p className="text-lg font-bold">Store details</p>
          <div className="flex gap-2">
            <SecondaryButton
              text="Delete account"
              textColor={"text-red-800"}
              borderColor={"border-red-800"}
              onClick={() => setDeleteModalVisible(true)}
            />
            <SecondaryButton
              text="Edit"
              onClick={() => {
                setVisible(true);
                setCountryId(currentUser?.country._id);
              }}
            />
          </div>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Shop Name</p>
          <p className="text-sm">{currentUser?.first_name || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Owner Name</p>
          <p className="text-sm">
            {currentUser?.owner_name || "Not Available"}
          </p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">D.O.B.</p>
          <p className="text-sm">
            {formatDate(currentUser?.dob) || "Not Available"}
          </p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Email</p>
          <p className="text-sm">{currentUser?.email || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Phone</p>
          <p className="text-sm">{currentUser?.phone_number || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">City</p>
          <p className="text-sm">{currentUser?.city?.name || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Country</p>
          <p className="text-sm">{currentUser?.country?.name || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Address</p>
          <p className="text-sm ">{currentUser?.address || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Referral code</p>
          <p className="text-sm">{currentUser?.referral_code || ""}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Delivery type</p>
          <p className="text-sm">
            {currentUser?.delivery_business?.name || ""}
          </p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Wallet</p>
          <p className="text-sm">₹ 30</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Total referred</p>
          <p className="text-sm">0</p>
        </div>
      </div>

      {visible ? (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values, resetForm, setFieldValue }) => (
            <Form>
              <SidebarField
                handleClose={() => setVisible(false)}
                title="Edit profile"
                button1={
                  <Button
                    text="Cancel"
                    type="button"
                    onClick={() => {
                      resetForm();
                      setVisible(false);
                    }}
                  />
                }
                button2={
                  <Button
                    type={currentUser?._id ? "Update" : "submit"}
                    text="Submit"
                    onClick={() => handleSubmit(values, resetForm)}
                  />
                }
              >
                <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                  <p>Cover Image</p>

                  <div className="relative h-fit w-[95%] shadow-xl rounded-xl m-auto">
                    <label
                      htmlFor="cover_image"
                      className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                    >
                      <input
                        type="file"
                        id="cover_image"
                        accept="image/*"
                        onChange={(e) => handleAddMultipleImages(e)}
                        name="cover_image"
                        className="h-[400px]"
                        style={{ display: "none" }}
                      />
                      <MdEdit />
                    </label>
                    <label htmlFor="cover_image">
                      {typeof currentUser?.cover_image !== "object" ? (
                        <div className="h-[250px]">
                          <LazyLoadImage
                            src={currentUser?.cover_image}
                            alt="img"
                            loading="lazy"
                            effect="blur"
                            height={"400px"}
                            wrapperProps={{
                              style: { transitionDelay: "1s" },
                            }}
                          />
                        </div>
                      ) : (
                        <img
                          src={promoimg}
                          className="h-[400px]"
                          alt="Promo Not Found"
                        />
                      )}
                    </label>
                  </div>
                  <p>Profile Image</p>
                  <div className="relative h-[20rem] w-[20rem] shadow-xl rounded-full overflow-hidden m-auto">
                    <label
                      htmlFor="image-upload"
                      className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                    >
                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        onChange={(e) => handleAddMultipleImages(e)}
                        name="profile_image"
                        style={{ display: "none" }}
                      />
                      <MdEdit />
                    </label>
                    <label htmlFor="image-upload">
                      {typeof currentUser?.profile_image !== "object" ? (
                        <LazyLoadImage
                          src={currentUser?.profile_image}
                          alt="img"
                          loading="lazy"
                          effect="blur"
                          wrapperProps={{
                            style: { transitionDelay: "1s" },
                          }}
                          className="h-full w-full object-cover"
                        />
                      ) : (
                        <img
                          src={promoimg}
                          alt="Promo Not Found"
                          className="h-full w-full object-cover"
                        />
                      )}
                    </label>
                  </div>

                  <FormField>
                    <Lable lable="Store name | EN" />
                    <TextInput
                      name="first_name"
                      type="text"
                      placeholder="Store name"
                      onChange={handleChange}
                      value={values.first_name}
                      required={true}
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField>
                    <Lable lable="Owner Name" />
                    <TextInput
                      name="owner_name"
                      type="text"
                      placeholder="Owner name"
                      onChange={handleChange}
                      value={values.owner_name}
                      required={true}
                    />
                    <ErrorMessage
                      name="owner_name"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField label="Gender">
                    <Dropdown
                      options={[
                        { label: "male", value: "male" },
                        { label: "female", value: "female" },
                        { label: "others", value: "others" },
                      ]}
                      name="gender"
                      value={values.gender}
                      onChange={(v) => setFieldValue("gender", v.value)}
                    />
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField label="D.O.B">
                    <TextInput
                      name="dob"
                      type="Date"
                      placeholder={"D.O.B"}
                      value={formatDate(values.dob)}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="dob"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField label="Country">
                    <Dropdown
                      options={countryOption}
                      name="country"
                      value={values.country?._id || ""}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="error"
                    />
                  </FormField>

                  <FormField label="City">
                    <Dropdown
                      options={cityOption}
                      name="city"
                      value={values.city?._id || ""}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="error"
                    />
                  </FormField>                  
                  <FormField label="Longitude And Latitude">
                    <TextInput
                      name="location"
                      type="text"
                      placeholder={"Longitude And Latitude"}
                      value={values.location?.coordinates}
                      onChange={handleChange}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <div className="h-80 overflow-hidden">
                    <GoogleMaps
                      storeLocation={storeCoords}
                      getAddress={async(v,latlong) =>{
                        console.log('latlong===>',latlong);
                        
                        setFieldValue('address',v)
                        const locationCopy={...values.location}
                        locationCopy.coordinates=[latlong?.lng, latlong?.lat]
                        setLoation(locationCopy)
                        setFieldValue('location',locationCopy)
                         getAddress(v,latlong)
                        }}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <FormField>
                    <div className="flex justify-between">
                      <Lable lable="Address" />
                      <RxReload onClick={() => setClickedAddress("")} />
                    </div>
                    <TextInput
                      name="address"
                      type="text"
                      placeholder="Address"
                      value={clickedAddress ? clickedAddress : values.address}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="address"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField>
                    <Lable lable="Email" />
                    <TextInput
                      name="email"
                      type="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={values.email}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  <FormField>
                    <Lable lable="Phone" />
                    <TextInput
                      value={values.phone_number}
                      onChange={handleChange}
                      name="phone_number"
                      disabled={true}
                    />
                    <ErrorMessage
                      name="phone_number"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  {/* <FormField>
                  <Lable lable="New password" />
                  <PasswordInput
                    name="new_password"
                    type="text"
                    placeholder="New Password"
                    onChange={handleChange}
                    value={values.new_password}
                  />
                  <ErrorMessage
                    name="new_password"
                    component="div"
                    className="error"
                  />
                </FormField>
                <FormField>
                  <Lable lable="Confirm password" />
                  <PasswordInput
                    name="confirm_password"
                    type="text"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                    value={values.confirm_password}
                  />
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="error"
                  />
                </FormField> */}
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      ) : null}

      {deleteModalVisible ? (
        <DeleteModal
          modalVisible={deleteModalVisible}
          handleClose={() => setDeleteModalVisible(false)}
        />
      ) : null}
    </>
  );
};

const Documents = ({ theme }) => {
  const [visible, setVisible] = useState(false);
  const handleClose = () => {
    setInitialDocuments({
      docMaster: null,
      image: "",
      name: "",
      expiry_date: "",
      unique_code: "",
    });
    setVisible(false);
  };

  const [initialDocuments, setInitialDocuments] = useState({
    docMaster: null,
    image: null,
    name: "",
    expiry_date: "",
    unique_code: "",
  });

  const { value, isLoading, handleLoading } = useFetchAll(() =>
    masterApi.getDocumentMaster()
  );
  const getallVendorDocs = useFetchAll(() => documentApi.getAllDocument());

  const documents =
    value && value.data && Array.isArray(value.data) ? value.data : [];
  //// console.log("documents",documents);

  const handleAddOrEdit = (doc) => {
    handleClose();
    //// console.log("doc",doc);
    const vendorDoc =
      getallVendorDocs.value.data.length &&
      getallVendorDocs.value.data.filter((data) => data.name === doc.name)[0];
    //// console.log("vendorDoc",vendorDoc);

    if (vendorDoc) {
      setInitialDocuments((prev) => {
        return {
          ...prev,
          docMaster: doc,
          name: doc.name,
          _id: vendorDoc._id,
          image: vendorDoc.image,
          unique_code: vendorDoc.unique_code,
          expiry_date: vendorDoc.expiry_date,
        };
      });
    } else {
      setInitialDocuments((prev) => {
        return {
          ...prev,
          docMaster: doc,
          name: doc.name,
        };
      });
    }
    setVisible(true);
  };
  //console.log("initialDocuments",initialDocuments);

  const handleAddImages = async (e, setFieldValue) => {
    const image = e.target.files[0];
    //console.log(image);

    compressImage(image && image, (ci) => {
      setFieldValue("image", ci);
    });
  };

  const renderVendorDoc = (masterDoc) => {
    const vendorDoc =
      getallVendorDocs.value.data?.length &&
      getallVendorDocs.value.data.filter(
        (data) => data.name === masterDoc.name
      )[0];
    //console.log("vendorDoc",vendorDoc);
    return (
      <div className="flex w-fit gap-2 p-3">
        <div className="w-40">
          {vendorDoc?.image ? (
            <LazyLoadImage
              src={vendorDoc.image}
              alt="img"
              loading="lazy"
              effect="blur"
              wrapperProps={{
                style: { transitionDelay: "1s" },
              }}
            />
          ) : (
            <FaRegAddressCard className="text-[5rem]" />
          )}
        </div>
        <div>
          {masterDoc.is_expiry_date && (
            <>
              <p className="text-xs">Expired date :</p>
              <p>{vendorDoc?.expiry_date || ""}</p>
            </>
          )}
          {masterDoc.is_unique_code && (
            <>
              <p className="text-xs mt-2">Unique code</p>
              <p>{vendorDoc?.unique_code || ""}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  // log @start
  //// console.log("initialDocuments",initialDocuments);
  //// console.log("getallVendorDocs.value.data",getallVendorDocs.value.data);
  // log @end

  const handleSubmit = async (value, resetForm) => {
    //console.log("value",value);

    handleLoading(true);
    try {
      const res = initialDocuments._id
        ? await documentApi.updateDocument(value, initialDocuments._id)
        : await documentApi.createDocument(value);
      //console.log("res",res.data.data);
    } catch (error) {
      //console.log("error",error);
    } finally {
      handleClose();
      resetForm();
      handleLoading(false);
    }
  };

  handleLoading(isLoading);
  useEffect(() => {
    getallVendorDocs.fetchAllData();
  }, [visible]);
  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <div className="flex justify-between mb-3 border-b py-4">
          <p className="text-lg font-bold">Documents</p>
          {/* <SecondaryButton text="Add" onClick={() => setVisible(true)} /> */}
        </div>
        {documents.length
          ? documents.map((doc, i) => (
              <div
                key={doc._id}
                className="border border-black-50 rounded p-2 my-2"
              >
                {/* {doc.is_mandatory &&<p className="text-red-800 text-sm pb-3">
            * indicates mandatory documents
          </p>} */}
                <div className="flex justify-between items-center">
                  <p>
                    {doc.name} &nbsp;
                    {doc.is_mandatory && (
                      <span
                        title=" * indicates mandatory documents"
                        className="text-red-800 cursor-pointer"
                      >
                        *
                      </span>
                    )}
                  </p>
                  <SecondaryButton
                    text="Edit"
                    onClick={() => handleAddOrEdit(doc)}
                  />
                </div>

                {/* Card */}
                {renderVendorDoc(doc)}
                {/* <div className="flex w-fit gap-2 p-3">
                  <FaRegAddressCard className="text-[5rem]" />
                  <div>
                    {doc.is_expiry_date && (
                      <>
                        <p className="text-xs">Expired date :</p>
                        <p>Jun 30, 2024</p>
                      </>
                    )}
                    {doc.is_unique_code && (
                      <>
                        <p className="text-xs mt-2">Unique code</p>
                        <p>7899</p>
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            ))
          : ""}
        <div className="border-b"></div>
      </div>

      {visible ? (
        <Formik
          initialValues={{
            image: initialDocuments.image || "",
            name: initialDocuments.name || "",
            expiry_date: initialDocuments.expiry_date || "",
            unique_code: initialDocuments.unique_code || "",
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values, setFieldValue, resetForm }) => (
            <Form>
              <SidebarField
                handleClose={() => {
                  resetForm();
                  handleClose();
                  setVisible(false);
                }}
                title="Edit document"
                button1={
                  <Button
                    text="Cancel"
                    type="button"
                    onClick={() => {
                      resetForm();
                      handleClose();
                      setVisible(false);
                    }}
                  />
                }
                button2={<Button type="submit" text="Submit" />}
              >
                <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                  <div className="relative h-fit w-[95%] shadow-xl rounded-xl m-auto">
                    <label
                      htmlFor="image"
                      className="absolute right-1 top-1 p-2 bg-buttonColor rounded-full cursor-pointer text-secondaryColor"
                    >
                      <input
                        type="file"
                        id="image"
                        accept="image/*"
                        onChange={(e) => handleAddImages(e, setFieldValue)}
                        name="image"
                        style={{ display: "none" }}
                      />
                      <MdEdit />
                    </label>

                    <label htmlFor="image">
                      {values.image !== "" &&
                      typeof values.image !== "object" ? (
                        <LazyLoadImage
                          src={values.image}
                          alt="img"
                          loading="lazy"
                          effect="blur"
                          wrapperProps={{
                            style: { transitionDelay: "1s" },
                          }}
                        />
                      ) : typeof values.image === "object" ? (
                        <ImagePreview
                          file={values.image}
                          Id="image"
                        ></ImagePreview>
                      ) : (
                        <FaRegAddressCard className="text-[10rem]" />
                      )}
                    </label>
                  </div>
                  <FormField>
                    <Lable lable="name" />
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="Document name"
                      onChange={handleChange}
                      value={values.name}
                      disabled={true}
                    />
                    <ErrorMessage
                      name="name"
                      component="div"
                      className="error"
                    />
                  </FormField>
                  {initialDocuments.docMaster?.is_expiry_date && (
                    <FormField>
                      <Lable lable="Expiry date" />
                      <TextInput
                        name="expiry_date"
                        type="date"
                        placeholder="expiry_date"
                        onChange={handleChange}
                        value={values.expiry_date}
                        required={true}
                      />
                      <ErrorMessage
                        name="expiry_date"
                        component="div"
                        className="error"
                      />
                    </FormField>
                  )}
                  {initialDocuments.docMaster?.is_unique_code && (
                    <FormField>
                      <Lable lable="Unique code" />
                      <TextInput
                        name="unique_code"
                        type="text"
                        placeholder="Unique code"
                        onChange={handleChange}
                        value={values.unique_code}
                        required={true}
                      />
                      <ErrorMessage
                        name="unique_code"
                        component="div"
                        className="error"
                      />
                    </FormField>
                  )}
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

const Wallet = ({ theme }) => {
  const [visible, setVisible] = useState(false);
  const [isCardDeailsModalOpen, setIsCardDeailsModalOpen] = useState(false);
  const [isAddWalletBalanceVisibile, setIsAddWalletBalanceVisbile] =
    useState(false);
  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <div className="flex justify-between mb-3 flex-wrap-reverse">
          {isAddWalletBalanceVisibile ? (
            <div className="w-[50%]">
              <TextInput placeholder={"0"} />
            </div>
          ) : (
            <div>
              <p className="text-lg font-bold">Current wallet balance :</p>
              <p className="text-buttonColor text-sm">INR 30</p>
            </div>
          )}
          <div className="flex flex-col justify-end items-end gap-1">
            <p
              className="cursor-pointer float-end hover:text-buttonColor text-sm"
              onClick={() => setVisible(true)}
            >
              View redeem history
            </p>
            {isAddWalletBalanceVisibile ? (
              <div className="flex gap-2">
                <SecondaryButton
                  text="Submit"
                  onClick={() => setIsAddWalletBalanceVisbile(false)}
                />
                <RxCross2
                  className="cursor-pointer"
                  onClick={() => setIsAddWalletBalanceVisbile(false)}
                />
              </div>
            ) : (
              <SecondaryButton
                text="Add"
                onClick={() => setIsAddWalletBalanceVisbile(true)}
              />
            )}
          </div>
        </div>
        <div className="flex justify-between my-5">
          <p className="text-lg font-bold">Card details</p>
          <SecondaryButton
            text="Add"
            onClick={() => setIsCardDeailsModalOpen(true)}
          />
        </div>
        <Button text="Stripe" />
        <img src={cartImage} alt="Cart" className="w-1" />
      </div>

      {visible ? (
        <SidebarField
          handleClose={() => setVisible(false)}
          title="Redeem history"
        >
          <img src={cartImage} alt="" />
        </SidebarField>
      ) : null}

      {isCardDeailsModalOpen ? (
        <AddCardDetailModal
          modalVisible={isCardDeailsModalOpen}
          handleClose={() => setIsCardDeailsModalOpen(false)}
        />
      ) : null}
    </>
  );
};

const BankDetails = ({ theme }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [bankDetails, setBankDetails] = useState(false);

  const { currentUser, loading } = useSelector((state) => state.store);
  // console.log("currentUser:", currentUser, loading);

  const FetchBankDetails = async () => {
    if (currentUser._id) {
      try {
        const details = await bankDetailApi.getBankDetailById(currentUser._id);
        setBankDetails(details?.data?.data);
        // console.log("Bank Details:", bankDetails);
      } catch (error) {
        console.error("Failed to fetch bank details", error);
      }
    } else {
      console.error("Vendor ID not found");
    }
  };
  useEffect(() => {
    FetchBankDetails();
  }, []);

  const handleSubmit = async (values, resetForm) => {
    if (currentUser?._id) {
      values.vendorId = currentUser?._id;
    } else {
      console.error("Vendor ID not found");
      toast.error("Vendor ID not found");
      return;
    }

    console.log("Form values after adding vendorId:", values);
    try {
      const res = currentUser?._id
        ? await bankDetailApi.updateBankDetail(values, bankDetails?._id)
        : await bankDetailApi.createBankDetail(values);
      console.log("API response:", res.data);
      toast.success("Bank details added successfully");
      FetchBankDetails();
      setOpenSidebar(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || "Failed to submit bank details");
    } finally {
      resetForm();
    }
  };

  const handleFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];

    compressImage(f, (ci) => {
      setFieldValue("frontImage", ci);
    });
  };
  const handleBackFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];

    compressImage(f, (ci) => {
      setFieldValue("backImage", ci);
    });
  };
  const handleAdditionalFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];

    compressImage(f, (ci) => {
      setFieldValue("additionalImage", ci);
    });
  };
  const initialValues = {
    accountHolderName: bankDetails?.accountHolderName || "",
    bankName: bankDetails?.bankName || "",
    accountNumber: bankDetails?.accountNumber || "",
    branchCode: bankDetails?.branchCode || "",
    swiftCode: bankDetails?.swiftCode || "",
    frontImage: bankDetails?.frontImage || "",
    backImage: bankDetails?.backImage || "",
    additionalImage: bankDetails?.additionalImage || "",
  };

  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <div className="flex justify-between mb-3">
          <p className="text-lg font-bold">Bank details</p>
          <SecondaryButton
            text="Add"
            onClick={() => {
              setOpenSidebar(true);
            }}
          />
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Account holder name</p>
          <p className="text-sm">{bankDetails?.accountHolderName}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Bank name</p>
          <p className="text-sm">{bankDetails?.bankName}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Bank Account number</p>
          <p className="text-sm">{bankDetails?.accountNumber}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Branch Code</p>
          <p className="text-sm">{bankDetails?.branchCode}</p>
        </div>
        <div className="mb-3 flex justify-between">
          <p className="text-sm">Swift code</p>
          <p className="text-sm">{bankDetails?.swiftCode}</p>
        </div>
      </div>
      {/* // Bank Details Sidebar @start */}
      {openSidebar ? (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleChange, values, handleSubmit, setFieldValue }) => (
            <SidebarField
              handleClose={() => setOpenSidebar(false)}
              title="Bank details"
              button1={
                <Button
                  text="Cancel"
                  type="button"
                  onClick={() => setOpenSidebar(false)}
                />
              }
              button2={
                <Button
                  text={currentUser._id ? "Update" : "Submit"}
                  onClick={handleSubmit}
                />
              }
            >
              <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                <FormField label="Account holder name">
                  <TextInput
                    name="accountHolderName"
                    type="text"
                    placeholder={"Account holder name"}
                    value={values.accountHolderName}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="accountHolderName"
                    component="div"
                    className="error"
                  />
                </FormField>

                <FormField label="Bank Name">
                  <TextInput
                    name="bankName"
                    placeholder={"Bank Name"}
                    type="text"
                    value={values.bankName}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="bankName"
                    component="div"
                    className="error"
                  />
                </FormField>

                <FormField label="Bank Account number">
                  <TextInput
                    name="accountNumber"
                    placeholder={"Bank Account number"}
                    type="number"
                    value={values.accountNumber}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="account_number"
                    component="div"
                    className="error"
                  />
                </FormField>

                <FormField label="Branch code">
                  <TextInput
                    name="branchCode"
                    placeholder={"Branch code"}
                    type="text"
                    value={values.branchCode}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="branchCode"
                    component="div"
                    className="error"
                  />
                </FormField>

                <FormField label="Swift code">
                  <TextInput
                    name="swiftCode"
                    placeholder={"Swift code"}
                    type="text"
                    value={values.swiftCode}
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="swiftCode"
                    component="div"
                    className="error"
                  />
                </FormField>

                <div className="flex justify-between  h-fit  w-[100%]">
                  <div className="flex-col mx-2 ">
                    <p>Front Photo of Passbook</p>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="frontImage"
                        className="w-32 h-32 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                      >
                        <input
                          type="file"
                          id="frontImage"
                          accept="image/*"
                          name="frontImage"
                          onChange={(e) => handleFileUpload(e, setFieldValue)}
                          style={{ display: "none" }}
                          required
                        />
                        {values.frontImage ? "" : <span>Add Images</span>}
                        {typeof values.frontImage !== "object" ? (
                          <LazyLoadImage
                            src={values.frontImage}
                            alt="img"
                            loading="lazy"
                            effect="blur w-full h-full"
                            style={{
                              width: "100%!important",
                              height: "100% !important",
                              objectFit: "contain",
                            }}
                            className="rounded-xl cursor-pointer"
                          />
                        ) : (
                          <ImagePreview
                            file={values.frontImage}
                            id="frontImage"
                          />
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="flex-col mx-2 ">
                    <p>Back Photo of Passbook</p>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="backImage"
                        className="w-32 h-32 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                      >
                        <input
                          type="file"
                          id="backImage"
                          accept="image/*"
                          name="backImage"
                          onChange={(e) =>
                            handleBackFileUpload(e, setFieldValue)
                          }
                          style={{ display: "none" }}
                          required
                        />
                        {values.backImage ? "" : <span>Add Images</span>}
                        {typeof values.backImage !== "object" ? (
                          <LazyLoadImage
                            src={values.backImage}
                            alt="img"
                            loading="lazy"
                            effect="blur w-full h-full"
                            style={{
                              width: "100%!important",
                              height: "100% !important",
                              objectFit: "contain",
                            }}
                            className="rounded-xl cursor-pointer"
                          />
                        ) : (
                          <ImagePreview
                            file={values.backImage}
                            id="backImage"
                          />
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="flex-col mx-2 ">
                    <p>Additional Photo</p>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="additionalImage"
                        className="w-32 h-32 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                      >
                        <input
                          type="file"
                          id="additionalImage"
                          accept="image/*"
                          name="additionalImage"
                          onChange={(e) =>
                            handleAdditionalFileUpload(e, setFieldValue)
                          }
                          style={{ display: "none" }}
                          required
                        />
                        {values.additionalImage ? "" : <span>Add Images</span>}
                        {typeof values.additionalImage !== "object" ? (
                          <LazyLoadImage
                            src={values.additionalImage}
                            alt="img"
                            loading="lazy"
                            effect="blur w-full h-full"
                            style={{
                              width: "100%!important",
                              height: "100% !important",
                              objectFit: "contain",
                            }}
                            className="rounded-xl cursor-pointer"
                          />
                        ) : (
                          <ImagePreview
                            file={values.additionalImage}
                            id="additionalImage"
                          />
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </Form>
            </SidebarField>
          )}
        </Formik>
      ) : null}
      {/* // Bank Details Sidebar @end */}
    </>
  );
};

const Redeem = ({ theme }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <div className="flex justify-between mb-3 py-4">
          <p className="text-lg font-bold">Redeem points</p>
          <p
            className="cursor-pointer hover:text-buttonColor text-sm"
            onClick={() => setVisible(true)}
          >
            View redeem history
          </p>
        </div>
        <Lable lable="Total redeem points : 7" />
        <div className="grid grid-cols-2 gap-3 my-3 mx-1">
          <TextInput placeholder="Enter Points" name="redeem" type="text" />
          <SecondaryButton text={"Redeem"} />
        </div>
      </div>
      {visible ? (
        <SidebarField
          handleClose={() => setVisible(false)}
          title="Redeem history"
        >
          <img src={cartImage} alt="" />
        </SidebarField>
      ) : null}
    </>
  );
};

const ContactAdmin = ({ theme }) => {
  const { handleLoading } = useContext(LoadingContext);
  const [adminContectDetail, setAdminContectDetail] = useState("");
  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const [articleData, setArticleData] = useState("");
  const [articleTitle, setArticleTitle] = useState("");
  const adminContect = useFetchAll(() => AdminContectApi.getAdminContect());

  // Fetching admin contact details when data is available
  useEffect(() => {
    if (adminContect?.value?.data) {
      setAdminContectDetail(adminContect.value.data[0]);
    }
  }, [adminContect]);

  // Function to open the article model with the appropriate data
  const handleArticle = async (type) => {
    setIsArticleOpen(true);
    setArticleTitle(type);
    handleLoading(true);

    try {
      const data = await ArticlesApi.getArticlesDetails();
      console.log("API Article data:", data.data.data);
      // Choose which article content to display based on the type
      const articleContent =
        type === "TNC"
          ? data.data.data[0].storeTermsAndConditions
          : data.data.data[0].storePrivacyPolicy;

      setArticleData(articleContent);
    } catch (error) {
      console.error("Error fetching article data:", error);
      toast.error(error.message);
    } finally {
      handleLoading(false);
    }
  };

  // Closing the modal
  const handleClose = () => {
    setIsArticleOpen(false);
    setArticleData("");
  };

  return (
    <>
      <div
        className={`p-5 w-full rounded-lg shadow-2xl ${
          theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
        }`}
      >
        <p className="text-lg font-bold mb-3">Contact Admin</p>
        <div className="flex flex-col items-end text-right">
          <div className="mb-3">
            <p className="text-sm">Email</p>
            <p className="text-sm font-semibold">
              {adminContectDetail?.contactEmail}
            </p>
          </div>
          <div className="mb-3">
            <p className="text-sm">Phone</p>
            <p className="text-sm font-semibold">
              {adminContectDetail?.contactPhone}
            </p>
          </div>

          {/* Terms & Conditions Link */}
          <p
            className="text-sm mb-3 cursor-pointer hover:text-buttonColor"
            onClick={() => handleArticle("TNC")}
          >
            Terms & Conditions
          </p>

          {/* Privacy Policy Link */}
          <p
            className="text-sm mb-3 cursor-pointer hover:text-buttonColor"
            onClick={() => handleArticle("PP")}
          >
            Privacy Policy
          </p>
        </div>
      </div>

      {/* Article Model */}
      {isArticleOpen && (
        <ArticleModel
          data={articleData}
          title={
            articleTitle === "TNC" ? "Terms and Conditions" : "Privacy Policy"
          }
          onClose={handleClose}
        />
      )}
    </>
  );
};
