import Api from "../utils/axios";


export const documentApi = {
    createDocument: (payload) => Api.post("master/create-document", payload,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }),
    updateDocument: (payload, id) => Api.put(`master/update-document/${id}`, payload,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }),
    getDocumentById: (id) => Api.get(`master/get-document/${id}`),
    getAllDocument: () => Api.get("master/get-all-document"),
    deleteDocument: (id) => Api.delete(`master/delete-document/${id}`)
  };


export const bankDetailApi = {
    createBankDetail: (payload) => Api.post("vendor/create-bank-detail", payload,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }),
    updateBankDetail: (payload, id) => Api.put(`vendor/update-bank-detail/${id}`, payload,{
        headers: {
            "Content-Type": "multipart/form-data",
          },
    }),
    getBankDetailById: (id) => Api.get(`vendor/get-bank-detail/${id}`),
    // getAllDocument: () => Api.get("master/get-all-document"),
    deleteBankDetail: (id) => Api.delete(`master/delete-document/${id}`)
  };
  export const AdminContectApi ={
    getAdminContect: () => Api.get("vendor/get-all-admin-logo-settings"),
  }

  export const ArticlesApi={
    getArticlesDetails: ()=>Api.get("vendor/get-all-privacy-terms"),
  }