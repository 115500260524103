import axios from "axios";
import { toast } from "react-toastify";
import AuthApi from "../apis/auth.apis";
import { server } from "../config/apiConfig";

const accessToken = localStorage.getItem("accessToken");
const refreshToken = localStorage.getItem("refreshToken");
let isToastShown = false;

const axiosInstance = axios.create({
  baseURL: server,
});

let isRefreshing = false;
let failedQueue = [];
let toastShown = false;

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken !== null) {
      config.headers["cookies"] = `${refreshToken}`;
    }
    if (token !== null) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    console.error("Request Error:", error); // Developer-friendly log
    toast.error("Unable to fetch data at the moment. Please try again later."); // User-friendly message
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    isToastShown = false;
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // console.log(error);

    if (!error.response) {
      console.error("Network/Server Error:", error);
      toast.error("Network error! Please check your internet connection.");
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 403:
        if (!originalRequest._retry) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = token;
                return axiosInstance(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          isRefreshing = true;

          const refreshToken = localStorage.getItem("refreshToken");
          return new Promise((resolve, reject) => {
            AuthApi.generateAccessToken({ refreshToken })
              .then((res) => {
                const newAccessToken = res.data?.data?.accessToken;
                const newRefreshToken = res.data?.data?.refreshToken;
                localStorage.setItem("accessToken", newAccessToken);
                localStorage.setItem("refreshToken", newRefreshToken);
                axiosInstance.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${newAccessToken}`;
                originalRequest.headers[
                  "Authorization"
                ] = `Bearer ${newAccessToken}`;
                processQueue(null, newAccessToken);
                resolve(axiosInstance(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                localStorage.clear();
                toast.error("Session expired !! Please login again");
                window.location.href = "/";
                reject(err);
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        }
        break;
      case 420:
        localStorage.clear();
        if (!toastShown) {
          toast.error("Session Expired!!");
          toastShown = true;
        }
        if (window.__persistor) {
          window.__persistor.purge();
        }

        break;
      case 401:
        if (!isToastShown) {
          toast.error("Invalid user credentials");
          if (accessToken === null && refreshToken === null) {
            localStorage.clear();
            window.location.href = "/";
          }
          // window.location.href = "/";
          isToastShown = true;
          setTimeout(() => {
            isToastShown = false;
          }, 3000);
        }
        break;
      case 404:
        if (!isToastShown) {
          toast.error(error.response.data.message);
          isToastShown = true;
          setTimeout(() => {
            isToastShown = false;
          }, 3000);
        }
        break;
      default:
        console.error("API Error:", error.response);
        toast.error(
          error.response.data.message ||
            "An unexpected error occurred. Please try again."
        );
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
