import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { loginValidationSchema } from "../../validationSchema/ValidationSchema";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Phone from "../../components/Phone";
import Lable from "../../components/Lable";
import Dropdown from "../../components/Dropdown";
import PasswordInput from "../../components/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { loginWithGoogle, storeLogin } from "../../store/slices/storeSlice";
import LoaderContext from "../../components/loader";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

const Login = () => {
  const { handleLoading } = useContext(LoaderContext);
  const { loading } = useSelector((state) => state.store);

  const signUpWithGoogle = async () => {
    try {
      const result = await dispatch(loginWithGoogle());
      console.log("Google Sign-In Successful:", result);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      alert("Google Sign-In failed. Please try again.");
    }
  };

  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    handleLoading(loading);
  }, [handleLoading, loading]);

  const initialValues = {
    email: "",
    password: "",
  };

  // const store = [
  //   { id: "1", label: "Store", value: "Store" },
  //   { id: "2", label: "Substore", value: "Substore" },
  // ];

  const handleSubmit = async (values) => {
    const payload = {
      emailOrPhone: toggle ? values.email : values.phone,
      password: values.password,
    };

    try {
      dispatch(storeLogin(payload));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div
        className={`flex flex-col items-center justify-center gap-5 my-10 ${
          theme === "light" ? "" : " text-darkSecondary"
        }`}
      >
        <div className="flex gap-5 items-center justify-center ">
          <span className=" flex items-center justify-center gap-3 cursor-pointer w-full rounded-full bg-[#efeeee]  p-2 shadow-2xl">
            <FcGoogle
              className="text-4xl cursor-pointer"
              onClick={signUpWithGoogle}
            />
            {/* <span className=" font-semibold text-lg text-[#303030] "></span> */}
          </span>
          <span className=" flex items-center justify-center gap-3 cursor-pointer w-full rounded-full bg-[#506dc0] p-2 shadow-2xl ">
            <FaFacebookF
              className="text-3xl cursor-pointer "
              style={{ color: "white" }}
            />
            {/* <span
                      className=" font-semibold text-lg text-[#303030] "
                      // onClick={signUpWithGoogle}
                    ></span> */}
          </span>
        </div>
        <span>Or</span>
      </div>
      <div className={`flex gap-2`}>
        <button
          onClick={() => setToggle(false)}
          className={`${
            !toggle
              ? "active"
              : `${
                  theme === "light"
                    ? "bg-secondaryColor text-textColorLight"
                    : "bg-darkPrimary text-darkSecondary"
                }`
          }`}
        >
          Phone
        </button>
        <span> | </span>
        <button
          onClick={() => setToggle(true)}
          className={`${
            toggle
              ? "active"
              : `${
                  theme === "light"
                    ? "bg-secondaryColor text-textColorLight"
                    : "bg-darkPrimary text-darkSecondary"
                }`
          }`}
        >
          Email
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={loginValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <Form className="mt-12">
            {!toggle ? (
              <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
                <Lable lable="Phone" />
                <div>
                  <Phone value={values.phone} name="phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            ) : (
              <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
                <Lable lable="Email" />
                <div>
                  <TextInput
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    onChange={handleChange}
                    value={values.email ? values.email : ""}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            )}
            <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Password" />
              <div>
                <PasswordInput
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password}
                  autoComplete
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
              </div>
            </div>
            {/* <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
              <Lable lable="Store" />
              <div>
                <Dropdown options={store} name="store" value={values.store} />
                <ErrorMessage name="store" component="div" className="error" />
              </div>
            </div> */}
            <div className="mt-8 flex gap-4 justify-between flex-wrap">
              <NavLink to={"forgot-password"} className="active">
                Forgot Password
              </NavLink>
              <Button text={"signin"} type={"submit"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
