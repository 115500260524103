import React from "react";
import { useSelector } from "react-redux";

const OrderStatusTimeline = ({ orderStatus }) => {
  const { theme } = useSelector((state) => state.theme);

  if (!orderStatus || orderStatus.length === 0) {
    return (
      <div className="flex justify-center items-center h-full">
        <h1 className="text-lg font-medium text-gray-700">
          No details available
        </h1>
      </div>
    );
  }

  return (
    <div className="p-6 rounded-lg shadow-lg bg-white">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Order Status</h2>
      <div className="relative">
        {orderStatus.map((status, index) => (
          <div key={index} className="flex items-start mb-6">
            <div
              className={`w-[17px] h-4 rounded-full border-4 border-green-600 ${
                index === 0 ? "mt-1" : "mt-0"
              }`}
            />
            <div className="ml-4">
              <h3 className="text-lg font-bold capitalize text-gray-800">
                {status.status}
              </h3>
              <p className="text-sm text-gray-600">
                {new Date(status.updatedAt).toLocaleString()}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                {status.details || "No additional details available"}
              </p>
            </div>
          </div>
        ))}
        <div
          className={`absolute left-2 top-0 bottom-0 w-1 bg-blue-600 ${
            orderStatus.length > 1 ? "h-full" : "h-0"
          }`}
        />
      </div>
    </div>
  );
};

export default OrderStatusTimeline;
