import { ErrorMessage, Form, Formik } from "formik";
import "./itemList.style.css";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaCamera, FaRegCheckCircle, FaSearch } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import { IoAddCircleOutline } from "react-icons/io5";
import { TbPoint } from "react-icons/tb";
import BreadCrumb from "../../components/BreadCrumb";
import Button, { SecondaryButton } from "../../components/Button";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import { FormField } from "../../components/FormField";
import Lable from "../../components/Lable";
import SidebarField from "../../components/Sidebar/SidebarField";
import SidebarRight from "../../components/Sidebar/SidebarRight";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import ToggleField from "../../components/ToggleField";
import ReactQuill from "react-quill";
import PaginatorCom from "../../components/Paginator/PaginatorCom";

import {
  CategoryApi,
  modifierCategory,
  productApi,
  productImageApi,
  ProductmodifierApi,
  productVariantApi,
  SubCategoryApi,
  TaxRegimeApi,
} from "../../apis/ProductListing.apis";
import LoadingContext from "../../components/loader/index";
import DropdownMenubar from "../../components/Menubar/DropdownMenubar";
import ImagePreview from "../../components/ImagePreview";
import {
  MdDeleteOutline,
  MdOutlineCancel,
  MdOutlineDelete,
} from "react-icons/md";
import { toast } from "react-toastify";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import DeltaCard from "../../components/DeltaCard";
import { compressImage } from "../../custom/compressImage";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tooltip from "../../components/Tooltip/Tooltip";
import { TiEdit } from "react-icons/ti";
import { IoMdAdd, IoMdAddCircleOutline, IoMdRemove } from "react-icons/io";
import Variant from "./Veriant";
import masterApi from "../../apis/master.api";
// import { BsBorder } from "react-icons/bs";
import { Divider } from "primereact/divider";
// import { productSchema } from "../../validationSchema/ValidationSchema";
import LoaderContext from "../../components/loader/index";
import { useFetchAll } from "../../custom/FetchApiHook";
import { formattedAmount } from "../../utils/helpers/Functions.helper";


const ItemList = () => {
  const debounceTimeout = useRef(null);
  const { handleLoading } = useContext(LoadingContext);

  const [allTax, setAllTax] = useState([]);
  const [addItemVisible, setAddItemVisible] = useState(false);
  const [addModifier, setAddModifier] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [step, setStep] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [Taxes, setTaxes] = useState([]);
  const [deliveryType,setDeliveryType]=useState(null)
  const [productDetailsWithTax,setProductDetailsWithTax]=useState([])

  const [productPagination, setProductPagination] = useState({
    page: 1,
    limit: 8,
    sortOrder: "desc",
    filter: {},
    totalPages: 1,
    totalCount: 5,
  });
  const [editData, setEditData] = useState("");
  const [editProduct, setEditProduct] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [selectedModifierCategory, setSelectedModifierCategory] =
    useState(null);
  const [modifierCategories, setModifierCategories] = useState([]);
  const [productModifiers, setProductModifiers] = useState([]);
  const [isVariant, setIsVariant] = useState(false);
  const [allVariantData, setAllVariantData] = useState(null);
  const [editVariantData, setEditVariantData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({
    itemName: null,
    itemData: null,
  });

  const [is_color, setColorPicker] = useState(false);
  const [isShowDescriptionid, setIsShowDescriptionid] = useState(null);

  const product_details_info = {
    product_size: "Enter a size of product eg. 120GB, S/M/L/XL/XXL, 1 liter!",
    mrp_price: "Enter a MRP Price of product!",
    sales_price: "Enter a Sales Price of product!",
    stock: "Enter a number of stock!",
    product_width: "Enter a Width in of product in centimetre!",
    product_height: "Enter a Height of product in centimetre !",
    product_depth: "Enter a Depth of product in centimetre!",
    product_weight: "Enter a Weight of product in gram!",
  };

  //console.log('editProduct',editProduct);

  const initialProductValues = {
    primary_image: editProduct?.primary_image || null,
    name: editProduct?.name || null,
    brand: editProduct?.brand || null,
    model: editProduct?.model || null,
    serial_number: editProduct?.serial_number || null,
    // mrp_price: editProduct?.mrp_price?.$numberDecimal || 0.0,
    // sales_price: editProduct?.sales_price?.$numberDecimal || 0.0,
    // product_size: editProduct?.product_size || "",
    // //  in_stock: editProduct?.in_stock || true,
    // stock: editProduct?.stock || 0,
    product_color: editProduct?.product_color || "",
    product_details:
      editProduct === "" || editProduct?.product_details?.length === 0
        ? [
            {
              product_size: null,
              mrp_price: null,
              sales_price: null,
              stock: null,
              product_width: null,
              product_height: null,
              product_depth: 0,
              product_weight: null,
            },
          ]
        : editProduct?.product_details,
    tax: editProduct?.tax?._id || null,
    sub_category: editProduct?.subCategory?._id || null,
    additional_info: editProduct?.additional_info || null,
    description: editProduct?.description || null,
    specification: editProduct?.specification || null,
    cancellation_policy: editProduct?.cancellation_policy || null,
    replacement_policy: editProduct?.replacement_policy || null,
    return_policy: editProduct?.return_policy || null,
    is_visible: editProduct?.is_visible || true,
    sequence: editProduct?.sequence || null,
  };


  
  const taxRegimeData = useFetchAll(() => TaxRegimeApi.getAllRegimeApi());
  const taxOptions=taxRegimeData.value?.data?taxRegimeData.value?.data?.map((d)=>{
    d.label=d.name
    d.value=d._id
    return d
  }):[{}] 

  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = (name, value) => {
    // console.log(value);

    setDeleteModalData((prev) => {
      return {
        itemName: name,
        itemData: value,
      };
    });
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    handleLoading(true);
    // console.log("deleteModalData", deleteModalData);

    try {
      let res;

      if (deleteModalData.itemName === "product") {
        res = await productApi.deleteProduct(deleteModalData.itemData._id);
        toast.success(`${res.data.message}`);
        getAllProducts();
      }

      if (deleteModalData.itemName === "variant") {
        res = await productVariantApi.deleteProductVariant(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllProductVariants();
      }
      if (deleteModalData.itemName === "modifier_category") {
        res = await ProductmodifierApi.deleteModifierMapping(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllProductModifier();
      }

      if (deleteModalData.itemName === "product_images") {
        res = await productImageApi.deleteProductImage(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllProductImages();
      }

      if (deleteModalData.itemName === "subcategory") {
        res = await SubCategoryApi.deleteSubCategory(
          deleteModalData.itemData._id
        );
        toast.success(`${res.data.message}`);
        getAllSubcategory();
      }
      //console.log(res);
    } catch (err) {
      //console.log(err);
      // toast.error(`${err.message}`)
    } finally {
      setDeleteModalData({ itemName: null, itemData: null });
      handleLoading(false);
      handleDeleteClose();
    }
  };

  const handleOpen = () => {
    setEditVisible(true);
  };

  const handleClose = () => {
    setEditData("");
    setEditVisible(false);
  };
  const handleCloseItem = () => {
    setEditProduct("");
    setColorPicker(false);
    setImagesList([]);
    setSelectedModifierCategory(null);
    setModifierCategories([]);
    setProductModifiers([]);
    setAddItemVisible(false);
    setDeliveryType(null);
    setProductDetailsWithTax([]);
    setTaxes([])
    setStep(0);
  };

  const handleProductNav = (index) => {
    //console.log();
    if (editProduct._id) {
      setStep(index);
    }
  };

  
const TaxCalculation=(data=false)=>{

  // console.log('deliveryType',deliveryType);
  // console.log('Taxes',Taxes);
  // console.log('editData',editProduct);
  // console.log('productDetailsWithTaxCopy',productDetailsWithTaxCopy);
  const productDetailsWithTaxCopy=data?data:[...productDetailsWithTax]

  const deliveryTypeCopy=deliveryType?{...deliveryType}:null
  const TaxesCopy=Taxes.length?[...Taxes]:editProduct?.tax?.taxes
  
  
  if (productDetailsWithTaxCopy?.length && TaxesCopy?.length) {
    productDetailsWithTaxCopy.forEach((item, index) => {
      if (item.sales_price) {
        item.mrp_price = item?.mrp_price?.$numberDecimal
          ? item?.mrp_price?.$numberDecimal
          : item.mrp_price;
        item.sales_price = item?.sales_price?.$numberDecimal
          ? item?.sales_price?.$numberDecimal
          : item.sales_price;            

        const standardTax = [];
        const standardTotalTaxAmount = TaxesCopy.filter(
          (t) => t.taxType !== "TAX"
        ) // Exclude items where taxType is "TAX"
          .reduce((sum, t) => {
            // console.log("Sum",sum);
            // console.log("TTTTT",t);

            const rate = parseFloat(t.rate.$numberDecimal);
            //console.log("rate", rate);
            const salesPrice = parseFloat(item.sales_price);
            const taxAmount = (rate * salesPrice) / 100;
            t.taxAmount = parseFloat(taxAmount).toFixed(2);
            // console.log("taxAmount",taxAmount);

            const data = {
              name: t.name,
              rate: parseFloat(rate),
              taxType: "REBASE TAX",
              taxAmount: taxAmount,
            };
            standardTax.push(data);
            return sum + taxAmount;
          }, 0);

        const additionalFee = deliveryTypeCopy?.additionalFee;
        const closingFee = deliveryTypeCopy?.closingFeeRate;
        const rebase = parseFloat(
          parseFloat(standardTotalTaxAmount) + parseFloat(item?.sales_price)
        ).toFixed(2);
        const referralFee =
          (parseFloat(rebase) * parseFloat(deliveryTypeCopy?.referralFeeRate)) /
          100;
        const taxableAmount =
          parseFloat(rebase) +
          parseFloat(referralFee) +
          parseFloat(closingFee) +
          parseFloat(additionalFee);

        const Vat = TaxesCopy.filter((t) => t.taxType === "TAX").map((d) => {
          // d.rate=parseFloat(d.rate.$numberDecimal);

          d.taxAmount = parseFloat(
            (parseFloat(taxableAmount) *
              parseFloat(d?.rate?.$numberDecimal)) /
              100
          ).toFixed(2);
          const data = {
            name: d?.name,
            rate: parseFloat(d?.rate?.$numberDecimal),
            taxType: "REBASE TAX",
            taxAmount: parseFloat(
              (parseFloat(taxableAmount) *
                parseFloat(d?.rate?.$numberDecimal)) /
                100
            ).toFixed(2),
          };
          // standardTax.push(d)
          return data;
        });
        const data = {
          standardTax: standardTax,
          standardTotalTaxAmount: parseFloat(standardTotalTaxAmount).toFixed(
            2
          ),
          referralFee: {percentage:deliveryTypeCopy?.referralFeeRate,amount:parseFloat(referralFee).toFixed(2)},
          closingFee: parseFloat(closingFee).toFixed(2),
          additionalFee: parseFloat(additionalFee).toFixed(2),
          rebase: parseFloat(rebase).toFixed(2),
          taxableAmount: parseFloat(taxableAmount).toFixed(2),
          vat: Vat,
          grandTotal: parseFloat(
            taxableAmount + parseFloat(Vat[0]?.taxAmount)
          ).toFixed(2),
        };
        item.taxData = data;
        productDetailsWithTaxCopy[index] = item;
        setProductDetailsWithTax(productDetailsWithTaxCopy)
        return productDetailsWithTaxCopy
      }
    });
  }
}

  const handleProductClick = (value) => {
    setIsShowDescriptionid(
      isShowDescriptionid === value._id ? null : value._id
    );
    handleCloseItem();
    //console.log("value", value);
    if (!value.product_details) {
      value.product_details = [
        {
          product_size: null,
          mrp_price: null,
          sales_price: null,
          stock: null,
          product_width: null,
          product_height: null,
          product_depth: 0,
          product_weight: null,
        },
      ];
    }

    setTimeout(() => {
      if (value!=="") {
        setProductDetailsWithTax(value.product_details);
        setTaxes(value?.tax?.taxes)
        const subCategoryData= subCategory.filter((s)=>s._id===value?.subCategory?._id)
        getDeliveryTypeById(subCategoryData[0]?.category?.delivery_calculation)
        TaxCalculation()
      }
      setEditProduct(value);
      value.product_color && setColorPicker(true);
      setAddItemVisible(true);
      setStep(0);
    }, 100);
  };

  const handleNext = async (values, resetForm) => {
    //console.log("Values", values);

    const primary__image = document.getElementById("primary__image");
    const item_name = document.getElementById("item_name");
    const product_details = document.getElementById("product_details");
    const mrp_price = document.getElementById("mrp_price");
    const sales_price = document.getElementById("sales_price");
    const sequence_number = document.getElementById("sequence_number");
    const sub_category = document.getElementById("sub_category");
    const description = document.getElementById("description");
    const specification = document.getElementById("specification");
    const cancellation_policy = document.getElementById("cancellation_policy");
    const replacement_policy = document.getElementById("replacement_policy");
    const return_policy = document.getElementById("return_policy");

    setTimeout(() => {
      if (primary__image) primary__image.classList.remove("blink-shadow");
      if (item_name) item_name.classList.remove("blink-shadow");
      if (product_details) product_details.classList.remove("blink-shadow");
      if (mrp_price) mrp_price.classList.remove("blink-shadow");
      if (sales_price) sales_price.classList.remove("blink-shadow");
      if (sequence_number) sequence_number.classList.remove("blink-shadow");
      if (sub_category) sub_category.classList.remove("blink-shadow");
      if (description) description.classList.remove("blink-shadow");
      if (specification) specification.classList.remove("blink-shadow");
      if (cancellation_policy)
        cancellation_policy.classList.remove("blink-shadow");
      if (replacement_policy)
        replacement_policy.classList.remove("blink-shadow");
      if (return_policy) return_policy.classList.remove("blink-shadow");
    }, 3000);

    const removeClassList = (id) => {
      setTimeout(() => {
        if (id) id.classList.remove("blink-shadow");
      }, 3000);
    };
    const validateProductDetail = () => {
      let key = [];
      if (values.product_details.length === 1) {
        values.product_details.forEach((data, index) => {
          Object.keys(data).forEach((item, i) => {
            if (item !== "product_depth" && item !== "product_size") {
              //console.log(`${item}_${index}`);
              const fieldID = `${item}_${index}`;

              const id = document.getElementById(fieldID);
              //  console.log('id',id);

              if (id) {
                (values.product_details[index][item] === "" ||
                  values.product_details[index][item] === null) &&
                  key.push(`${item.replace("_", " ")} `);
                if (
                  id &&
                  (values.product_details[index][item] === "" ||
                    values.product_details[index][item] === null)
                )
                  id.classList.add("blink-shadow");
                removeClassList(id);
              }
            }
          });
        });
      } else {
        values.product_details.forEach((data, index) => {
          Object.keys(data).forEach((item, i) => {
            if (item !== "product_depth") {
              const id = document.getElementById(`${item}_${index}`);
              if (id) {
                (values.product_details[index][item] === "" ||
                  values.product_details[index][item] === null) &&
                  key.push(`${item.replace("_", " ")} `);
                if (
                  id &&
                  (values.product_details[index][item] === "" ||
                    values.product_details[index][item] === null)
                )
                  id.classList.add("blink-shadow");
                removeClassList(id);
              }
            }
          });
        });
      }
      key.length && toast(`${key.toString()} must be required!`);
      const isRequired = key.length ? true : false;
      return isRequired;
    };

    if (!values.primary_image) {
      toast("Primary Image is required !!");
      if (primary__image) primary__image.classList.add("blink-shadow");
      return;
    } else if (!values.name) {
      toast("Item name is required !!");
      if (item_name) item_name.classList.add("blink-shadow");
      return;
    } else if (validateProductDetail(values.product_details)) {
      return;
      // } else if (!values.mrp_price) {
      //   toast("MRP Price is required !!");
      //   if (mrp_price) mrp_price.classList.add("blink-shadow");
      //   return;
      // } else if (!values.sales_price) {
      //   toast("Sales price is required !!");
      //   if (sales_price) sales_price.classList.add("blink-shadow");
      //   return;
    } else if (!values.sub_category) {
      toast("Subcategory is required !!");
      if (sub_category) sub_category.classList.add("blink-shadow");
      return;
    } else if (!values.description || values.description === "<p><br></p>") {
      toast("Description is required !!");
      if (description) description.classList.add("blink-shadow");
      return;
    } else if (
      !values.specification ||
      values.specification === "<p><br></p>"
    ) {
      toast("Specification is required !!");
      if (specification) specification.classList.add("blink-shadow");
      return;
    } else if (
      !values.cancellation_policy ||
      values.cancellation_policy === "<p><br></p>"
    ) {
      toast("cancellation is required !!");
      if (cancellation_policy)
        cancellation_policy.classList.add("blink-shadow");
      return;
    } else if (
      !values.replacement_policy ||
      values.replacement_policy === "<p><br></p>"
    ) {
      toast("replacement policy is required !!");
      if (replacement_policy) replacement_policy.classList.add("blink-shadow");
      return;
    } else if (
      !values.return_policy ||
      values.return_policy === "<p><br></p>"
    ) {
      toast("return policy is required !!");
      if (return_policy) return_policy.classList.add("blink-shadow");
      return;
    } else if (!values.sequence) {
      toast("Sequence number is required !!");
      if (sequence_number) sequence_number.classList.add("blink-shadow");
      return;
    }

    handleLoading(true);
    if (step === 0) {
      try {
        console.log('Next Step::0',productDetailsWithTax);
        values.product_details=productDetailsWithTax
        const id= editProduct?.product?._id? editProduct?.product?._id: editProduct?._id
        //console.log("id============>",id);
        
        const resProduct = id
          ? await productApi.updateProduct(values, id)
          : await productApi.createProduct(values);
        //console.log("resProduct", resProduct.data.data);
        setEditProduct(resProduct?.data?.data);
        // resetForm(); 
      } catch (error) {
        //console.log(error);
      }
    }
    if (step === 2) {
      handleCloseItem();
      getAllProducts();
      handleLoading(false);
      return;
    }

    handleLoading(false);
    setStep((prevStep) => Math.min(prevStep + 1, 2));
  };

  const handleBack = async () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const handleAddMultipleImages = async (e, id = false) => {
    const images = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
      formData.append("image", images[i]);
    }
    //console.log("id",id);
    //console.log("editProduct",editProduct);
    const ProductId=editProduct?.product?._id?editProduct?.product?._id:editProduct?._id

    !id && formData.append("product", ProductId);

    if (images.length) {
      handleLoading(true);
      try {
        const res = id
          ? await productImageApi.updateProductImage(formData, id)
          : await productImageApi.createProductImages(formData);
        // console.log("res::Multiple Image Upload", res.data.data);
        getAllProductImages();
      } catch (error) {
        console.error("Error uploading images:", error);
        toast.error(error.message);
      }
      handleLoading(false);
    }
  };

  // const handleDeleteImages = async (id) => {
  //   if (id) {
  //     handleLoading(true);
  //     try {
  //       const res = await productImageApi.deleteProductImage(id);
  //       console.log("res::Image Delete", res.data.data);
  //       getAllProductImages();
  //     } catch (error) {
  //       console.error("Error Deleteing images:", error);
  //     }
  //     handleLoading(false);
  //   }
  // };

  const handleFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];

    compressImage(f, (ci) => {
      setFieldValue("primary_image", ci);
    });
  };

  const HandleModifierOpen = () => {
    setAddModifier(true);
  };
  const HandleModifierClose = () => {
    setAddModifier(false);
    getAllProductModifier();
  };
  const handleAddUpdateModifier = (item) => {
    // console.log("item", item);
    if (item === null || !Boolean(item.modifier.length)) {
      setSelectedModifierCategory(null);
      item !== null && toast.success("category have no Modifiers");
      return;
    }

    if (item !== null && item._id && item.product) {
      const updateModifierData = {
        modifier_mapping_id: item._id,
        product: item.product,
        modifier_category: item.modifier_category,
        name: item.modifier_category.name,
        modifier: item.modifier,
        default_selected_modifier: item.default_selected_modifier,
        is_add_quantity: item.is_add_quantity,
        is_modifier_settings: item.is_modifier_settings,
        min: item.min,
        max: item.max,
        sequence_no: item.sequence_no,
        type: item.type,
      };

      setSelectedModifierCategory(updateModifierData);
    } else {
      const addModifierData = {
        modifier_category: { modifier: item.modifier, _id: item._id },
        name: item.name,
      };
      setSelectedModifierCategory(addModifierData);
    }
  };

  // console.log("productModifiers",productModifiers)
  // console.log("selectedModifierCategory",selectedModifierCategory)

  const handleSubmitProductModifier = async (values, resetForm) => {
    // console.log("i am running");
    values.product = editProduct._id;
    values.modifier_category = selectedModifierCategory.modifier_category._id;
    // console.log("values", values);

    try {
      const resModifier = selectedModifierCategory.modifier_mapping_id
        ? await ProductmodifierApi.updateModifierMapping(
            values,
            selectedModifierCategory.modifier_mapping_id
          )
        : await ProductmodifierApi.createModifierMapping(values);
      // console.log("Selected Modifier", resModifier);
    } catch (error) {
      //console.log(error);
    } finally {
      getAllProducts();
      resetForm();
    }
    setSelectedModifierCategory(null);
  };
  const handleSubmitSubcategory = async (values, resetForm) => {
    try {
      const createRes = editData
        ? await SubCategoryApi.updateSubCategory(values, editData._id)
        : await SubCategoryApi.createSubCategory(values);
      // console.log(createRes.data?.data);
      toast.success(
        editData._id ? "Subcategory updated" : "Subcategory created"
      );
      if (!editData) {
        toast.info(
          "Subcategory is pending approval. It will be visible once approved by an admin."
        );
      }
      getAllSubcategory();
    } catch (err) {
      //console.log(err);
      toast.error(err?.message);
    } finally {
      resetForm();
      setEditData("");
      handleClose();
    }
  };
  // const handleSubcategoryDelete = async (item) => {
  //   console.log("item", item);
  //   handleLoading(true);
  //   try {
  //     const deleteRes = await SubCategoryApi.deleteSubCategory(item._id);
  //     getAllSubcategory();
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err?.message);
  //   } finally {
  //     handleLoading(false);
  //   }
  // };

  const handleSubcategoryEdit = (item) => {
    // console.log("item", item);
    setEditData(item);
    handleOpen();
  };
  const handleSubmit = async (values, { resetForm }) => {
    handleLoading(true);
    // console.log("Item List values", values);
    if (step === 2) {
      // handleSubmitProductModifier(values, resetForm);
    } else {
      handleSubmitSubcategory(values, resetForm);
    }
    handleLoading(false);
  };

  const handleProductPagination = (data) => {
    //console.log("data", data);
    if (data.clearPagination) {
      setProductPagination(data.clearPagination);
      return;
    }

    setProductPagination((prev) => {
      let copyPagination = { ...prev.filter };

      if (data.filter) {
        Object.keys(data?.filter).forEach((key, i) => {
          copyPagination[key] = data?.filter[key];
        });
      }

      return {
        page: data.page ? data.page : 1,
        limit: data.limit ? data.limit : 5,
        sortOrder: data.sortOrder ? data.sortOrder : "desc",
        filter: copyPagination,
      };
    });
  };

  // @Get All Data API @Start
  const getAllSubcategory = async () => {
    handleLoading(true);
    try {
      const res = await SubCategoryApi.getSubCategory();
      const subcategoriesData =
        res.data?.data.length &&
        res.data?.data.map((o, i) => {
          o.value = o._id;
          o.label = o?.category ? `${o.category.name} > ${o.name}` : o.name;
          return o;
        });
      // //console.log("subcategoriesData", subcategoriesData);

      setSubCategory(subcategoriesData);
    } catch (err) {
      //console.log(err);
      toast.error(err?.message);
    } finally {
      handleLoading(false);
    }
  };

  //console.log("subcategory", subCategory);
  const getAllProducts = async () => {
    //console.log("search", searchQuery);

    handleLoading(true);
    try {
      const res = await productApi.getAllProducts(
        productPagination,
        searchQuery
      );
      setAllProducts(res.data?.data);
    } catch (err) {
      //console.log(err);
      toast.error(err?.message);
    } finally {
      handleLoading(false);
    }
  };
  const getProductById = async () => {
    handleLoading(true);
    if (editProduct._id) {
      try {
        const resProduct = await productApi.getProductById(editProduct._id);
        // //console.log("resProduct", resProduct);
        setEditProduct(resProduct.data.data);
      } catch (error) {
        //console.log(error);
        toast.error(error?.message);
      }
    }
    handleLoading(false);
  };
  const getAllProductImages = async () => {
    const productId=editProduct?.product?._id?editProduct?.product?._id:editProduct?._id
    handleLoading(true);
    if (productId) {
      try {
        const resUpload = await productImageApi.getAllProductImages(
          productId
        );
        // //console.log("resUpload", resUpload);
        setImagesList(resUpload.data.data);
      } catch (error) {
        //console.log(error);
        toast.error(error?.message);
      }
    }
    handleLoading(false);
  };
  const getModifierCategory = async () => {
    handleLoading(true);
    try {
      const res = await modifierCategory.getModifierCategory();
      // //console.log("res::Modifier categories", res);
      setModifierCategories(res.data.data);
    } catch (error) {
      //console.log(error);
      toast.error(error?.message);
    }

    handleLoading(false);
  };
  const getAllProductModifier = async () => {
    handleLoading(true);
    try {
      const res = await ProductmodifierApi.getAllProductModifier(
        editProduct._id
      );
      // //console.log("res::All Product Modifiers", res);
      setProductModifiers(res.data.data);
    } catch (error) {
      //console.log(error);
      toast.error(error?.message);
    }

    handleLoading(false);
  };

  const getAllProductVariants = async () => {
    handleLoading(true);
    try {
      const productId=editProduct?.product?._id?editProduct?.product?._id:editProduct?._id
      const res = await productVariantApi.getAllProductVariants(productId);
      //console.log("res::All Product Variants", res);
      setAllVariantData(res.data.data);
    } catch (error) {
      //console.log(error);
      toast.error(error?.message);
    }

    handleLoading(false);
  };

  const getAllTax = async () => {
    handleLoading(true);
    try {
      const res = await masterApi.getAllTax();
      // //console.log("setAllTax", res?.data?.data.allTaxMaster);
      let allTaxData = res?.data?.data?.allTaxMaster
        ? res?.data?.data?.allTaxMaster
        : [];
      allTaxData = allTaxData.length
        ? allTaxData.map((t, i) => {
            t.label = `${t.name}@${t.rate.$numberDecimal}%`;
            t.value = t._id;
            return t;
          })
        : [{}];

      setAllTax(allTaxData);
    } catch (error) {
      //console.log(error);
      // toast.error(error?.message);
    }

    handleLoading(false);
  };

  // @Get All Data API @end


  const getDeliveryTypeById=async(id)=>{
    handleLoading(true);
    try {
      const res = await masterApi.getDeliveryFeeById(id);
      //console.log('res::getDeliveryTypeById',res?.data?.data);
      
      const deliveryFee={
        additionalFee:res?.data?.data?.additionalFee,
        closingFeeRate:res?.data?.data?.closingFeeRate,
        referralFeeRate:res?.data?.data?.referralFeeRate,
        shippingFeeRate:res?.data?.data?.shippingFeeRate,
        shippingHandlingFeeFixedRate:res?.data?.data?.shippingHandlingFeeFixedRate,
      }
      
      
      
      setDeliveryType(deliveryFee);
      // return deliveryFee
    } catch (error) {
      //console.log(error);
      // toast.error(error?.message);
    }
    
    handleLoading(false);
  }
  

  const handleSearch = useCallback((s) => {
    // console.log('Search at OrderCom', s);
    setSearchQuery(s);
  }, []);

  const debouncedSearch = useCallback(
    (s) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        handleSearch(s.target.value);
      }, 1500);
    },
    [handleSearch]
  );

  // const [filteredData,setFilteredData]= useState([])
  //   useMemo(()=>{
  //    const data=allProducts?.products? allProducts?.products.filter((item) => {
  //       return Object.values(item).some(
  //         (objValue) =>
  //           objValue !== null &&
  //           objValue
  //             .toString()
  //             .toLowerCase()
  //             .includes(searchQuery.trim().toLowerCase())
  //       );
  //     }):[];
  //     setFilteredData(data)
  //   },[allProducts])

  const FilterSubcategoryProdct = (item) => {
    const productsCopy = [...allProducts.products];
    if (item) {
      //   console.log("item",item);
      // console.log("allProducts",allProducts);
      const data = {
        filter: {
          "sub_category._id": item._id,
        },
      };

      console.log("filter::data", data);

      handleProductPagination(data);
      // const products= productsCopy.filter((p)=>p.sub_category._id===item._id)
      // console.log("products",products);
      // setFilteredData(products)
    } else {
      const clearPagination = {
        page: 1,
        limit: 8,
        sortOrder: "desc",
        filter: {},
      };

      handleProductPagination({ clearPagination });
      // setFilteredData(productsCopy)
    }
  };

  const stepIndex = useMemo(async () => {
    // console.log("editProduct", editProduct);
    getProductById();
    getAllProducts();
    if (step === 1) {
      getAllProductImages();
    }
    if (step === 2) {
      // getModifierCategory();
      // getAllProductModifier();
      getAllProductVariants();
    }
  }, [step]);

  const closeVariantModal = async () => {
    setIsVariant(false);
    setEditVariantData(null);
    setColorPicker(false);
  };
  const openAddOrEditVariant = () => {
    setIsVariant(true);
  };
  const handleSubmitVariant = async (data) => {
    // console.log('variant data',data);
    data.product = editProduct?.product?._id? editProduct?.product?._id:editProduct._id;
    const formData = new FormData();
    Object.keys(data).forEach((item, i) => {
      if (item === "secondary_image") {
        for (let i = 0; i < data[item].length; i++) {
          formData.append(item, data[item][i]);
        }
      } else if (item === "product_details") {
        data[item].forEach((pd, index) => {
          Object.keys(pd).forEach((d) => {
            // console.log("item[index][pd]",item);

            formData.append(`${item}[${index}][${d}]`, pd[d]);
          });
        });
      } else {
        formData.append(item, data[item]);
      }
    });
    handleLoading(true);
    try {
      const res = editVariantData?._id
        ? await productVariantApi.updateProductVariant(
            formData,
            editVariantData._id
          )
        : await productVariantApi.createProductVariant(formData);
      // console.log("create variant::res",res);
      getAllProductVariants();
      toast.success(`Variant ${editVariantData?._id ? "Updated" : "Created"}`);
      closeVariantModal();
    } catch (error) {
      //console.log("error", error);
      // closeVariantModal()
    }
    handleLoading(false);
  };
  
  useEffect(() => {
    getAllSubcategory();
    getAllTax();
    // console.log("all tax",allTax);
  }, [editProduct]);

  

  useEffect(() => {
    getAllProducts();
    //console.log(":::::getallProduct:::::", productPagination);
  }, [productPagination, searchQuery]);
  
 
  useEffect(() => {
    TaxCalculation()
  }, [editProduct,deliveryType,Taxes]);

  //console.log('ProductDetailsWithTaxData',productDetailsWithTax);

  return (
    <>
      <div className="flex justify-between">
        <div className="lg:w-9/12 p-10">
          <div className="flex justify-between items-center flex-wrap mb-2">
            <BreadCrumb title="Item" />
            <Button
              text="Add New"
              type="button"
              className="float-end"
              onClick={() => setAddItemVisible(true)}
            />
          </div>
          <div className="mb-5 border-b pb-2 flex items-center justify-between">
            <div className="border rounded-full px-3 py-1.5 text-xs bg-transparent w-fit flex justify-between items-center">
              <input
                type="search"
                placeholder="search..."
                // value={searchQuery}
                onChange={debouncedSearch}
                className="bg-transparent outline-none"
              />
              <FaSearch />
            </div>
            <SecondaryButton
              onClick={() => FilterSubcategoryProdct()}
              text={"Clear Filter"}
              type={"submit"}
              padding={"py-2 px-3"}
            />
          </div>
          <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
            {allProducts?.products &&
              allProducts?.products.map((item) => (
                <DeltaCard
                  isShowDescriptionid={isShowDescriptionid}
                  handleProductClick={handleProductClick}
                  item={item}
                  key={item.id}
                  openProductDeletModal={openDeleteModal}
                />
              ))}
          </div>
          <div className="flex justify-center items-center">
            <PaginatorCom
              paginatorData={{
                page: allProducts?.page ? allProducts?.page : 1,
                rows: allProducts?.limit ? allProducts?.limit : 5,
                totalPages: allProducts?.totalPages
                  ? allProducts?.totalPages
                  : 1,
                totalCount: allProducts?.totalCount
                  ? allProducts?.totalCount
                  : 1,
              }}
              onPaginatorClick={(e) => {
                if (e) {
                  const data = {
                    limit: e.rows,
                    page: +e.page + 1,
                  };
                  handleProductPagination(data);
                }
              }}
            />
          </div>
        </div>
        <SidebarRight>
          <div className=" pb-2  flex items-center justify-between text-lg font-semibold border-b">
            <h1>Sub Category</h1>
            <div className="mt-5">
              <Button
                cssClass="px-3 py-2"
                type="button"
                text="Add New"
                onClick={handleOpen}
              />
            </div>
          </div>
          <div className="w-full flex flex-col">
            {subCategory?.length > 0
              ? subCategory.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between py-3 border-b"
                  >
                    <div
                      onClick={() => FilterSubcategoryProdct(item)}
                      className="flex gap-2 cursor-pointer"
                    >
                      <TbPoint
                        style={{ color: item.is_visible ? "green" : "red" }}
                        className="text-xl"
                      />
                      {item.name}
                    </div>
                    {/* <DropdownMenubar
                      menuItem={[
                        {
                          label: "Menu",
                          items: [
                            {
                              label: "Edit",
                              icon: <TiEdit />,
                              command: () => handleSubcategoryEdit(item),
                            },
                            {
                              label: "Delete",
                              icon: <MdOutlineDelete />,
                              command: () =>
                                openDeleteModal("subcategory", item),
                            },
                          ],
                        },
                      ]}
                      // handleEdit={() => handleSubcategoryEdit(item)}
                      // handleDelete={() => openDeleteModal("subcategory", item)}
                    /> */}
                    {/* <SecondaryButton text='Edit'  onClick={() => handleSubcategoryEdit(item)}/> */}
                  </div>
                ))
              : ""}
            {/* <div className="mt-5">
              <Button type="button" text="Add New" onClick={handleOpen} />
            </div> */}
          </div>
        </SidebarRight>
      </div>

      {addItemVisible && (
        <Formik initialValues={initialProductValues} onSubmit={handleSubmit}>
          {({ handleChange, setFieldValue, values }) => (
            <SidebarField
              button1={
                step === 0 ? (
                  ""
                ) : (
                  <Button text="Back" type="button" onClick={handleBack} />
                )
              }
              button0={
                step === 2 && (
                  <Button
                    text="Add Variant"
                    type="button"
                    onClick={openAddOrEditVariant}
                  />
                )
                // step === 2 && (
                //   <Button
                //     text="Add Modifier"
                //     type="button"
                //     onClick={HandleModifierOpen}
                //   />
                // )
              }
              button2={
                <Button
                  text={step === 2 ? "Submit" : "Next "}
                  type="submit"
                  onClick={() => handleNext(values)}
                />
              }
              handleClose={() => handleCloseItem()}
              title={editProduct._id ? "Edit Item " : "Add New Item"}
            >
              <div className="flex items-center mx-10">
                {["iteminfo", "images", "Variants"].map((section, index) => (
                  <React.Fragment key={index}>
                    <div
                      className={`flex flex-col gap-2 items-center w-5 justify-center cursor-pointer`}
                      onClick={() => handleProductNav(index)}
                      // aria-disabled={
                      //   toggle !== "iteminfo" && section !== toggle
                      // }
                      // disabled={toggle !== "iteminfo" && section !== toggle}
                    >
                      <span
                        className={`p-2 border rounded-full ${
                          index === step
                            ? "border-buttonColor"
                            : "border-slate-300"
                        }`}
                      >
                        {section === "iteminfo" && (
                          <FiInfo
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                        {section === "images" && (
                          <FaCamera
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                        {section === "Variants" && (
                          <FaRegCircleCheck
                            className={`${
                              index === step ? "" : "text-slate-300"
                            } text-lg`}
                          />
                        )}
                      </span>
                      <span className="font-semibold">
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </span>
                    </div>
                    {index < 2 && <div className="border-t w-full"></div>}
                  </React.Fragment>
                ))}
              </div>
              {/* <div className="flex justify-center">
              {step===1 && imagesList.length? (<MdDeleteOutline className="text-3xl text-red-700"/>):""}
              </div> */}
              <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                {/* {JSON.stringify(editProduct)} */}
                {step === 0 && (
                  <>
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="primary_image"
                        className="w-52 h-52 pb-3  shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                      >
                        <IoAddCircleOutline
                          className="text-5xl text-slate-500 rounded-full"
                          id="primary__image"
                        />
                        <input
                          type="file"
                          id="primary_image"
                          accept="image/*"
                          name="primary_image"
                          onChange={(e) => handleFileUpload(e, setFieldValue)}
                          style={{ display: "none" }}
                          required
                        />
                        {values.primary_image ? "" : <span>Add Images</span>}
                        {editProduct._id &&
                        typeof values.primary_image !== "object" ? (
                          <LazyLoadImage
                            src={values.primary_image}
                            alt="img"
                            loading="lazy"
                            effect="blur w-full h-full"
                            style={{
                              width: "100%!important",
                              height: "100% !important",
                              objectFit: "contain",
                            }}
                            className="rounded-xl cursor-pointer"
                          />
                        ) : (
                          <ImagePreview
                            file={values.primary_image}
                            id="primary_image"
                          />
                        )}
                      </label>
                    </div>
                    <FormField
                      label="Sub Category"
                      tooltip="Select type of Sub Category"
                    >
                      {/* {JSON.stringify(values?.sub_category)} */}
                      <div id="sub_category">
                        <Dropdown
                          options={subCategory?.length > 0 ? subCategory : [{}]}
                          name="sub_category"
                          value={values?.sub_category}
                          onChange={handleChange}
                          handleOnchange={(v)=>{
                            //console.log('subcategory',v.category.delivery_calculation);
                            getDeliveryTypeById(v?.category?.delivery_calculation)

                          }}

                        />
                        <ErrorMessage
                          name="sub_category"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>
                    <FormField label="Item Name" tooltip="Enter product name">
                      <div className="flex items-center" id="item_name">
                        <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                          EN
                        </span>
                        <TextInput
                          name="name"
                          type="text"
                          placeholder="Item Name"
                          value={values.name}
                          onChange={handleChange}
                          required={true}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </FormField>
                    <FormField label="Brand Name" tooltip="Enter brand name">
                      <div className="flex items-center">
                        <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                          EN
                        </span>
                        <TextInput
                          name="brand"
                          type="text"
                          placeholder="Item brand"
                          value={values.brand}
                          onChange={handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="brand"
                        component="div"
                        className="error"
                      />
                    </FormField>
                    {/* <div className="flex justify-between gap-2"> */}
                    <FormField label="Model" tooltip="Enter model name">
                      <div className="flex items-center">
                        <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                          EN
                        </span>
                        <TextInput
                          name="model"
                          type="text"
                          placeholder="Item model"
                          value={values.model}
                          onChange={handleChange}
                        />
                      </div>
                      <ErrorMessage
                        name="model"
                        component="div"
                        className="error"
                      />
                    </FormField>
                    {/* <FormField
                        label="Serial number"
                        tooltip="Enter serial number"
                      >
                        <div className="flex items-center">
                          <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                            EN
                          </span>
                          <TextInput
                            name="serial_number"
                            type="text"
                            placeholder="Item serial number"
                            value={values.serial_number}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="serial_number"
                          component="div"
                          className="error"
                        />
                      </FormField> */}
                    {/* </div> */}

                    {/* <div className="flex justify-between gap-2">
                      <FormField label="MRP Price" tooltip="Enter MRP price">
                        <div id="mrp_price" className="flex items-center">
                          <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                            <pre> ₹</pre>
                          </span>
                          <TextInput
                            
                            name="mrp_price"
                            type="number"
                            placeholder="0"
                            value={values.mrp_price}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="mrp_price"
                          component="div"
                          className="error"
                        />
                      </FormField>
                      <FormField
                        label="Sales price"
                        tooltip="Enter sales price"
                      >
                        <div className="flex items-center" id="sales_price">
                          <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                            <pre> ₹</pre>
                          </span>
                          <TextInput
                            id="sales_price"
                            name="sales_price"
                            type="number"
                            placeholder="0"
                            value={values.sales_price}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <ErrorMessage
                          name="sales_price"
                          component="div"
                          className="error"
                        />
                      </FormField>
                      
                    </div>
                    <div className="flex justify-between gap-2">
                      <FormField 
                      label="Size" 
                        className="grow" 
                      tooltip="Enter only single size">
                        <div id="product_size" className="flex items-center">
                          
                          <TextInput
                            name="product_size"
                            type="text"
                            placeholder="Product size"
                            value={values.product_size}
                            // onChange={(e)=>setFieldValue('product_size',e.target.value.split(","))}
                            onChange={(e)=>setFieldValue('product_size',e.target.value)}
                            required={true}
                          />
                        </div>
                        <ErrorMessage
                          name="product_size"
                          component="div"
                          className="error"
                        />
                      </FormField>
                      <FormField label="Stock quantity" tooltip="Enter Stock quantity">
                        <div id="stock">
                          <TextInput
                            name="stock"
                            type="number"
                            value={values.stock}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                        name="stock"
                        component="div"
                        className="error"
                      />
                      </FormField>
                    </div> */}

                    {/* <FormField
                        label="Product Color"
                        tooltip="Enter Product Color"
                        className="grow"

                      >
                        <div className="flex items-center  relative" id="product_color">
                         
                        <label style={{background:values.product_color}} htmlFor="product_color" className={`overflow-hidden rounded-full border-2 border-gray-950  w-[4rem] h-[4rem] inline-block`} >
                        <input
                            id="product_color"
                            name="product_color"
                            type="color"
                            placeholder="color"
                            value={values.product_color}
                            onChange={handleChange}
                            required={true}
                            style={ {
                              width: '6rem',
                              height: '6rem',
                              marginLeft: '-12px',
                              marginTop: '-13px',
                              border: 'none',
                              outline: 'none',
                              borderRadius: '100%',
                              background: 'transparent'
                            }}
                          />                          
                        </label>
                            
                          
                         
                        </div>

                        <ErrorMessage
                          name="product_color"
                          component="div"
                          className="error"
                        />
                      </FormField> */}
                    <div id="is_color">
                      <ToggleField
                        text="Color?"
                        name="is_color"
                        value={is_color}
                        toggaleHandleChange={() => {
                          setColorPicker(!is_color);
                          setFieldValue(
                            "product_color",
                            is_color ? "#000000" : null
                          );
                        }}
                      />
                      <ErrorMessage
                        name="is_color"
                        component="div"
                        className="error"
                      />
                    </div>
                    {is_color ? (
                      <FormField
                        label="Product color"
                        tooltip="Pick Product Color"
                      >
                        <div
                          className="flex items-center  relative"
                          id="product_color"
                        >
                          <label
                            style={{ background: values.product_color }}
                            htmlFor="product_color"
                            className={`overflow-hidden  border-2 border-gray-950  w-full h-[2rem] inline-block`}
                          >
                            <input
                              id="product_color"
                              name="product_color"
                              type="color"
                              placeholder="color"
                              value={values.product_color}
                              onChange={handleChange}
                              required={false}
                              style={{
                                width: "100%",
                                height: "6rem",
                                marginTop: "-2rem",
                                border: "none",
                                outline: "none",
                                borderRadius: "100%",
                                background: "transparent",
                              }}
                            />
                          </label>
                        </div>
                      </FormField>
                    ) : (
                      ""
                    )}
                    <FormField
                      label="TAX"
                      className="w-[100%]"
                      tooltip="Select type of Tex"
                    >      
                    {/* {JSON.stringify(values.tax)}             */}
                      <Dropdown
                        options={taxOptions? taxOptions : [{}]}
                        name="tax"
                        value={values.tax}
                        onChange={handleChange}
                        handleOnchange={(v)=>{
                            //console.log(v);
                            const d= v.taxes.sort((a, b) => a.taxType.localeCompare(b.taxType))
                            //console.log(d);                            
                            setTaxes(d)                            
                        }}  
                      />                      
                      <ErrorMessage
                        name="tax"
                        component="div"
                        className="error"
                      />
                    </FormField>
                    <div className="flex items-center justify-between mb-2">
                      {/* <span>
                      {JSON.stringify(values.product_details)}
                    </span> */}
                      <span className="flex">
                        <Lable lable={"Product Details"} />
                        <Tooltip text={"Add more details of Product"} />
                      </span>
                      <div className="flex gap-1">
                        <IoMdAddCircleOutline
                          onClick={() => {
                            if (
                              values.product_details[
                                values.product_details.length - 1
                              ]["product_size"] !== null
                            ) {
                              const productDetails = values.product_details;
                              productDetails.push({
                                product_size: null,
                                mrp_price: null,
                                sales_price: null,
                                stock: null,
                                product_width: null,
                                product_height: null,
                                product_depth: 0,
                                product_weight: null,
                              });
                              setFieldValue("product_details", productDetails);
                            } else {
                              toast.warn(
                                "Provide product deatil in previous field!"
                              );
                            }
                          }}
                          className="text-[1.5rem] cursor-pointer"
                        />
                        <MdDeleteOutline
                          onClick={() => {
                            if (values.product_details.length - 1 > 0) {
                              const variantDetails = values.product_details;
                              variantDetails.pop();
                              setFieldValue("product_details", variantDetails);
                            } else {
                              toast.warn(
                                "Atleast one Product Size, MRP Price, Sales Price and Stock must be required!"
                              );
                            }
                          }}
                          className="text-[1.5rem] cursor-pointer"
                        />
                      </div>
                    </div>

                    <div id="product_details" className=" w-full">
                      {values.product_details?.map((item, i) => (
                        <>
                          {Object.keys(item).map((label, index) => (
                            <div
                              id={`${label}_${i}`}
                              key={`${i}${index}`}
                              className={`border ${
                                label === "product_size" ||
                                label === "mrp_price" ||
                                label === "sales_price" ||
                                label === "stock" ||
                                label === "product_width" ||
                                label === "product_width" ||
                                label === "product_height" ||
                                label === "product_weight" ||
                                label === "product_depth"
                                  ? "flex"
                                  : "hidden"
                              }  justify-between mb-1 items-center text-[12px]`}
                            >
                              <span className="w-5 border-r px-2 py-2  gap-2 capitalize  flex justify-between items-center">
                                <label className="text-[.85rem]">
                                  {label.replace("_", " ")}
                                </label>{" "}
                                <Tooltip text={product_details_info[label]} />
                              </span>
                              <TextInput
                                name={`${label}`}
                                type={
                                  label === "product_size" ? "text" : "number"
                                }
                                placeholder={`${label.replace("_", " ")}`}
                                onChange={(e) => {
                                  let variant = [...values.product_details];                                  
                                  console.log("variant", variant);
                                  variant[i][label] = e.target.value;                                  
                                  TaxCalculation(variant)
                                  // setProductDetailsWithTax(variant)
                                  setFieldValue("product_details", variant);
                                }}
                                value={
                                  label === "mrp_price" ||
                                  label === "sales_price"
                                    ? values.product_details[i][`${label}`]
                                        ?.$numberDecimal
                                      ? values.product_details[i][`${label}`]
                                          ?.$numberDecimal
                                      : values.product_details[i][`${label}`]
                                    : values.product_details[i][`${label}`]
                                }
                                required={
                                  label === "product_size" ||
                                  label === "product_depth"
                                    ? false
                                    : true
                                }
                                className={`capitalize`}
                              />
                             
                            </div>
                          ))}
                          <Divider className="border border-gray-300" />
                          <div>
                            <h1>Tax Details</h1>
                            {/* {JSON.stringify(productDetailsWithTax)} */}
                          <TaxData key={i} item={productDetailsWithTax[i]} />
                          </div>
                          
                            {/* <TaxView Taxes={Taxes} deliveryFee={deliveryType} item={item?.sales_price?.$numberDecimal?{sales_price:item?.sales_price?.$numberDecimal}:item}/> */}
                          <Divider className="border border-gray-300" />
                        </>
                      ))}
                      <ErrorMessage
                        name="product_details"
                        component="div"
                        className="blink-shadow error px-4 py-1"
                      />
                    </div>
                    {/* <FormField
                      label="VAT/TAX"
                      className="w-[100%]"
                      tooltip="Select type of Tex"
                    >
                      <Dropdown
                        options={allTax ? allTax : [{}]}
                        name="tax"
                        value={values.tax}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="tax"
                        component="div"
                        className="error"
                      />
                    </FormField> */}
                   
                    

                    {/* <FormField label="Description" tooltip="write a brief description of item">
                      <Textarea
                        name="description"
                        placeholder="Item Description"
                        value={values.description}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error"
                      />
                    </FormField> */}

                    <FormField
                      label="Description"
                      tooltip="write a brief description of item"
                    >
                      <div id="description">
                        <ReactQuill
                          theme="snow"
                          value={values.description}
                          onChange={(v) => setFieldValue("description", v)}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Additional Information"
                      tooltip="write a brief additional information of item"
                    >
                      <div id="additional_info">
                        <ReactQuill
                          theme="snow"
                          value={values.additional_info}
                          onChange={(v) => setFieldValue("additional_info", v)}
                        />
                        <ErrorMessage
                          name="additional_info"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Specifications"
                      tooltip="write a brief Specifications of item"
                    >
                      <div id="specification">
                        <ReactQuill
                          theme="snow"
                          value={values.specification}
                          onChange={(v) => setFieldValue("specification", v)}
                        />
                        <ErrorMessage
                          name="specification"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Cancellation policy"
                      tooltip="write a brief Cancellation policy of item"
                    >
                      <div id="cancellation_policy">
                        <ReactQuill
                          theme="snow"
                          value={values.cancellation_policy}
                          onChange={(v) =>
                            setFieldValue("cancellation_policy", v)
                          }
                        />
                        <ErrorMessage
                          name="cancellation_policy"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>
                    <FormField
                      label="Replacement policy"
                      tooltip="write a brief replacement policy of item"
                    >
                      <div id="replacement_policy">
                        <ReactQuill
                          theme="snow"
                          value={values.replacement_policy}
                          onChange={(v) =>
                            setFieldValue("replacement_policy", v)
                          }
                        />
                        <ErrorMessage
                          name="replacement_policy"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <FormField
                      label="Return policy"
                      tooltip="write a brief return policy of item"
                    >
                      <div id="return_policy">
                        <ReactQuill
                          theme="snow"
                          value={values.return_policy}
                          onChange={(v) => setFieldValue("return_policy", v)}
                        />
                        <ErrorMessage
                          name="return_policy"
                          component="div"
                          className="error"
                        />
                      </div>
                    </FormField>

                    <div className="flex justify-between flex-wrap-reverse gap-4">
                      <div id="is_visible">
                        <ToggleField
                          text="Is Item Visible to user?"
                          name="is_visible"
                          value={values.is_visible}
                        />
                        <ErrorMessage
                          name="is_visible"
                          component="div"
                          className="error"
                        />
                      </div>

                      <FormField
                        label="Sequence Number"
                        tooltip="Enter sequence Number"
                      >
                        <div id="sequence">
                          <TextInput
                            name="sequence"
                            type="number"
                            value={values.sequence}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                          name="sequence"
                          component="div"
                          className="error"
                        />
                      </FormField>
                    </div>
                    <div className="flex justify-between flex-wrap-reverse gap-4">
                      {/* <div>
                     <ToggleField
                          value={values.in_stock}
                          text="In Stock"
                          name="in_stock"
                        />
                        <ErrorMessage
                        name="in_stock"
                        component="div"
                        className="error"
                      />
                     </div> */}
                    </div>
                  </>
                )}
                {step === 1 && (
                  <div className="flex justify-center  gap-5 flex-wrap">
                    {imagesList.length > 0 &&
                      imagesList.map((imgUrl, index) => (
                        <div
                          key={index}
                          className="w-52 h-52 pb-3   shadow-2xl border  text-center rounded-xl relative"
                          onMouseEnter={() =>
                            (document.getElementById(
                              `deleteProductIcon-${index}`
                            ).style.display = "inline-block")
                          }
                          onMouseLeave={() =>
                            (document.getElementById(
                              `deleteProductIcon-${index}`
                            ).style.display = "none")
                          }
                        >
                          {/* <input
                       
                       type="checkbox"
                       className="m-2 absolute z-1 right-0"
                       value={index}
                       onChange={(e) => {
                        }}
                        /> */}
                          <i
                            id={`deleteProductIcon-${index}`}
                            style={{ display: "none" }}
                            className="absolute text-red-700 top-0 right-0  bg-black rounded-full p-1 "
                          >
                            <MdDeleteOutline
                              onClick={() =>
                                openDeleteModal("product_images", imgUrl)
                              }
                              className="cursor-pointer  text-3xl"
                            />
                          </i>
                          <label htmlFor={`${imgUrl._id}`}>
                            <input
                              type="file"
                              id={`${imgUrl._id}`}
                              accept="image/*"
                              name="images"
                              onChange={(e) =>
                                handleAddMultipleImages(e, imgUrl._id)
                              }
                              style={{ display: "none" }}
                            />
                            <LazyLoadImage
                              src={imgUrl.image}
                              alt={`Item ${index + 1}`}
                              // style={{width:"5rem !important",height:"5rem !important", objectFit:"contain"}}
                              className="rounded-xl cursor-pointer"
                              loading="lazy"
                              effect="blur"
                            />
                          </label>
                        </div>
                      ))}
                    <label
                      htmlFor="image-upload"
                      className="w-52 h-44 shadow-2xl text-center rounded-xl flex items-center justify-center gap-3 flex-col cursor-pointer"
                    >
                      <IoAddCircleOutline className="text-5xl text-slate-500" />

                      <input
                        type="file"
                        id="image-upload"
                        accept="image/*"
                        multiple
                        name="images"
                        onChange={handleAddMultipleImages}
                        style={{ display: "none" }}
                      />
                      <span>Add Images</span>
                    </label>
                  </div>
                )}
                <Variant
                  isOpen={isVariant}
                  editVariantData={editVariantData}
                  handleVariantClose={closeVariantModal}
                  handleSubmitVariant={handleSubmitVariant}
                />
                {step === 2 && allVariantData && allVariantData.length
                  ? allVariantData.map((p, i) => (
                      <div
                        key={p._id}
                        className="w-full h-fit p-3 shadow-2xl rounded-lg flex flex-col gap-4"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex gap-1.5 items-center ">
                            <FaRegCheckCircle className="text-lg text-hoverColor" />
                            <span className="text-[17px]">
                              {i + 1}. {p?.name}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <SecondaryButton
                              onClick={() => {
                                setIsVariant(true);
                                setEditVariantData(p);
                              }}
                              text="Edit"
                            />
                            <span
                              onClick={() => openDeleteModal("variant", p)}
                              className="border cursor-pointer border-errorColor hover:bg-red-700 hover:text-white rounded-full  font-bold px-3 text-[12px]"
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}

                {/* {step === 2 && productModifiers.length
                  ? productModifiers.map((p, i) => (
                      <div
                        key={p._id}
                        className="w-full h-fit p-3 shadow-2xl rounded-lg flex flex-col gap-4"
                      >
                        <div className="flex justify-between items-center">
                          <div className="flex gap-1.5 items-center ">
                            <FaRegCheckCircle className="text-lg text-hoverColor" />
                            <span className="text-[17px]">
                              {i + 1}. {p.modifier_category?.name}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            <SecondaryButton
                              onClick={() => {
                                handleAddUpdateModifier(p);
                              }}
                              text="Edit"
                            />
                            <span
                              onClick={() =>
                                openDeleteModal("modifier_category", p)
                              }
                              className="border cursor-pointer border-errorColor hover:bg-red-700 hover:text-white rounded-full  font-bold px-3 text-[12px]"
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                        <div className="text-sm">
                          {p.modifier_category.modifier.length
                            ? p.modifier_category.modifier.map(
                                (m, i) =>
                                  p.modifier.includes(m._id) && (
                                    <React.Fragment key={m._id}>
                                      {" "}
                                      {m.name} |{" "}
                                    </React.Fragment>
                                  )
                              )
                            : ""}
                        </div>
                       
                      </div>
                    ))
                  : ""} */}
              </Form>
            </SidebarField>
          )}
        </Formik>
      )}

      {addModifier && (
        <SidebarField
          button1={""}
          button2={
            <Button
              text="Back "
              type="submit"
              onClick={() => HandleModifierClose()}
            />
          }
          handleClose={() => HandleModifierClose()}
          title="Add New Item"
        >
          <ul>
            {modifierCategories.length
              ? modifierCategories.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className="p-3 border-t text-buttonColor cursor-pointer"
                      onClick={() => handleAddUpdateModifier(item)}
                    >
                      {item.name}
                    </li>
                  );
                })
              : ""}
          </ul>
        </SidebarField>
      )}

      {selectedModifierCategory != null && (
        <Formik
          initialValues={{
            type: selectedModifierCategory.type || "",
            sequence_no: selectedModifierCategory.sequence_no || 0,
            min: selectedModifierCategory.min || 0,
            max: selectedModifierCategory.max || 0,
            is_add_quantity: selectedModifierCategory.is_add_quantity || false,
            is_modifier_settings:
              selectedModifierCategory.is_modifier_settings || false,
            select_will_be_shown_to_users: true,
            default_selected_modifier:
              selectedModifierCategory.default_selected_modifier || null,
            modifier: selectedModifierCategory.modifier || [],
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, handleChange, values }) => (
            <Form>
              <SidebarField
                button1={
                  <Button
                    text="Back "
                    // type="submit"
                    onClick={() => handleAddUpdateModifier(null)}
                  />
                }
                button2={
                  <Button
                    text={
                      selectedModifierCategory.modifier_mapping_id
                        ? "Update "
                        : "Add"
                    }
                    type="submit"
                  />
                }
                handleClose={() => handleAddUpdateModifier(null)}
                title="Add New Item"
              >
                <div className="p-2 flex flex-col gap-4">
                  <h1 className="border-b pb-2 text-lg ">
                    {selectedModifierCategory.name}
                  </h1>
                  <div className="flex justify-between w-full gap-3">
                    <div className="w-full">
                      <div className="flex">
                        <Lable lable={"Modifier Group Type"} />
                        <Tooltip text="select type of modifier group" />
                      </div>
                      <Dropdown
                        name="type"
                        value={values.type}
                        options={[
                          { id: "1", label: "Select", value: "Select" },
                          {
                            id: "2",
                            label: "Select Range",
                            value: "Select Range",
                          },
                        ]}
                      />
                    </div>
                    <div className="w-full">
                      <div className="flex">
                        <Lable lable={"Modifier Sequence Number"} />
                        <Tooltip text="Enter a Sequence Number" />
                      </div>
                      <TextInput
                        type="number"
                        value={values.sequence_no}
                        name="sequence_no"
                        placeholder={"0"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between w-full gap-3">
                      <div className="w-full">
                        <div className="flex">
                          <Lable lable={"Min Modifier"} />
                          <Tooltip text="add Minimum Modifier number" />
                        </div>
                        <TextInput
                          type="number"
                          value={values.min}
                          name="min"
                          placeholder={"0"}
                          onChange={handleChange}
                        />
                      </div>
                      {values.type === "Select Range" ? (
                        <div className="w-full">
                          <div className="flex">
                            <Lable lable={"Max Modifier"} />
                            <Tooltip text="add Maximum Modifier number" />
                          </div>
                          <TextInput
                            type="number"
                            value={values.max}
                            name="max"
                            placeholder={"0"}
                            onChange={handleChange}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    <ToggleField
                      text="Can User Add Modifier Quantity ?"
                      name={"is_add_quantity"}
                      value={values.is_add_quantity}
                    />
                  </div>
                  <div className="pb-2 border-b">
                    <ToggleField
                      text="Associate Modifier Settings"
                      name={"is_modifier_settings"}
                      value={values.is_modifier_settings}
                    />
                  </div>
                  <div className="pb-2 border-b flex justify-between">
                    <div className="flex">
                      <span>Modifier Selection</span>
                      <Tooltip text="You can Select your Modifier" />
                    </div>
                    <span className="text-red-600 ">Optional</span>
                  </div>
                  <div className="flex justify-start gap-4 pb-2 border-b">
                    <div>
                      <ToggleField
                        text="Selected will be shown to users"
                        name={"select_will_be_shown_to_users"}
                      />
                    </div>
                    <div>
                      <ToggleField
                        text="Default Selected"
                        name={"default_select"}
                      />
                    </div>
                  </div>

                  {selectedModifierCategory.modifier_category.modifier
                    ? selectedModifierCategory.modifier_category.modifier.map(
                        (item, i) => {
                          return (
                            <div
                              key={item._id}
                              className="flex justify-between items-center"
                            >
                              <div className="flex gap-3 items-center">
                                {" "}
                                <input
                                  type="checkbox"
                                  value={item._id}
                                  checked={values.modifier.includes(item._id)}
                                  name={`modifier${[i]}`}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    const selectedModifiers = [
                                      ...values.modifier,
                                    ];

                                    if (isChecked) {
                                      selectedModifiers.push(item._id); // Add ID if checked
                                    } else {
                                      const index = selectedModifiers.indexOf(
                                        item._id
                                      );
                                      if (index > -1) {
                                        selectedModifiers.splice(index, 1); // Remove ID if unchecked
                                      }
                                    }

                                    setFieldValue(
                                      "modifier",
                                      selectedModifiers
                                    );
                                  }}
                                />
                                <span>{item.name}</span>
                              </div>
                              <div className="flex gap-3 items-center">
                                <TextInput
                                  value={item.price.$numberDecimal}
                                  disabled={true}
                                  placeholder="0"
                                />
                                <span>
                                  <input
                                    type="radio"
                                    value={values.default_selected_modifier}
                                    name="default_selected_modifier"
                                    onChange={() =>
                                      setFieldValue(
                                        "default_selected_modifier",
                                        item._id
                                      )
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        }
                      )
                    : ""}
                </div>
              </SidebarField>
            </Form>
          )}
        </Formik>
      )}

      {editVisible && (
        <SubCategoryForm
          handleSubmit={handleSubmit}
          handleClose={() => handleClose()}
          initialValues={{
            name: editData.name || "",
            sequence: editData.sequence || "",
            category: editData.category || "",
            is_visible: editData.is_visible || false,
            categoryType: "Ecommerce",
          }}
        />
      )}
      <DeleteFieldModal
        isOpen={deleteModal}
        onClose={handleDeleteClose}
        onDelete={() => handleDelete()}
      />
    </>
  );
};

export const SubCategoryForm = ({
  initialValues,
  handleSubmit,
  handleClose,
}) => {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const { handleLoading } = useContext(LoaderContext);

  const fetchingCategoryItems = async () => {
    handleLoading(true);
    try {
      const res = await CategoryApi.getCategory();

      setCategoriesOptions(res.data?.data?.categories);
    } catch (err) {
      //console.log(err);
    } finally {
      handleLoading(false);
    }
  };

  const categoryOptions = categoriesOptions.map((cat) => {
    return {
      value: cat._id,
      label: cat.name,
    };
  });

  useEffect(() => {
    fetchingCategoryItems();
  }, []);
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleChange, values }) => (
        <Form>
          <SidebarField
            button1={
              <Button text="Cancel" type="button" onClick={handleClose} />
            }
            button2={<Button text="Submit" type="submit" />}
            handleClose={handleClose}
            title={values.name ? "Edit Sub Category" : "Add New Sub Category"}
          >
            <div className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
              <FormField label="Sub Category Name" tooltip="Enter subcategory">
                <TextInput
                  name="name"
                  type="text"
                  placeholder="Sub Category Name"
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                />
                <ErrorMessage name="name" component="div" className="error" />
              </FormField>

              <FormField label="Category" tooltip="Choose or create a category">
                <div className="flex items-center gap-2">
                  <Dropdown
                    options={categoryOptions}
                    name="category"
                    value={values.category}
                    placeholder="Choose your category"
                  />
                </div>
                <ErrorMessage
                  name="category"
                  component="div"
                  className="error"
                />
              </FormField>

              <FormField
                label="Sequence Number"
                tooltip="Enter sequence number to sort the subcategory"
              >
                <TextInput
                  name="sequence"
                  type="number"
                  placeholder="Sequence Number"
                  onChange={handleChange}
                  required={true}
                  value={values.sequence}
                />
                <ErrorMessage name="number" component="div" className="error" />
              </FormField>
              <ToggleField
                text="Is subcategory visible to user?"
                name="is_visible"
                onChange={handleChange}
                value={values.is_visible}
              />
            </div>
          </SidebarField>
        </Form>
      )}
    </Formik>
  );
};

export default ItemList;





const TaxData = ( {key,item} ) => {
  console.log('TaxView',item);
  
    
  if (item?.sales_price) {    
    return (
      <div key={`t-${key}`}>
        <table class="min-w-full bg-white border border-gray-200">
          <thead>
            <tr class="bg-gray-100 border-b">
              <th class="px-4 py-2 text-left text-gray-600 font-semibold">
                Tax Name
              </th>
              <th class="px-4 py-2 text-left text-gray-600 font-semibold">
                Tax
              </th>
              <th class="px-4 py-2 text-left text-gray-600 font-semibold text-right">
                Amount
              </th>              
            </tr>
          </thead>
          <tbody>
          <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-700">
                  Product Base Price
                  </td>                   
                    <td>
                     
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                    {formattedAmount(parseFloat(item?.sales_price?.$numberDecimal?item?.sales_price?.$numberDecimal:item?.sales_price).toFixed(2))}
                    </td>                    
                  </tr>  
            {
               item?.taxData?.standardTax?.map((t, i) =>
                  (
                    <tr class="border-b" key={i}>
                      <td class="px-4 py-2 text-gray-700">{t.name}</td>
                      <td class="px-4 py-2 text-gray-700">
                        {t?.rate.$numberDecimal || t?.rate}%
                      </td>
                      <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount( t?.taxAmount?.$numberDecimal||t?.taxAmount)}
                      </td>
                    </tr>
                  ))}
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                  Line Rebase
                  </td>                   
                    <td>
                     
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                    {formattedAmount(item?.taxData?.rebase?.$numberDecimal||item?.taxData?.rebase)}
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                   Referral Fee
                  </td>                   
                    <td className="px-4 py-2 text-gray-700">
                     {item?.taxData?.referralFee?.percentage?.$numberDecimal||item?.taxData?.referralFee?.percentage}%
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                    {formattedAmount(item?.taxData?.referralFee?.amount?.$numberDecimal||item?.taxData?.referralFee?.amount)}
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                   Closing Fee
                  </td>                   
                    <td className="px-4 py-2 text-gray-700">
                      {item?.taxData?.closingFeeRate?.$numberDecimal||item?.taxData?.closingFeeRate}
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount(item?.taxData?.closingFee?.$numberDecimal||item?.taxData?.closingFee)}
                     
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                   Additional Fee
                  </td>                   
                    <td className="px-4 py-2 text-gray-700">
                      {item?.taxData?.additionalFee?.$numberDecimal||item?.taxData?.additionalFee}
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount(item?.taxData?.additionalFee?.$numberDecimal||item?.taxData?.additionalFee)}
                     
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                   Taxable Amount
                  </td>                   
                    <td>
                      
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount(item?.taxData?.taxableAmount?.$numberDecimal||item?.taxData?.taxableAmount)}
                     
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                  VAT Included
                  </td>                   
                    <td className="px-4 py-2 text-gray-700">
                    {item?.taxData?.vat[0]?.rate?.$numberDecimal||item?.taxData?.vat[0]?.rate}%
                     
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount(item?.taxData?.vat[0]?.taxAmount?.$numberDecimal||item?.taxData?.vat[0]?.taxAmount)}
                     
                    </td>                    
                  </tr>              
                  <tr class="border-b" >
                  <td class="px-4 py-2 text-left text-gray-600">
                  Grand Total
                  </td>                   
                    <td className="px-4 py-2 text-gray-700">
                   
                     
                    </td>
                    <td class="px-4 py-2 text-gray-700 text-right">
                      {formattedAmount(item?.taxData?.grandTotal?.$numberDecimal||item?.taxData?.grandTotal)}
                     
                    </td>                    
                  </tr>              
          </tbody>
        </table>
      </div>
    );
  }
 };
 