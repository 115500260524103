// DeleteFieldModal.js
import React from "react";
import { BsExclamationTriangle } from "react-icons/bs";

const DeleteFieldModal = ({ isOpen, onClose, onDelete }) => {
  if (!isOpen) return null;

  return (
    <div className="w-full fixed inset-0 flex items-center justify-center bg-[#111827d9] bg-opacity-5 z-[9999] ">
      <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-sm z-40">
        <div className="text-center">
          <div className="text-red-500 flex items-center justify-center  ">
            <i className=" flex items-center justify-center bg-[#ffe4e6] rounded-full p-3">
              <BsExclamationTriangle className=" text-4xl   " />
            </i>
          </div>
          <h3 className="text-xl text-gray-900 mt-4 font-semibold ">
            Are you sure?
          </h3>
          <p className="text-lg text-gray-500 mt-2">
            This action cannot be undone. All values associated with this field
            will be lost.
          </p>
        </div>
        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={onDelete}
            className="bg-red-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-red-700"
          >
            Delete field
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded-lg hover:bg-gray-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteFieldModal;
