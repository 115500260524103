import React from "react";
import { createContext, useState } from "react";

export const LoaderContext = createContext()

export const LoaderProvider = ({ children }) => {
    // Initial States @start
    const [isLoading, setIsLoading] = useState(false)
    // Initial States @end


    // Action jaisa hai @start 

    // @Loader Action start
    const handleLoading = (data) => {
        setIsLoading(data)
    }

    // @Loader Action  end



    // Action jaisa hai @end
    return (
        <LoaderContext.Provider
            value={{
                // loader
                isLoading,
                handleLoading,
            }}
        >
            {children}
        </LoaderContext.Provider>
    )
}


export default LoaderContext