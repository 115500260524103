import { ErrorMessage, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Lable from "../../components/Lable";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Phone from "../../components/Phone";
import { forgatePasswordValidationSchema } from "../../validationSchema/ValidationSchema";
import AuthApi from "../../apis/auth.apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LoaderContext from "../../components/loader";

const ForgotPassword = () => {
  const [toggle, setToggle] = useState(false);
  const { theme } = useSelector((state) => state.theme);

  const { handleLoading } = useContext(LoaderContext);

  const navigate = useNavigate();
  const initialValues = {
    phone: "",
    email: "",
  };

  const handleSubmit = async (values) => {
    handleLoading(true);
    console.log("values", values);
    const payload = {
      emailOrPhone: toggle ? values.email : values.phone,
    };

    console.log("payload", payload);
    console.log("AuthApi.generateOtp", AuthApi?.generateOtp);
    try {
      const res = await AuthApi?.generateOtp(payload);
      console.log("res", res);
      toast.success("Otp sent successfully");
      navigate("/verify-otp", { state: { emailOrPhone: payload } });
      handleLoading(false);
    } catch (error) {
      console.log("error", error);
      handleLoading(false);
    }
  };

  return (
    <>
      <div className={`flex gap-2`}>
        <button
          onClick={() => setToggle(false)}
          className={`${
            !toggle
              ? "active"
              : `${
                  theme === "light"
                    ? "bg-secondaryColor text-textColorLight"
                    : "bg-darkPrimary text-darkSecondary"
                }`
          }`}
        >
          Phone
        </button>
        <span> | </span>
        <button
          onClick={() => setToggle(true)}
          className={`${
            toggle
              ? "active"
              : `${
                  theme === "light"
                    ? "bg-secondaryColor text-textColorLight"
                    : "bg-darkPrimary text-darkSecondary"
                }`
          }`}
        >
          Email
        </button>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={forgatePasswordValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values }) => (
          <Form className="mt-12">
            {!toggle ? (
              <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
                <Lable lable="Phone" />
                <div>
                  <Phone value={values.phone} name="phone" />
                  <ErrorMessage
                    name="phone"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            ) : (
              <div className="gap-3  grid grid-cols-1 sm:grid-cols-2 items-center mb-3">
                <Lable lable="Email" />
                <div>
                  <TextInput
                    name="email"
                    type="email"
                    placeholder="E-mail"
                    onChange={handleChange}
                    value={values.email ? values.email : ""}
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            )}

            <div className="mt-8 flex gap-4 justify-between flex-wrap">
              {/* <NavLink to={"forgot-password"} className="active">
              Forgot Password
            </NavLink> */}
              <Button text={"Send otp"} type={"submit"} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
