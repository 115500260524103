// import React, { useState } from "react";
// import { Calendar } from "primereact/calendar";
// import moment from "moment";

// export default function CalendarComp({sx,canlenderFilter, handleFilter,...restProps}) {
//   const [dates, setDates] = useState(null);
//   const handleDateRange = (e,name) => {
//     setDates(e.value);
//     const formattedDates = e.value.map(date => moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
//     formattedDates[1]!=="Invalid date"&& handleFilter(name, formattedDates);
//   };
//   return (
//     <div  style={sx?sx:{}} className="card flex justify-content-center">
//       <Calendar
//         placeholder="    Select Dates"
//         value={dates}
//         onChange={(e) => handleDateRange(e,canlenderFilter?canlenderFilter.inputName:"month_range")}
//         selectionMode="range"      
//         maxDate={canlenderFilter && canlenderFilter.maxDate?canlenderFilter.maxDate:null}
//         // readOnlyInput


  
//         {...restProps}
//         // hideOnRangeSelection
//         // touchUI 
//       />
//     </div>
//   );
// }


import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";

export default function CalendarComp({sx,canlenderFilter, handleFilter,...restProps}) {
  const [dates, setDates] = useState(null);
  const handleDateRange = (e,name) => {
    setDates(e.value);
    console.log("e.value",e.value);
    
    const formattedDates = e.value && e.value.map(date => moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    formattedDates && formattedDates[1]!=="Invalid date"&& handleFilter(name, formattedDates);
  };
  return (
    <div  style={sx?sx:{}} className="rounded-2xl border ">
      <Calendar
        placeholder="Select Dates"
        value={dates}
        onChange={(e) => handleDateRange(e,canlenderFilter?canlenderFilter.inputName:"month_range")}
        selectionMode="range"      
        maxDate={canlenderFilter && canlenderFilter.maxDate?canlenderFilter.maxDate:null}
        // readOnlyInput  
        {...restProps}
        // hideOnRangeSelection
        // touchUI 
      />
    </div>
  );
}
