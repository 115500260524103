import { toast } from "react-toastify";
import AuthApi from "../apis/auth.apis";

function jwtDecode(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const isValidToken = async (accessToken) => {
  if (!accessToken) {
    window.location.href = "/";
    alert("Session Expired");
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {
    console.log("token expired ", decoded.exp < currentTime);
  }

  return decoded.exp > currentTime;
};

export const tokenExpired = (exp) => {
  console.log("exp is : ", exp);
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Session Expired");

    localStorage.removeItem("accessToken");

    window.location.href = "/login";
  }, timeLeft);
};
