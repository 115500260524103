import React, { useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaRegStar,
  FaStar,
  FaTimesCircle,
  FaUndoAlt,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import PriceTooltip from "./ToolTip";
import ReactQuill from "react-quill";


const ProductInfo = ({
  productData,
  totalRating,
  variant,
  selectedVariant,
  setSelectedVariant,
  selectedSize,
  setSelectedSize,
  selectedColor,
  setSelectedColor,
  variantId,
}) => {
  const {
    brand,
    model,
    serial_number,
    return_policy,
    replacement_policy,
    cancellation_policy,
  } = productData;

  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [sizeId, setSizeId] = useState();
  const [isInCart, setIsInCart] = useState(false);
  const { product_details } = selectedVariant ? selectedVariant : productData;
  const [selectedSizeDetails, setSelectedSizeDetails] = useState(
    product_details && product_details[0]
  );

  // Update selected size details when product_details changes
  useEffect(() => {
    setSelectedSizeDetails(product_details[0]);
  }, [product_details]);

  const {
    stock,
    sales_price,
    mrp_price,
    taxData,
    product_height,
    product_weight,
    product_width,
    product_depth,
  } = selectedSizeDetails || {};

  // Set default size and sizeId
  useEffect(() => {
    if (product_details.length >= 0) {
      setSelectedSize(product_details?.[0]?.product_size);
      setSizeId(product_details?.[0]?._id);
    }
  }, [product_details]);

  // Set default color if none is selected
  useEffect(() => {
    if (!selectedVariant && productData?.product_color?.length > 0) {
      setSelectedColor(productData.product_color[0]);
    } else if (selectedVariant?.product_color?.length > 0) {
      setSelectedColor(selectedVariant.product_color[0]);
    }
  }, [productData, selectedVariant]);

  const handleSizeClick = (size, sizeDetails) => {
    setSelectedSizeDetails(sizeDetails);
    setSizeId(sizeDetails?._id);
    setSelectedSize(size);
  };

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const costPrice = mrp_price?.$numberDecimal;
  const salePrice = sales_price?.$numberDecimal;
  const grandTotal = parseFloat(taxData?.grandTotal?.$numberDecimal).toFixed(2);
  const discountAmount = costPrice - grandTotal;
  const percentageOff = (discountAmount / costPrice) * 100;

  return (
    <div>
      <h1 className="text-2xl font-semibold mb-2">
        {selectedVariant
          ? selectedVariant.name
          : productData?.name || "Product Name"}
      </h1>

      {/* Ratings Section */}
      <div className="flex justify-start items-center mb-4">
        {[...Array(5)].map((_, index) =>
          index < productData?.average_rating ? (
            <FaStar key={index} className="text-orange-500 text-xs" />
          ) : (
            <FaRegStar key={index} className="text-orange-500 text-xs" />
          )
        )}
        <span className="text-sm text-gray-500 ml-2">
          ({productData?.average_rating.toFixed(1)})
          <span className="ml-4">+{totalRating.toLocaleString("en-US")} ratings</span>
        </span>
      </div>

      {/* Product Info */}
      <p className="text-sm text-gray-600 mb-4">
        {productData?.sub_category?.name || "Sub Category"} | {brand} | Model:{" "}
        {model} | S.No: {serial_number}
      </p>

      {/* Price and Discount */}
      <div className="flex items-baseline mb-2">
        {costPrice && (
          <p className="text-lg font-semibold text-red-500 mr-3">
            -{percentageOff.toFixed(0)}%
          </p>
        )}
        <div className="relative">
          <span className="text-sm absolute top-0 left-0">₹</span>
          <p className="text-3xl font-bold text-gray-900 ml-3">
            {new Intl.NumberFormat("en-IN", {
              maximumFractionDigits: 2,
            }).format(grandTotal)}
          </p>
        </div>
        <PriceTooltip
          mrp={costPrice}
          discount={discountAmount}
          specialPrice={grandTotal}
        />
      </div>

      {/* Stock and Sizes */}
      {(selectedVariant?.stock ?? stock) < 10 &&
        (selectedVariant?.stock ?? stock) !== 0 && (
          <p className="text-[#ff6161] text-sm">
            Hurry, Only {selectedVariant?.stock ?? stock} left!
          </p>
        )}
      <p className={`text-sm font-semibold mb-3 ${stock > 0 ? "text-green-500" : "text-red-500"}`}>
        {stock > 0 ? "In Stock" : "Out of Stock"}
      </p>

      {/* Color Section */}
      <div className="mb-6">
        <div className="flex items-center justify-start gap-5">
          <span className="text-sm font-semibold">Color</span>
          <div className="flex space-x-2 mt-2">
            {selectedVariant?.product_color?.length > 0 ? (
              selectedVariant.product_color.map((item, index) => (
                <ColorItem
                  key={`variant-${index}`}
                  color={item}
                  selectedColor={selectedColor}
                  handleClick={handleColorClick}
                />
              ))
            ) : productData?.product_color?.length > 0 ? (
              productData.product_color.map((color, index) => (
                <ColorItem
                  key={`color-${index}`}
                  color={color}
                  selectedColor={selectedColor}
                  handleClick={handleColorClick}
                />
              ))
            ) : (
              <p className="text-sm text-gray-500">No colors available</p>
            )}
          </div>
        </div>
      </div>

      {/* Size Section */}
      {product_details && (
        <div className="mb-3 flex items-center justify-start gap-7">
          <span className="text-sm font-semibold">Size</span>
          <div className="flex space-x-2 mt-2">
            {product_details.length > 0 ? (
              product_details.map((size, index) => (
                <SizeItem
                  key={index}
                  size={size?.product_size}
                  selectedSize={selectedSize}
                  handleClick={() => handleSizeClick(size?.product_size, size)}
                />
              ))
            ) : (
              <p className="text-sm text-gray-500">No sizes available</p>
            )}
          </div>
        </div>
      )}

      {/* Policies Section */}
      <div className="mb-0">
        <h2 className="text-xl font-bold text-gray-900 mb-2">Policies</h2>
        <PolicyItem
          icon={<FaUndoAlt className="text-yellow-500 mr-2" />}
          label="Return Policy:"
          policy={return_policy}
        />
        <PolicyItem
          icon={<FaCheckCircle className="text-green-500 mr-2" />}
          label="Replacement Policy:"
          policy={replacement_policy}
        />
        <PolicyItem
          icon={<FaTimesCircle className="text-red-500 mr-2" />}
          label="Cancellation Policy:"
          policy={cancellation_policy}
        />
      </div>

      {/* Product Dimensions Section */}
      <div className="mt-6">
        <h2 className="text-xl font-bold text-gray-900 mb-2">Product Dimensions</h2>
        <ProductDimensions
          height={product_height}
          width={product_width}
          depth={product_depth}
          weight={product_weight}
        />
      </div>
    </div>
  );
};

export default ProductInfo;

// Child Component for Color Selection
const ColorItem = ({ color, selectedColor, handleClick }) => (
  <div
    onClick={() => handleClick(color)}
    className={`rounded-full cursor-pointer border-2 transition-transform duration-300 ${
      selectedColor === color
        ? "border-blue-600 transform scale-105"
        : "border-gray-300"
    }`}
    style={{ backgroundColor: color, width:"2.5rem" ,height:"2.5rem" }}
  />
);

// Child Component for Size Selection
const SizeItem = ({ size, selectedSize, handleClick }) => (
  <button
    onClick={() => handleClick(size)}
    className={`px-4 py-2 border rounded-sm transition-colors duration-200 ${
      selectedSize === size
        ? "bg-blue-600 text-white"
        : "bg-gray-200 text-gray-700"
    }`}
  >
    {size}
  </button>
);

// Policy Display Component
const PolicyItem = ({ icon, label, policy }) => (
  <div className="text-sm text-gray-700 mb-3 flex  items-start">
   
    <span className="font-semibold flex justify-center items-center mt-2"> {icon} {label}</span>
    <span 
    className="font-semibold flex justify-center items-center mt-2" 
    dangerouslySetInnerHTML={{
      __html:
      policy || "<p>Not available.</p>",
    }}
    
    /> 
   
    {/* {policy?(<ReactQuill
      value={policy}
      readOnly={true}
      theme={"bubble"}
    />):( <span className="ml-2">Not available</span>)} */}
  </div>
);

// Product Dimensions Display Component
const ProductDimensions = ({ height, width, depth, weight }) => (
  <div className="text-sm text-gray-700 mb-3">
    <p>
      <span className="font-semibold">Height:</span> {height || "-"} cm
    </p>
    <p>
      <span className="font-semibold">Width:</span> {width || "-"} cm
    </p>
    <p>
      <span className="font-semibold">Depth:</span> {depth || "-"} cm
    </p>
    <p>
      <span className="font-semibold">Weight:</span> {weight || "-"} kg
    </p>
  </div>
);
