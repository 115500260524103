import Api from "../utils/axios";

export const FoodListingApi = {
  // getSubCategory: () => Api.post(`product/get-all-subcategory`),
  getSubCategory: () => Api.get(`product/all-food-subcategory`),
  createFoodProduct: (payload) =>
    Api.post("food-product/create-food-product", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getAllFoodProduct: (payload) =>
    Api.post("food-product/get-all-food-products", payload),
  deleteFoodProduct: (id) => 
    Api.delete(`food-product/delete-food-product/${id}`),

  updateFoodProduct: (payload, id) =>
    Api.put(`food-product/update-food-product/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  getFoodProductById: (id) =>
    Api.get(`food-product/get-food-product-by-id/${id}`),

  // getSubSingleCategory: (id) => Api.post(`/get-subcategory-by-id/${id}`),
  // createSubCategory: (payload) =>
  //   Api.post("product/create-subcategory", payload),
  // updateSubCategory: (payload, id) =>
  //   Api.put(`product/update-subcategory/${id}`, payload),
  // deleteSubCategory: (id) => Api.delete(`product/delete-subcategory/${id}`),
};

export const foodProductImageApi = {
  createFoodProductImages: (payload) =>
    Api.post("food-product/create-food-product-images", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getProductFoodImageById: (id) =>
    Api.get(`food-product/get-food-product-image/${id}`),
  getAllFoodProductImages: (id) =>
    Api.get(`food-product/get-all-food-product-images/${id}`),
  updateFoodProductImage: (payload, imageId) =>
    Api.put(`food-product/update-food-product-image/${imageId}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteFoodProductImage: (id) =>
    Api.delete(`food-product/delete-food-product-image/${id}`),
};

export const FoodModifierApi = {
  createFoodModifer: (payload) =>
    Api.post("food-product/create-modifier-mapping", payload),
  getProductFoodModifierById: (id) =>
    Api.get(`food-product/get-modifier-mapping-by-id/${id}`),
  getAllFoodProductModifier: (id) =>
    Api.get(`food-product/get-all-modifier-mappings/${id}`),
  updateFoodProductModifier: (payload, id) =>
    Api.put(`food-product/update-modifier-mapping/${id}`, payload),
  deleteFoodProductModifier: (id) =>
    Api.delete(`food-product/delete-modifier-mapping/${id}`),
};
