import React from "react";

const PaginationPrevNext = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  return (
    <>
      {totalPages > 0 && (
        <div className="flex items-center justify-start space-x-2 mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 border rounded-lg transition-colors duration-300 ${
              currentPage === 1
                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                : "bg-alphaColor text-white hover:bg-mainColor"
            }`}
          >
            Previous page
          </button>

          {/* {Array.from({ length: totalPages }, (_, index) => (
        <button
          key={index + 1}
          onClick={() => handlePageChange(index + 1)}
          className={`px-3 py-1 border rounded-lg transition-colors duration-300 ${
            index + 1 === currentPage
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500 hover:bg-gray-100"
          }`}
        >
          {index + 1}
        </button>
      ))} */}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 border rounded-lg transition-colors duration-300 ${
              currentPage === totalPages
                ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                : "bg-alphaColor text-white hover:bg-mainColor"
            }`}
          >
            Next page
          </button>
        </div>
      )}
    </>
  );
};

export default PaginationPrevNext;
