import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import BasicTable from "../../components/Table/BasicTable.jsx";
import {
  StorecanlenderFilter,
  StoreColumns,
  Storefilters,
  StoresearchBy,
  ProductColumns,
  ProductsearchBy,
  Productfilters,
  ProductcanlenderFilter
} from "../../service/ReviewService.js";
import OrderApi,{reviewApi} from "../../apis/order.apis.js";
// import OrderModal from "./OrderModal/OrderModal.jsx";
import { toast } from "react-toastify";
import { useFetchAll } from "../../custom/FetchApiHook.jsx";
import StarRating from "../../components/RatingStar.jsx";
import DataViewComp from "../../components/DataView/DataView.jsx";
import { useNavigate } from "react-router-dom";



const ReviewComp = ({reviewName,disabledFilters,pagination,handlePagination}) => {
  const navigate=useNavigate()
  const [search,setSearch]=useState("")
  const [selectedCellData,setSelectedCellData]=useState(null)

  const debounceTimeout = useRef(null); 
  const { value,fetchAllData } = useFetchAll(() =>reviewName==="product_review"?reviewApi.getAllProductReviews(pagination?pagination:{},search):reviewApi.getAllVendorReviews(pagination?pagination:{},search));
 

  console.log('value',value);
  // const {page,totalCount,totalPages,limit}=value.data

  const handleRowClick = (row,cellName) => {
    console.log("row", row);
  
  };

  const handleCellClick =async (cell,field="") => {
    console.log("cell", cell);
    console.log("field", field);

    if (field==="product_detail") {      
      // setSelectedCellData(cell);
      navigate(`/store/product-details/${cell.product_detail._id}`)
    }
  
  
  };

  const onPaginatorClick = (e) => {
    if (e) {
      console.log("Paginator Clicked",e);
     //  const row={
     //   filters: null,
     //   first: 0,
     //   multiSortMeta: [],
     //   page: 0,
     //   rows: 10,
     //   sortField: null,
     //   sortOrder: null,
     //   totalPages: 2   
     // }
     const data={
      limit:e.rows,
      page:+e.page+1
     }
     handlePagination && handlePagination(data)
     // handlePagination && handlePagination(e.row,limit,sortOrder,filter)
    }
  
  };

  const handleSearch = useCallback((s) => {
    // console.log('Search at OrderCom', s);
    setSearch(s);
}, []);

const debouncedSearch = useCallback((s) => {
  if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
  } 
  debounceTimeout.current = setTimeout(() => {
      handleSearch(s); 
  }, 1500);
}, [handleSearch]);


const handleClearFilter=()=>{
  const clearPagination=  {
      "page": 1,
      "limit": 5,
      "sortOrder": "desc",
      "filter": {}
    }
    console.log('clearPagination',clearPagination);
    
    handlePagination && handlePagination({clearPagination})
  }


const handleFilter=(name,value)=>{
  console.table('name,value',name,value);
  let data={}
  if (name==='search_by') {
    data.sortOrder=value.code
  }
  else{
   
    const filter={}
    filter[name]=value.code?value.code:value
    data.filter=filter
    
  }
  
  console.log('data',data);
   handlePagination && handlePagination(data)
  
  
  }

  // console.log("pagination",pagination);
  // console.log("search",search);
  // console.log("debouncedSearch",debouncedSearch);
  

  useEffect(() => {
    fetchAllData()
  }, [pagination,search]);


const DataFilter=reviewName==="product_review"?Productfilters.filter((f)=>disabledFilters?!disabledFilters.includes(f.inputName):true):Storefilters.filter((f)=>disabledFilters?!disabledFilters.includes(f.inputName):true)
// if (reviewName==="product_review") {
//   return(<div className="p-1">  
//     <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
//         <Card data={value?.data?.ratingDistribution} title={reviewName==="product_review"?"Product Rating":"Store Rating"} />     
//       </div>     
//       <DataViewComp   
//         columns={reviewName==="product_review"?ProductColumns:StoreColumns}
//         data={reviewName==="product_review"?value.data?.ratingsReviews:value?.data?.ratingsReviews}
//       />
      
//     </div>)
// }

    return (
      <div className="p-1">  
          <Card data={value?.data?.ratingDistribution} title={reviewName==="product_review"?"Product Rating":"Store Rating"} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
          {/* <Card title="Delivery Review" ratingCount="0 / 0" /> */}
        </div>   
        <BasicTable
          
          columns={reviewName==="product_review"?ProductColumns:StoreColumns}
          data={reviewName==="product_review"?value.data?.ratingsReviews:value?.data?.ratingsReviews}
          onPaginatorClick={onPaginatorClick}
          paginatorData={{
            filters: null,
            first: 0,
            multiSortMeta: [],
            page: value?.data?.page||1,
            rows: value?.data?.limit||5,
            sortField: null,
            sortOrder: null,
            totalPages: value.data?.totalPages||1  ,
            totalCount: value.data?.totalCount||1  
          }}
          SearchBy={reviewName==="product_review"?ProductsearchBy:StoresearchBy}
          handleSearch={debouncedSearch}
          handleFilter={(name,value)=>handleFilter(name,value)}
          handleClearFilter={handleClearFilter}
          filters={DataFilter}
          WeekRange={false}
          MonthRange={true}
          canlenderFilter={reviewName==="product_review"?ProductcanlenderFilter:StorecanlenderFilter}
          selectedData={selectedCellData}
          
          handleCellClick={handleCellClick}
        />     
      </div>
    );
  }

export default ReviewComp;



const Card = ({data, title, ratingCount }) => {
  const { theme } = useSelector((state) => state.theme);

  console.log("Review card ",data);
  

  return (
    <div
      className={`p-3 w-full rounded-lg shadow-2xl ${
        theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
      }`}
    >
      <div className="flex justify-between items-center">
        <span className="text-lg ">{title}</span>
        {/* <span className="text-2xl">{ratingCount}</span> */}
      </div>
      <div className="py-3">
        {data && data.map((d,i)=>( <div key={i} className="flex justify-between">
          <span className="flex">
            <StarRating stars={d.rating}/>       
          </span>
          <span>{d.count} ({d.percentage}%)</span>
        </div>))}        
      </div>
    </div>
  );
};
