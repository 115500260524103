import React, { useEffect, useState } from "react";

const ImagePreview = ({ file, Id }) => {
  const [preview, setPreview] = useState();
  console.log("File", file);

  const renderFile = (f) => {
    if (f) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(f);
    }
  };

  useEffect(() => {
    if (typeof file === "object") {
      renderFile(file);
    }

    return undefined;
  }, [file]);

  if (typeof file === "object") {
    return (
      <img
        id={Id}
        src={preview}
        alt="Preview"
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    );
  } else {
    return (
      <img
        id={Id}
        src={file}
        alt="Preview"
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
      />
    );
  }
};

export default ImagePreview;
