import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React from "react";
import StarRating,{ BiggerStar }  from "../RatingStar";
import PaginationPrevNext from "../Paginator/PaginationPrevNext";


const ReviewsSection = ({
  reviewData,
  totalRating,
  totalReview,
  averageRating,
  ratingDistribution,
  totalPages,
  setReviewPage,
  currentPage,
}) => {
  return (
    <div className=" p-2 ">
      <h2 className="text-3xl font-bold">Customer reviews</h2>
      <section className="flex w-full flex-col lg:flex-row mb-20 ">
        <div className="flex  flex-col gap-6  items-start w-full  mt-10 lg:w-[30%] ">
          <div>
            <span className="text-3xl font-bold">{totalReview}</span>
            <span className="text-gray-500 ml-2">Total Reviews</span>
          </div>
          <div>
            {averageRating ? (
              <div>
                <div className="flex items-center justify-center">
                  <BiggerStar stars={Number(averageRating).toFixed(1)} />
                  <span className="ml-1 mt-1 text-xl text-black">out of 5</span>
                </div>
              </div>
            ) : (
              <span className="text-gray-500">No Average Rating</span>
            )}
          </div>
          <div>
            <span>{totalRating} global ratings</span>
          </div>
          <div className="text-right">
            <div className=" flex flex-col gap-3 mt-2">
              {ratingDistribution &&
                ratingDistribution.map(({ rating, count, percentage }) => (
                  <div
                    key={rating}
                    className={`flex items-center gap-1 ${
                      rating === 1 ? "pl-[0.2rem] " : ""
                    } `}
                  >
                    <span className="text-sm">{rating} stars</span>
                    <div className="ml-2 w-40 bg-gray-200 rounded-sm">
                      <div
                        className="bg-green-500 h-5 rounded-sm"
                        style={{
                          width:
                            totalReview > 0
                              ? `${(count / totalReview) * 100}%`
                              : "0%",
                        }}
                      ></div>
                    </div>
                    <span className="ml-2 text-gray-500 text-sm ">
                      {Number(percentage).toFixed(0)}%
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="mt-6 space-y-4 w-full">
          {reviewData.length > 0 ? (
            reviewData.map((review) => (
              <div key={review._id} className="p-4 ">
                <div className="flex items-center justify-between">
                  <div className="flex items-center ">
                    <LazyLoadImage
                      src={review.created_by.profile_image}
                      alt={review.created_by.first_name}
                      effect="blur"
                      className="w-[2.5rem] h-[2.5rem] object-cover rounded-full bg-red-400"
                    />
                    <div className="ml-4">
                      <span className="font-semibold">
                        {`${review.created_by.first_name} ${review.created_by.last_name}`}
                      </span>

                      <div className="flex justify-start items-center">
                        <StarRating stars={review?.rating} />
                      </div>
                    </div>
                  </div>
                  <span className="text-sm text-gray-500">
                    {/* {new Date(review.createdAt).toLocaleDateString()} */}
                    {moment(review.createdAt).format("MMM Do, YYYY")}
                  </span>
                </div>
                <p className="text-sm text-gray-600 mt-2">{review.review}</p>
                {review.images.length > 0 && (
                  <div className="flex mt-2 space-x-2">
                    {review.images.map((image, index) => (
                      <LazyLoadImage
                        key={index}
                        src={image}
                        alt="Review image"
                        effect="blur"
                        className="w-16 h-24 rounded-lg object-contain"
                      />
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="text-center text-black flex items-center justify-center w-full font-bold ">
              No customer reviews
            </div>
          )}
        </div>
      </section>
      <PaginationPrevNext
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setReviewPage}
      />
    </div>
  );
};

export default ReviewsSection;
