import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import OrderFilters from "../Filter/OrderFilters";
import PaginatorCom from "../Paginator/PaginatorCom";

export default function BasicTable({
  columns,
  data,
  paginatorData,
  SearchBy,
  handleSearch,
  handleFilter,
  handleClearFilter,
  filters,
  WeekRange,
  MonthRange,
  canlenderFilter,
  handleRowClick,
  handleCellClick,
  onPaginatorClick,
  selectedData
}) {
  const { theme } = useSelector((state) => state.theme);
const [selected, setSelectedData] = useState(selectedData);
  const [metaKey, setMetaKey] = useState(false);

  

  const onRowClick = (e) => {
    console.log("Row clicked:", e);
    const cellName=e.originalEvent.target.innerText
    setSelectedData(e.data);
    handleRowClick && handleRowClick(e.data,cellName)
  };


  const isSelectable = (data) =>true;

const isRowSelectable = (event) => (event.data ? isSelectable(event.data) : true);

const rowClassName = (data) => (isSelectable(data) ? '' : 'p-disabled');
console.log("rowClassName",rowClassName);


 
  

  return (
    <div
      className={`${
        theme === "light"
          ? "bg-secondaryColor text-textColorLight"
          : "bg-darkThirsary text-darkSecondary"
      } shadow-2xl p-5 rounded-xl `}
    >
        <OrderFilters
        filters={filters ? filters : []}
        SearchBy={SearchBy?.options.length ? SearchBy : { options: [] }}
        WeekRange={WeekRange ? WeekRange : false}
        MonthRange={MonthRange ? MonthRange : false}
        canlenderFilter={canlenderFilter}
        handleFilter={(name, value)=>handleFilter && handleFilter(name, value)}
        handleClearFilter={()=>handleClearFilter && handleClearFilter()}
        handleSearch={handleSearch?handleSearch:null}
        /> 


        <DataTable
        value={data}               
        onRowClick={onRowClick}
        selectionMode="single"
        selection={selected}
        metaKeySelection={metaKey}
        onSelectionChange={(e) => setSelectedData(e.value)}
        dataKey="_id"
        isDataSelectable={isRowSelectable} 
        rowClassName={rowClassName}
        >

        {columns.map((col, i) => (
        <Column
          key={col.field}
          field={col.field}
          header={col.header} 
          body={(rowData)=>col.accessor ? col.accessor(rowData,handleCellClick):renderBody(rowData[col.field],handleCellClick)}
          className={`p-2.5 text-sm border-b ${
                          theme === "light"
                            ? "bg-secondaryColor text-textColorLight"
                            : "bg-darkThirsary text-darkSecondary"
                        }`}
        />
        ))}
        </DataTable>
        <PaginatorCom paginatorData={paginatorData} onPaginatorClick={onPaginatorClick}/> 
    </div>
  );
}

const renderBody=(data,handleCellClick)=>{
  // console.log("data",data);
  
  return(<div onClick={()=>handleCellClick && handleCellClick(data)} >
       {data}
  </div>)
}

