import React from 'react'
import ProductPage from '../../components/products/ProductPage'
import BreadCrumb from '../../components/BreadCrumb'

function ProductDetails() {
    return(<>
     <div className="p-5">
     <BreadCrumb title={"Product Details"} />
     <ProductPage/>
     </div>
    </>)
}

export default ProductDetails