// components/TextInput.jsx

import { useSelector } from "react-redux";

const TextInput = ({
  name,
  type,
  placeholder,
  value,
  onChange,
  required,
  disabled,
  className,
  minimumValue,
  maximumValue,
  sx = {},
  isApplied,
}) => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      disabled={disabled}
      min={minimumValue}
      max={maximumValue}
      required={required ? true : false}
      className={`${
        theme === "light" ? "" : "bg-darkInput "
      } border p-2  text-sm w-full ${className || ""}`}
      style={sx}
      readOnly={isApplied}
    />
  );
};

export default TextInput;
