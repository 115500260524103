import React, { useState } from "react";
import { IoMdSend } from "react-icons/io";

const OrderChat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = () => {
    if (newMessage.trim() !== "") {
      setMessages([
        ...messages,
        {
          id: messages.length + 1,
          text: newMessage,
          date: new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        },
      ]);
      setNewMessage("");
    }
  };

  return (
    <div className="flex flex-col justify-between h-full p-5 relative  rounded  max-w-lg mx-auto">
      <div className="flex flex-col space-y-4 overflow-y-auto mb-4  h-full">
        {messages.map((message) => (
          <div key={message.id} className="flex justify-end p-2">
            <div className="bg-gray-100 p-3 rounded-lg shadow-sm">
              <p className="text-sm text-gray-500 mb-1">{message.date}</p>
              <p className="text-gray-700">{message.text}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex items-center absolute  bottom-1  w-[90%]    px-2">
        <input
          type="text"
          className="flex-grow  rounded-l-lg p-2 h-11 focus:outline-none focus:bg-slate-700"
          placeholder="Say something..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button
          className="p-2    bg-blue-500 text-white rounded-r-lg focus:outline-none"
          onClick={handleSendMessage}
        >
          <IoMdSend className="text-3xl" />
        </button>
      </div>
    </div>
  );
};

export default OrderChat;
