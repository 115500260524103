import Api from "../utils/axios";

const OrderApi = {
  getAllOrders: (payload,search="") => Api.post(`order/get-all-orders?search=${search}`,payload),  
  updateOrders: (payload) => Api.put(`order/update-order`,payload),  
};

export default OrderApi;

export const reviewApi={
  getAllVendorReviews: (payload,search="") => Api.post(`order/get-all-vendor-review?search=${search}`,payload),  
  getAllProductReviews: (payload,search="") => Api.post(`order/get-all-product-review?search=${search}`,payload),  
}