import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
export default function Card({ key, data }) {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div key={key} className="n-card">
      <h1>{data.heading}</h1>
      <div className="d-flex justify-content-between flex-column">
        {data.contents.length
          ? data.contents.map(({ Icon, label, count }, i) => (
              <div className="d-flex justify-content-between align-items-center">
                <div className={styles.wrapper}>
                  <p className={`${styles.pIcon} text-buttonColor`}>
                    <Icon />
                  </p>
                  <p className={styles.pLabel}>{label}</p>
                </div>
                <p className={styles.pLabel}>{count}</p>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
}
