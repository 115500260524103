import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import {lang} from '../../utils/locale/index.js'
import { useTranslation } from "react-i18next";
import './css/flag.css'


export default function LangaugeSelector() {
    const { t, i18n: {changeLanguage, language} } = useTranslation();
    const currentLang=localStorage.getItem("lang") && localStorage.getItem("lang")!==undefined?localStorage.getItem("lang"):"en"
    console.log("currentlang",currentLang);
    const [selectedLang, setSelectedLang] = useState(currentLang||"en");

    const countries = [
        { name: 'English', code: 'US', value:"en" },
        { name: 'Hindi', code: 'IN',value:"hi" },
    ];

const HandleLangChange=(l)=>{
        console.log("Language",l);
        localStorage.setItem("lang",l)
        setSelectedLang(l);
        changeLanguage(l);
    }

console.log("language",language);
console.log("lang[`${language}`].translation.specificKey",lang[`${language}`].specificKey);


    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div  className="flex align-items-center gap-2">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div className="uppercase">{option.value}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedLang ? (
                    <span>
                        <b>{selectedLang}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };

    return (
        <span className="border border-[#00000050] rounded-full">
            <Dropdown value={selectedLang} onChange={(e) => HandleLangChange(e.value)} options={countries} optionLabel="name" placeholder="EN" 
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="w-w-fit" panelFooterTemplate={panelFooterTemplate} 
                dropdownIcon={(opts) => {
                    return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                }}/>

            {/* <span className="mx-3"> {t(lang[`${language}`].specificKey)}</span> */}
        </span>    
    )
}
        