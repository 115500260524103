import Api from "../utils/axios";

const StoreSettingApi = {
    createStoreSettingMaster: (payload) => Api.post(`/vendor/create-store-delivery-setting`,payload),
    updateStoreDelivery: (deleverySettingId,payload) => Api.put(`/vendor/update-store-delivery-setting/${deleverySettingId}`,payload),
    getStoreDeliveryById: (deleverySettingId) => Api.get(`/vendor/get-store-delivery-setting-by-id/${deleverySettingId}`),
    getAllStoreDelivery: () => Api.get("/vendor/get-all-store-delivery-settings"),
    deleteStoreDeliveryById: (deleverySettingId) => Api.delete(`/vendor/delete-store-delivery-setting/${deleverySettingId}`),
  };
  
  export default StoreSettingApi;


export const GeneralSettingApi = {
    createGeneralSettingMaster: (payload) => Api.post(`/vendor/create-store-genral-settings`,payload),
    updateGeneralSetting: (generalSettingId,payload) => Api.put(`/vendor/update-store-genral-settings/${generalSettingId}`,payload),
    getGeneralSettingById: (generalSettingId) => Api.get(`/vendor/get-store-genral-settings-by-id/${generalSettingId}`),
    getAllGeneralSetting: () => Api.get("/vendor/get-all-store-genral-settings"),
    deleteGeneralSettingById: (generalSettingId) => Api.delete(`/vendor/delete-store-genral-settings/${generalSettingId}`),
  };
  
