import React, { useCallback, useEffect, useRef, useState } from "react";
import BasicTable from "../../components/Table/BasicTable.jsx";
import {
  canlenderFilter,
  columns,
  data,
  filters,
  searchBy,
} from "../../service/OrderService.js";
import { useFetchAll } from "../../custom/FetchApiHook.jsx";
import OrderApi from "../../apis/order.apis.js";
import OrderModal from "./OrderModal/OrderModal.jsx";
import { toast } from "react-toastify";

// const OrderComp = ({pagination}) => {
//   const { value } = useFetchAll(() => OrderApi.getAllOrders(pagination?pagination:{}));
//   const [isOrderDetail, setIsOrderDetail] = useState(false);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   console.log(value);
//   const handleCloseOrderModal=()=>{

//     setIsOrderDetail(true);
//     setSelectedOrder(null);
//   }
//   const handleRowClick = (row) => {
//     console.log("row", row);
//     setSelectedOrder(row);
//     setIsOrderDetail(false);
//   };

//   return (
//     <div className="p-5">
//       <BasicTable
//         data={value?.data?.orders}
//         // data={[]}
//         columns={columns}
//         SearchBy={searchBy}
//         filters={filters}
//         WeekRange={false}
//         MonthRange={true}
//         handleRowClick={(row) => handleRowClick(row)}
//       />
//       <OrderModal
//         isOrderDetail={isOrderDetail}
//         handleCloseOrderModal={handleCloseOrderModal}
//         orderData={selectedOrder}
//       />
//     </div>
//   );
// };

// export default OrderComp;

// {
//   "title": "Current Week",
//   "path": "history/current-week",
//   "icon": "SlCalender"
// },
// {
//   "title": "Current Month",
//   "path": "history/current-month",
//   "icon": "VscCalendar"
// },

const  OrderComp = ({disabledFilters,pagination,handlePagination}) => {
  const [search,setSearch]=useState("")
  const debounceTimeout = useRef(null); 
  const { value,fetchAllData } = useFetchAll(() => OrderApi.getAllOrders(pagination?pagination:{},search));
  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  console.log(value);
  // const {page,totalCount,totalPages,limit}=value.data
  const handleCloseOrderModal=()=>{

    setIsOrderDetail(false);
    setSelectedOrder(null);
  }
  const handleRowClick = (row,cellName) => {
    console.log("row", row);
    setSelectedOrder(row);
    setIsOrderDetail(true);
  };

  const handleCellClick =async (cell,field="") => {
    console.log("cell", cell);
    console.log("field", field);

    if (field==="order_id") {      
      setSelectedOrder(cell);
      setIsOrderDetail(true);
    }
    if (field.vendor_action) {
      const payload={
        orderId:cell._id,
        vendor_action:field.vendor_action
      }
      try {
      const res = await  OrderApi.updateOrders(payload)
      console.log("updateOrders::res",res);
      fetchAllData()
      toast.success(`Order ${res?.data?.data?.delivery_status}`)
      
      } catch (error) {
        console.log(error);
        
      }
    }
  
  };

  const onPaginatorClick = (e) => {
    if (e) {
      console.log("Paginator Clicked",e);
     //  const row={
     //   filters: null,
     //   first: 0,
     //   multiSortMeta: [],
     //   page: 0,
     //   rows: 10,
     //   sortField: null,
     //   sortOrder: null,
     //   totalPages: 2   
     // }
     const data={
      limit:e.rows,
      page:+e.page+1
     }
     handlePagination && handlePagination(data)
     // handlePagination && handlePagination(e.row,limit,sortOrder,filter)
    }
  
  };

  const handleSearch = useCallback((s) => {
    // console.log('Search at OrderCom', s);
    setSearch(s);
}, []);

const debouncedSearch = useCallback((s) => {
  if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
  } 
  debounceTimeout.current = setTimeout(() => {
      handleSearch(s); 
  }, 1500);
}, [handleSearch]);


const handleClearFilter=()=>{
  const clearPagination=  {
      "page": 1,
      "limit": 5,
      "sortOrder": "desc",
      "filter": {}
    }
    console.log('clearPagination',clearPagination);
    
    handlePagination && handlePagination({clearPagination})
  }


const handleFilter=(name,value)=>{
  console.table('name,value',name,value);
  let data={}
  if (name==='search_by') {
    data.sortOrder=value.code
  }
  else{
   
    const filter={}
    filter[name]=value.code?value.code:value
    data.filter=filter
    
  }
  
  console.log('data',data);
   handlePagination && handlePagination(data)
  
  
  }

  // console.log("pagination",pagination);
  // console.log("search",search);
  // console.log("debouncedSearch",debouncedSearch);
  

  useEffect(() => {
    fetchAllData()
  }, [pagination,search]);


const DataFilter=filters.filter((f)=>disabledFilters?!disabledFilters.includes(f.inputName):true)
  return (
    <div className="p-1">     
      <BasicTable
        // data={[]}
        columns={columns}
        data={value?.data?.orders}
        onPaginatorClick={onPaginatorClick}
        paginatorData={{
          filters: null,
          first: 0,
          multiSortMeta: [],
          page: value?.data?.page||1,
          rows: value?.data?.limit||5,
          sortField: null,
          sortOrder: null,
          totalPages: value.data?.totalPages||1  ,
          totalCount: value.data?.totalCount||1  
        }}
        SearchBy={searchBy}
        handleSearch={debouncedSearch}
        handleFilter={(name,value)=>handleFilter(name,value)}
        handleClearFilter={handleClearFilter}
        filters={DataFilter}
        WeekRange={false}
        MonthRange={true}
        canlenderFilter={canlenderFilter}
        selectedData={selectedOrder}
        // handleRowClick={(row,cellName) => handleRowClick(row,cellName)}
        handleCellClick={handleCellClick}
      />
      <OrderModal
        isOrderDetail={isOrderDetail}
        handleCloseOrderModal={handleCloseOrderModal}
        orderData={selectedOrder}
      />
    </div>
  );
};

export default OrderComp;