import React from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";

export default function PaymentEarning({ key, left, right }) {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div
      key={key}
      className={`${styles.container}`}
    >
      <div className={styles.wrapper}>
        <div className={`${styles.circle}`}>
          <div className={`${styles.innerCircle} ${theme === "light" ?"text-blue-950":"text-white"}`}>
            {left.progress}%
          </div>
          <div
            className={styles.progressBorder}
            style={{ "--progress": left.progress }}
          ></div>
        </div>

        <span className={`${styles.cardText} ${theme === "light" ?"text-blue-950":"text-white"}`}>{left.label}</span>
        <span className={`${styles.cardText} ${theme === "light" ?"text-blue-950":"text-white"}`}>{left.price}</span>
      </div>
      <div className={styles.wrapper}>
        <div className={`${styles.circle}`}>
          <div className={`${styles.innerCircle} ${theme === "light" ?"text-blue-950":"text-white"}`}>
            {right.progress}%
          </div>
          <div
            className={styles.progressBorder}
            style={{ "--progress": right.progress }}
          ></div>
        </div>
        <span className={`${styles.cardText} ${theme === "light" ?"text-blue-950":"text-white"}`}>{right.label}</span>
        <span className={`${styles.cardText} ${theme === "light" ?"text-blue-950":"text-white"}`}>{right.price}</span>
      </div>
    </div>
  );
}
