import moment from "moment";
import StarRating from "../components/RatingStar";
import ImageGallery from "../components/ImageCarousel/PrimeCarousel";

export const StoreColumns = [
  { field: "createdAt", header: "Date",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"createdAt")}  className="">          
          <p className="w-[6rem]">
            {moment(row?.createdAt).utc().format("DD MMM YYYY hh:mma")}
          </p>
        </div>
      </>
    ),
   },
  { field: "user", header: "Customer",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"user")}  className="">          
          <p className="w-[6rem]">
            {row?.user?.first_name || ""}   {row?.user?.last_name || ""} 
          </p>
        </div>
      </>
    ),
   },
  { field: "rating", header: "Rating",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"user")}  className="">          
          <p className="w-[6rem]">
            <StarRating stars={row?.rating}/>   
          </p>
        </div>
      </>
    ),
   },
  { field: "review", header: "Review",
    accessor: (row,handleCellClick) => {

      return (
        <>
          <div  onClick={()=>handleCellClick(row,"review")}  className="h-5 line-clamp-2">          
            <p className="w-[6rem]">
              {row?.review || ""}
            </p>
          </div>
        </>
      )
    },
   },
  // { field: "images", header: "Images",
  //   accessor: (row,handleCellClick) => (
  //     <>
  //       <div  onClick={()=>handleCellClick(row,"images")}  className="">          
  //         <p className="w-[6rem]">
  //           {/* {JSON.stringify(row)} */}
  //           {row?.images || ""}
  //         </p>
  //       </div>
  //     </>
  //   ),
  //  },
  
];


export const StoresearchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

export const Storefilters = [
  {
    filterType: "Rating",
    inputName: "rating",
    options: [
      {
        name: "5",
        code: "5",
      },
      {
        name: "4",
        code: "4",
      },
      {
        name: "3",
        code: "3",
      },
      {
        name: "2",
        code: "2",
      },
      {
        name: "1",
        code: "1",
      },
     
    ],
  },
 
];


export const StorecanlenderFilter={
  filterType:"Review Date",
  inputName:"createdAt",  
  maxDate :new Date()
}

export const ProductColumns = [
  { field: "createdAt", header: "Date",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"createdAt")}  className="">          
          <p className="w-[6rem]">
            {moment(row?.createdAt).utc().format("DD MMM YYYY hh:mma")}
          </p>
        </div>
      </>
    ),
   },
  { field: "user", header: "Customer",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"user")}  className="">          
          <p className="w-[6rem]">
            {row?.user?.first_name || ""}   {row?.user?.last_name || ""} 
          </p>
        </div>
      </>
    ),
   },
  { field: "product_detail", header: "Product Details",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"product_detail")} className="cursor-pointer text-blue-600 hover:text-blue-800">          
          <p className="w-[6rem] line-clamp-2">
            {row?.product_detail?.name || ""} 
          </p>
        </div>
      </>
    ),
   },
  { field: "rating", header: "Rating",
    accessor: (row,handleCellClick) => (
      <>
        <div  onClick={()=>handleCellClick(row,"user")}  className="">          
          <p className="w-[6rem]">
            <StarRating stars={row?.rating}/>   
          </p>
        </div>
      </>
    ),
   },
  { field: "review", header: "Review",
    accessor: (row,handleCellClick) => {

      return (
        <>
          <div  onClick={()=>handleCellClick(row,"review")}  className="w-[6rem] h-5 line-clamp-2">          
            <p className="w-[6rem]">
              {row?.review || ""}
            </p>
          </div>
        </>
      )
    },
   },
  // { field: "images", header: "Images",
  //   accessor: (row,handleCellClick) => (
  //     <>
  //       <div  onClick={()=>handleCellClick(row,"images")}  >    
  //       <ImageGallery 
  //       images={row?.images?row?.images.map((img,i)=>{
  //         const galleryData={
  //           itemImageSrc: img,
  //           thumbnailImageSrc: img,
  //           alt: img,
  //           title: img
  //         }
  //         return galleryData
  //       }):[]}

  //       sx={{maxWidth:"20%"}}
  //       />
  //       </div>
  //     </>
  //   ),
  //  },
  
];


export const ProductsearchBy = {
  filterType: "Sort By",
  inputName: "search_by",
  options: [
    {
      name: "Descending ",
      code: "desc",
    },
    {
      name: "Ascending",
      code: "asc",
    },
  ],
};

export const Productfilters = [
  {
    filterType: "Rating",
    inputName: "rating",
    options: [
      {
        name: "5",
        code: "5",
      },
      {
        name: "4",
        code: "4",
      },
      {
        name: "3",
        code: "3",
      },
      {
        name: "2",
        code: "2",
      },
      {
        name: "1",
        code: "1",
      },
     
    ],
  },
 
];


export const ProductcanlenderFilter={
  filterType:"Review Date",
  inputName:"createdAt",  
  maxDate :new Date()
}
