import React, { useState } from "react";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";

export default function PaginatorCom({ paginatorData, onPaginatorClick }) {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onPageChange = (e, index) => {
    setFirst(e.first);
    setRows(e.rows);
    onPaginatorClick && onPaginatorClick(e);
  };

  const template2 = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      let dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 120, value: 120 },
      ];

      if (!dropdownOptions.some((item) => item.label === paginatorData?.rows)) {
        dropdownOptions.push({
          label: paginatorData?.rows,
          value: paginatorData?.rows,
        });
        dropdownOptions.sort((a, b) => a.value - b.value);
      }

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div className="mt-10">
      <Paginator
        template={template2}
        first={first}
        rows={paginatorData?.rows || 5}
        totalRecords={paginatorData?.totalCount || 100}
        onPageChange={(e) => onPageChange(e, 1)}
        className="justify-content-end"
      />
    </div>
  );
}
