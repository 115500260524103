export function compressImage(file, callback) {
  console.log(file)
  const reader = new FileReader();

  reader.onload = function (event) {
    const img = new Image();
    img.src = event.target.result;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Adjust the quality or size as needed
      const quality = 0.7; // Adjust this value to reduce file size

      // Convert the canvas to a data URL (base64)
      file && canvas.toBlob(
        (blob) => {
          if (blob.size > 1000000) {
            compressImage(
              new File([blob], file.name, { type: file.type }),
              callback
            );
          } else {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
            });
            callback(compressedFile);
          }
        },
        file.type,
        quality
      );
    };
  };

  file && reader.readAsDataURL(file);
}
