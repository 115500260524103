import { IoIosInformationCircleOutline } from "react-icons/io";
import Lable from "./Lable";
import Tooltip from "./Tooltip/Tooltip";


export const FormField = ({ label,tooltip, children, className = "" }) => (
    <div className={`form-field ${className}`}>
      <div className="flex gap-1 items-start">
      <Lable lable={label} /> 
      {tooltip?(<Tooltip text={tooltip} size={16} className="mb-2 cursor-pointer"><IoIosInformationCircleOutline /></Tooltip>):""}
      </div>
      {children}
    </div>
  );