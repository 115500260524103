// ErrorPage.js
import React from "react";

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-100 text-red-800 text-center p-4">
      <h1 className="text-3xl font-bold mb-4">Oops! Something went wrong.</h1>
      <p className="text-lg mb-6">
        We are sorry for the inconvenience. Please try refreshing the page or
        contact support if the issue persists.
      </p>
      <button
        onClick={() => window.location.reload()}
        className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-700 rounded"
      >
        Refresh
      </button>
    </div>
  );
};

export default ErrorPage;
