import * as Yup from "yup";
export const loginValidationSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

// Validation schema for register page
export const registerValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character"
    )
    .required("Password is required"),
  // country: Yup.string().required("Country is required"),
  // city: Yup.string().required("City is required"),
  // phone: Yup.string().required("Phone number is required"),
  // referralCode: Yup.string().required("Referral Code is required"),
  // address: Yup.string().required("Address is required"),
  // store: Yup.string().required("Store is required"),
});

export const createOrderSchem = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Address is required"),
  price: Yup.string().required("Price is required"),
  vehical: Yup.string().required("Vehical is required"),
});

// const inferYupType = (value, fieldName) => {
//   if (Array.isArray(value)) {
//     return Yup.array()
//       .of(Yup.string().required(`${fieldName} cannot be empty`))
//       .min(1, `${fieldName} must have at least one item`)
//       .required(`${fieldName} is required`);
//   } else if (typeof value === 'number') {
//     return Yup.number()
//       .typeError(`${fieldName} must be a number`)
//       .required(`${fieldName} is required`);
//   } else if (typeof value === 'boolean') {
//     return Yup.boolean().required(`${fieldName} is required`);
//   } else {
//     return Yup.string().required(`${fieldName} is required`);
//   }
// };


// export const generateDynamicValidationSchema = (initialValues) => {
//   const shape = Object.keys(initialValues).reduce((schema, key) => {
//     const value = initialValues[key];
//     schema[key] = inferYupType(value, key);
//     return schema;
//   }, {});

//   return Yup.object().shape(shape);
// };




// // Function to check if an object is a file
// const isFileObject = (value) => {
//   return value && typeof value === 'object' && 'name' in value && 'lastModified' in value;
// };

// // Validation function to handle both image URLs and file objects
// const inferYupType = (value, fieldName) => {
//   if (Array.isArray(value)) {
//     return Yup.array()
//       .of(
//         Yup.mixed().test(
//           'is-valid-file-or-url',
//           `${fieldName} must contain valid image files or URLs`,
//           (item) => {
//             if (typeof item === 'string') {
//               // Validate as a URL
//               return Yup.string()
//                 .url(`${fieldName} must be a valid URL`)
//                 .matches(/\.(jpeg|jpg|png|webp)$/, `${fieldName} must be an image URL`)
//                 .isValidSync(item);
//             } else if (isFileObject(item)) {
//               // Validate as a file object
//               const validFileExtensions = /\.(jpeg|jpg|png|webp)$/;
//               return validFileExtensions.test(item.name);
//             }
//             return false; // Invalid type
//           }
//         )
//       )
//       .min(1, `${fieldName} must have at least one valid image`)
//       .required(`${fieldName} is required`);
//   } else {
//     return Yup.mixed().required(`${fieldName} is required`);
//   }
// };

// // Function to dynamically generate schema based on initial values
// export const generateDynamicValidationSchema = (initialValues) => {
//   const shape = Object.keys(initialValues).reduce((schema, key) => {
//     const value = initialValues[key];
//     schema[key] = inferYupType(value, key);
//     return schema;
//   }, {});

//   return Yup.object().shape(shape);
// };

// // Example usage
// const initialValues = {
//   images: [
//     { name: 'logo.png', lastModified: 1724543459541 },
//     "http://res.cloudinary.com/doyb9ojic/image/upload/v1726232359/xlbyt35prygdgzeucfrw.webp",
//     "http://res.cloudinary.com/doyb9ojic/image/upload/v1726232360/jggxha5s7gzslah5z4bh.webp"
//   ]
// };

// // Validate using the generated schema
// const validationSchema = generateDynamicValidationSchema(initialValues);

// validationSchema
//   .validate(initialValues)
//   .then(() => {
//     console.log("Validation passed!");
//   })
//   .catch((err) => {
//     console.log("Validation error:", err.errors);
//   });


// Function to check if an object is a file
const isFileObject = (value) => {
  return value && typeof value === 'object' && 'name' in value && 'lastModified' in value;
};

// Function to infer Yup schema type based on input value or predefined type
const inferYupType = (type, fieldName) => {
  switch (type) {
    case 'file':
      return Yup.mixed()
        .test(
          'is-valid-file',
          `${fieldName} must be a valid image file or URL`,
          (item) => {
            if (typeof item === 'string') {
              // Allow URLs as valid too
              return Yup.string()
                .url(`${fieldName} must be a valid URL`)
                .matches(/\.(jpeg|jpg|png|webp)$/, `${fieldName} must be a valid image URL`)
                .isValidSync(item);
            } else if (isFileObject(item)) {
              const validFileExtensions = /\.(jpeg|jpg|png|webp)$/;
              return validFileExtensions.test(item.name);
            }
            return false; // Invalid type
          }
        )
        .required(`${fieldName} is required`);

    case 'file_or_url':
      return Yup.array()
        .of(
          Yup.mixed().test(
            'is-valid-file-or-url',
            `${fieldName} must contain valid image files or URLs`,
            (item) => {
              if (typeof item === 'string') {
                // Validate as a URL
                return Yup.string()
                  .url(`${fieldName} must be a valid URL`)
                  .matches(/\.(jpeg|jpg|png|webp)$/, `${fieldName} must be a valid image URL`)
                  .isValidSync(item);
              } else if (isFileObject(item)) {
                // Validate as a file object
                const validFileExtensions = /\.(jpeg|jpg|png|webp)$/;
                return validFileExtensions.test(item.name);
              }
              return false; // Invalid type
            }
          )
        )
        .min(1, `${fieldName} must have at least one valid image`)
        .required(`${fieldName} is required`);

    case 'boolean':
      return Yup.boolean().required(`${fieldName.replace('_',' ')} is required`);
    case 'string':
      return Yup.string().required(`${fieldName.replace('_',' ')} is required`);

    case 'array':
      return Yup.array()
        .of(Yup.string().required(`${fieldName.replace('_',' ')} item cannot be empty`))
        .min(1, `${fieldName.replace('_',' ')} must have at least one item`)
        .required(`${fieldName.replace('_',' ')} is required`);

    case 'number':
      return Yup.number()
        .typeError(`${fieldName.replace('_',' ')} must be a number`)
        .required(`${fieldName.replace('_',' ')} is required`);

    default:
      return Yup.mixed().required(`${fieldName.replace('_',' ')} is required`);
  }
};

// Function to dynamically generate schema based on field types
export const generateDynamicValidationSchema = (fieldTypes) => {
  const shape = Object.keys(fieldTypes).reduce((schema, key) => {
    const type = fieldTypes[key];
    schema[key] = inferYupType(type, key);
    return schema;
  }, {});

  return Yup.object().shape(shape);
};

export const forgatePasswordValidationSchema = Yup.object()
  .shape({
    email: Yup.string().email("Invalid email"),
    phone: Yup.string(),
  })
  .test(
    "email-or-phone",
    "At least one of email or phone is required",
    function (value) {
      return value.email || value.phone;
    }
  );

export const otpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required("OTP is required")
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits"),
});

export const passwordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    ),

  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});




export const productSchema = Yup.object().shape({
  primary_image: Yup.mixed().required('Primary image is required'),
  name: Yup.string().required('Product name is required'),
  brand: Yup.string().nullable(),
  model: Yup.string().nullable(),
  serial_number: Yup.string().nullable(),
  product_color: Yup.string().matches(/^#([0-9A-F]{3}){1,2}$/i, 'Invalid color code'),
  product_details: Yup.array().of(
    Yup.object().shape({
      product_size: Yup.string().nullable(),
      mrp_price: Yup.number().required('MRP price is required'),
      sales_price: Yup.number().required('Sales price is required'),
      stock: Yup.number().required('Stock is required'),
      product_width: Yup.number().required('Product width is required'),
      product_height: Yup.number().required('Product height is required'),
      product_depth: Yup.number().nullable(),
      product_weight: Yup.number().required('Product weight is required'),
    })
  ).required(),
  tax: Yup.number().nullable(),
  sub_category: Yup.string().required('Sub category is required'),
  additional_info: Yup.string().nullable(),
  description: Yup.string().required('Description is required'),
  specification: Yup.string().required('Specification is required'),
  cancellation_policy: Yup.string().required('Cancellation policy is required'),
  replacement_policy: Yup.string().required('Replacement policy is required'),
  return_policy: Yup.string().required('Return policy is required'),
  is_visible: Yup.boolean().required('Visibility status is required'),
  sequence: Yup.number().required('Sequence is required')
});

export const productVariantSchema = Yup.object().shape({
  primary_image: Yup.mixed()
    .test(
      'is-valid-file',
      `primary image must be a valid image file or URL`,
      (item) => {
        if (typeof item === 'string') {
          // Allow URLs as valid too
          return Yup.string()
            .url(`primary image must be a valid URL`)
            .matches(/\.(jpeg|jpg|png|webp)$/, `primary image must be a valid image URL`)
            .isValidSync(item);
        } else if (isFileObject(item)) {
          const validFileExtensions = /\.(jpeg|jpg|png|webp)$/;
          return validFileExtensions.test(item.name);
        }
        return false; // Invalid type
      }
    )
    .required(`primary image is required`),
  secondary_image: Yup.array()
    .of(
      Yup.mixed().test(
        'is-valid-file-or-url',
        `secondary image must contain valid image files or URLs`,
        (item) => {
          if (typeof item === 'string') {
            // Validate as a URL
            return Yup.string()
              .url(`secondary image must be a valid URL`)
              .matches(/\.(jpeg|jpg|png|webp)$/, `secondary image must be a valid image URL`)
              .isValidSync(item);
          } else if (isFileObject(item)) {
            // Validate as a file object
            const validFileExtensions = /\.(jpeg|jpg|png|webp)$/;
            return validFileExtensions.test(item.name);
          }
          return false; // Invalid type
        }
      )
    )
    .min(1, `secondary image must have at least one valid image`)
    .required(`secondary image is required`),
  name: Yup.string().required('Product name is required'),
  serial_number: Yup.string().nullable(),
  // product_color: Yup.string().nullable(),
  product_details: Yup.array()
  .of(
    Yup.object().shape({
      product_size: Yup.string().nullable(),
      mrp_price: Yup.number().required('MRP price'),
      sales_price: Yup.number().required('Sales price'),
      stock: Yup.number().required('Stock'),
      product_width: Yup.number().required('Product width'),
      product_height: Yup.number().required('Product height'),
      product_depth: Yup.number().nullable(), // Always optional
      product_weight: Yup.number().required('Product weight'),
    })
  )
  .required('Product details are required')
  .test('is-product-details', 'Product details must have valid values', function (value) {
    console.log(value);
    
    const { productDetailsLength } = this.options.context; // Accessing the context
    if (productDetailsLength === 1) {
      // If length is 1, product_size and product_depth are optional
      return value.every(detail => 
        detail.mrp_price && detail.sales_price && detail.stock && detail.product_width &&
        detail.product_height && detail.product_weight
      );
    } else {
      // If length > 1, product_size is required but product_depth is still optional
      return value.every(detail => 
        detail.product_size && detail.mrp_price && detail.sales_price && detail.stock &&
        detail.product_width && detail.product_height && detail.product_weight
      );
    }
  }),
  additional_info: Yup.string().nullable(),
  description: Yup.string().test('is-description','Description is required',function (value) { return value !== "<p><br></p>"
  }).required('Description is required'),
  specification: Yup.string().test('is-specification','Specification is required',function (value) { return value !== "<p><br></p>"
  }).required('Specification is required'),
  is_visible: Yup.boolean().required('Visibility status is required'),
  // sequence: Yup.number().required('Sequence is required')
});