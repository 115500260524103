const ArticleModel = ({ data, onClose, title }) => {
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-3xl w-full h-[80vh] relative">
        {/* Fixed Header */}
        <div className="sticky top-0 bg-secondaryColor p-4 flex justify-between items-center border-b border-gray-200 z-10">
          <h2 className="text-lg font-bold">{title}</h2>
          <button
            onClick={onClose}
            className=" focus:outline-none"
          >
            X
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="p-5 overflow-y-auto h-[calc(80vh-80px)]">
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleModel;
