import React, { useRef } from 'react';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { HiOutlineDotsVertical } from "react-icons/hi";
import { TiEdit } from "react-icons/ti";
import { MdOutlineDelete } from "react-icons/md";
import './styles.css';

export default function DropdownMenubar({menuItem}) {
    const menuLeft = useRef(null);

    // const items = [
    //     {
    //         label: 'Menu',
    //         items: [
    //             {
    //                 label: 'Edit',
    //                 icon: <TiEdit />,
    //                 command: () => handleEdit()
    //             },
    //             {
    //                 label: 'Delete',
    //                 icon: <MdOutlineDelete />,
    //                 command: () => handleDelete()
    //             }
    //         ]
    //     }
    // ];

    return (
        <div className="card flex justify-between">
            <Menu model={menuItem} popup ref={menuLeft} id="popup_menu_left" className='justify-between' />
            <Button 
                label="" 
                icon={<HiOutlineDotsVertical />} 
                className="mr-2" 
                onClick={(event) => menuLeft.current.toggle(event)} 
                aria-controls="popup_menu_left" 
                aria-haspopup 
            />
        </div>
    );
}
