// import React, { useState, useCallback, useRef, useEffect } from 'react';
// import { GoogleMap, Marker, DirectionsRenderer, useJsApiLoader } from '@react-google-maps/api';
// import store_open from '../../assest/icons/store_open.png'
// import deliveryman_online_with_orders from '../../assest/icons/deliveryman_online_with_orders.png'

// const containerStyle = {
//   width: '100%',
//   height: '92vh',
// };

// const storeLocation = {
//   lat: 22.0690847442456, // Example store coordinates
//   lng: 82.1406660628033,
// };

// // const customerLocation = {
// //   lat: 22.0890847442456, // Example customer coordinates
// //   lng: 82.1406660628033,
// // };

// // const deliverymanLocation = {
// //   lat: 22.0790847442456, // Example deliveryman coordinates
// //   lng: 82.1406660628033,
// // };

// const libraries = ['places', 'drawing'];

// function GoogleMapsView({}) {
//   console.log('storeLocation',storeLocation);

//   const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: 'AIzaSyBu92f4nKc9t8J0Aa17oVOUFlwn659OEGU',
//     libraries,
//   });

//   const [map, setMap] = useState(null);
//   const [directionsResponse, setDirectionsResponse] = useState(null);

//   const mapRef = useRef(null);

//   const onLoad = useCallback((map) => {
//     const bounds = new window.google.maps.LatLngBounds();
//     bounds.extend(storeLocation);
//     // bounds.extend(customerLocation);
//     // bounds.extend(deliverymanLocation);
//     map.fitBounds(bounds);
//     setMap(map);
//     mapRef.current = map;
//   }, []);

//   const onUnmount = useCallback(() => {
//     setMap(null);
//   }, []);

//   useEffect(() => {
//     // if (isLoaded && map) {
//     //   const directionsService = new window.google.maps.DirectionsService();

//     //   directionsService.route(
//     //     {
//     //       origin: storeLocation,
//     //       destination: customerLocation,
//     //       travelMode: window.google.maps.TravelMode.DRIVING,
//     //     },
//     //     (result, status) => {
//     //       if (status === window.google.maps.DirectionsStatus.OK) {
//     //         setDirectionsResponse(result);
//     //       } else {
//     //         console.error(`error fetching directions ${result}`);
//     //       }
//     //     }
//     //   );
//     // }
//   }, [isLoaded, map]);

//   return isLoaded ? (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={storeLocation}
//       zoom={12}
//       onLoad={onLoad}
//       onUnmount={onUnmount}
//     >
//       {/* Store Marker */}
//       <Marker
//         position={storeLocation}
//         // label="Store"
//         icon={{
//             url: store_open, // Path to custom image for deliveryman marker
//             scaledSize: new window.google.maps.Size(50, 50), // Adjust the size of the icon
//           }}
//         />

//       {/* Customer Marker
//       <Marker
//         position={customerLocation}
//         label="Customer"

//       />

//       Deliveryman Marker
//       <Marker
//         position={deliverymanLocation}
//         icon={{
//           url: deliveryman_online_with_orders, // Path to custom image for deliveryman marker
//           scaledSize: new window.google.maps.Size(50, 50), // Adjust the size of the icon
//         }}
//         label="Deliveryman"
//         /> */}

//       {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
//     </GoogleMap>
//   ) : <></>;
// }

// export default React.memo(GoogleMapsView);

import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { TbCurrentLocation } from "react-icons/tb";
import store_open from "../../assest/icons/store_open.png";
import styles from "./googleMap.css";
import { documentApi } from "../../apis/profile.apis";
import { toast } from "react-toastify";
import { json } from "react-router-dom";

const containerStyle = {
  width: "100%",
  height: "92vh",
};

const storeLocation = {
  lat: 22.0690847442456,
  lng: 82.1406660628033,
};

const libraries = ["places", "drawing"];

function GoogleMapsView({getAddress}) {
  // const [latitude,setLatitude]=useState("");
  // const [longitude,setLongitude]=useState("");
  // const [storeLocation,setStoreLocation]=useState("");
  // const handleGetLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         const { latitude, longitude } = position.coords;
  //         setLatitude(latitude);
  //         setLongitude(longitude);
  //         setStoreLocation({
  //           lat: latitude,
  //           lng: longitude,
  //         })
  //         toast.success("Location fetched successfully!");
  //       },
  //       (error) => {
  //         console.error("Error fetching location:", error);
  //         toast.error("Unable to fetch location.");
  //       }
  //     );
  //   } else {
  //     toast.error("Geolocation is not supported by this browser.");
  //   }
  // };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBu92f4nKc9t8J0Aa17oVOUFlwn659OEGU",
    libraries,
    language: "en",
    region: "US",
  });

  const [map, setMap] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [address, setAddress] = useState("");
  const mapRef = useRef(null);

  // Function to get address from latitude and longitude using reverse geocoding
  const getAddressFromLatLng = async (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat, lng }; 
    
    
    try {
      const result = await geocoder.geocode({ location: latLng });
      if (result.results[0]) {
        console.log('latLng',latLng);
        // console.log("Address:", result.results[0].formatted_address);
        getAddress&& getAddress(result.results[0].formatted_address,latLng)
      } else {
        setAddress("Address not found");
        console.warn("No address found");
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };

  const onLoad = useCallback((map) => {
    setMap(map);
    mapRef.current = map;

    // Add a custom button for getting current location
    const locationButtonDiv = document.createElement("div");
    locationButtonDiv.className = "custom-location-button";
    locationButtonDiv.title = "Get Current Location";
    // locationButtonDiv.addEventListener("click", handleGetLocation);
    locationButtonDiv.innerHTML = `<div style="display: flex; align-items: center; justify-content: center; cursor: pointer; background: white; border-radius: 8px; padding: 8px;">
      <span style="font-size: 24px; color: blue;">📍</span>
    </div>`;
    map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(locationButtonDiv);

    locationButtonDiv.addEventListener("click", () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            alert(JSON.stringify(userLocation))
            setCurrentLocation(userLocation);
            map.panTo(userLocation);
            map.setZoom(14);
          },
          (error) => {
            console.error("Error getting current location:", error);
          }
        );
      }
    });

    // Add a click event listener to the map to get location and address
    map.addListener("click", async (e) => {
      const clickedLatLng = e.latLng;
      const selected = {
        lat: clickedLatLng.lat(),
        lng: clickedLatLng.lng(),
      };

      setSelectedLocation(selected);
      console.log("Selected Location Lat,Lng:", selected);

      // Get the address for the clicked location
      await getAddressFromLatLng(selected.lat, selected.lng);
    });
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={storeLocation}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          position={storeLocation}
          icon={{
            url: store_open,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
        />

        {currentLocation && (
          <Marker
            position={currentLocation}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "blue",
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "white",
            }}
            label={{
              text: "You",
              className: "current-location-label",
            }}
          />
        )}

        {selectedLocation && (
          <Marker
            position={selectedLocation}
            icon={{
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 8,
              fillColor: "red",
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "white",
            }}
            label={{
              text: "Selected",
              className: "selected-location-label",
            }}
          />
        )}
      </GoogleMap>

      {selectedLocation && (
        <div className="selected-location-info">
          <p>Selected Location:</p>
          <p>Latitude: {selectedLocation.lat}</p>
          <p>Longitude: {selectedLocation.lng}</p>
          <p>Address: {address}</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapsView);
