import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { PiSquaresFour } from "react-icons/pi";
import { TfiMenuAlt } from "react-icons/tfi";
import Button, { SecondaryButton } from "../Button";
import CalendarComp from "../CalendarComp/CalendarComp";
import FilterDropdown from "../Dropdown/FilterDropdown";
import SelectDropdown from "../Dropdown/SelectDropdown";
import WeekRangeDropdown from "../Dropdown/WeekRange";
import styles from '../Table/Styles.module.css';

const FiltersComp = ({
  layoutView,
  SearchBy,
  handleSearch,
  handleFilter,
  handleClearFilter,
  filters,
  WeekRange,
  MonthRange,
  canlenderFilter
}) => {

  const [gridToggle, SetGridToggle] = useState(false);

  

  return (
    <>
      <div className={styles.subcontainer1}>
        {layoutView ? (
          <>
            <div
              onClick={() => SetGridToggle(false)}
              style={{ cursor: "pointer" }}
            >
              <TfiMenuAlt
                size={"1.3rem"}
                color={gridToggle ? "black" : "#145388"}
              />
            </div>
            <div
              onClick={() => SetGridToggle(true)}
              style={{ cursor: "pointer" }}
            >
              <PiSquaresFour
                size={"1.9rem"}
                color={!gridToggle ? "black" : "#145388"}
              />
            </div>
          </>
        ) : (
          ""
        )}
       
        {SearchBy.options.length ? (
          <SelectDropdown
            inputName={SearchBy.inputName}
            placeholder={SearchBy.filterType}
            options={SearchBy.options}
            handleFilter={handleFilter}
          />
        ) : null}

        {SearchBy.options.length ? (
          <div className="border rounded-full px-3 py-1.5 text-sm w-fit flex justify-between items-center">
            <input
              type="text"
              className=" outline-none"
              placeholder="Search"
              onChange={(e)=>{ 
                // console.log("search",e.target.value);                
                handleSearch && handleSearch(e.target.value)
              }}
            />
            <CiSearch size={"1.1rem"} />
          </div>
        ) : null}

      {filters &&
          filters.map((f, i) => (
            <FilterDropdown
              key={i}
              inputName={f.inputName}
              label={f.filterType}
              options={f.options}
              handleFilter={handleFilter}
            />
          ))}
          
        {WeekRange ? <WeekRangeDropdown handleFilter={handleFilter} /> : ""}
        {MonthRange ? <CalendarComp  canlenderFilter={canlenderFilter} handleFilter={handleFilter} /> : ""}

        {SearchBy.options.length ? (
          <>   
            <SecondaryButton onClick={()=>handleClearFilter && handleClearFilter()} text={"Clear Filter"} type={"submit"} padding={'py-2 px-3'}/>
            {/* <Button text={'Apply'} type={'submit'}/> */}
          </>
        ) : null}
      </div>
    </>
  );
};

export default FiltersComp;


