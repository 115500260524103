import React, { useState } from "react";

const VariantPage = ({ product, handleVariantSelected }) => {
  const [hoveredImage, setHoveredImage] = useState(
    product?.primary_image || ""
  );

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { product_details } = product || {};

  return (
    <div
      className="bg-white shadow-lg h-[30rem] rounded-lg overflow-hidden w-full md:w-80 lg:w-96 cursor-pointer"
      onClick={() => {
        if (product) {
          handleVariantSelected(product);
          scrollToTop();
        }
      }}
    >
      {/* Image Section */}
      <div className="relative h-60 w-full overflow-hidden">
        <img
          src={hoveredImage}
          alt={product?.name || "Product Image"}
          className=" w-full h-full object-contain hover:scale-105 transition-transform duration-300 ease-in-out object-top"
        />
        {/* Secondary Image Hover */}
        <div className="absolute bottom-0 left-0 flex space-x-2 p-2">
          {product?.secondary_image?.map((image, index) => (
            <img
              key={index}
              src={product.created_by ? image.image : image}
              alt={`Secondary image ${index}`}
              className="w-10 h-10 object-cover object-top rounded-full cursor-pointer hover:ring-2 hover:ring-gray-300 transition-all"
              onMouseEnter={() =>
                setHoveredImage(product.created_by ? image.image : image)
              }
              onMouseLeave={() => setHoveredImage(product.primary_image)}
            />
          ))}
        </div>
      </div>

      {/* Product Info Section */}
      <div className="p-4">
        <h2 className="text-lg font-semibold line-clamp-2">
          {product?.name || "Product Name"}
        </h2>
        <p
          className="text-sm text-gray-500 mt-2 line-clamp-3"
          dangerouslySetInnerHTML={{
            __html: product?.description || "No description available",
          }}
        ></p>

        {/* Price Section */}
        <div className="mt-4 flex items-center justify-between">
          <span className="text-xl font-bold text-blue-500">
            ₹
            {parseFloat(
              product_details?.[0]?.sales_price?.$numberDecimal || 0
            ).toLocaleString()}
          </span>
          <span className="line-through text-gray-500">
            ₹
            {parseFloat(
              product_details?.[0]?.mrp_price?.$numberDecimal || 0
            ).toLocaleString()}
          </span>
        </div>

        {/* Stock and Button */}
        <div className="mt-4 flex justify-between items-center">
          <span
            className={`text-sm ${
              product_details?.[0]?.stock > 0
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {product_details?.[0]?.stock > 0
              ? `${product_details?.[0]?.stock} in stock`
              : "Out of stock"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default VariantPage;
