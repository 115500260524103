import { user_role } from "../config/apiConfig";
import Api from "../utils/axios";

const AuthApi = {
  register: (payload) => Api.post(`auth/register`, payload),
  login: (payload) => Api.post(`auth/login/${user_role.vendor}`, payload),
  loginWithGoogleProvider: (payload) =>
    Api.post(`auth/login/${user_role.vendor}`, payload),
  updateUser: (payload) =>
    Api.patch(`auth/update-account`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateCoverImage: (payload) =>
    Api.patch(`auth/update-cover-image`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  currentUser: () => Api.get(`auth/current-user`),
  generateAccessToken: (payload) => Api.post("auth/refresh-token", payload),
  logoutUser: () => Api.post("auth/logout"),

  generateOtp: (payload) => Api.post("auth/generate-otp", payload),
  verfiryOtp: (payload) => Api.post("auth/verify-otp", payload),
  resetPassword: (payload) => Api.post("auth/reset-password", payload),
  referralCode: (payload) => Api.post(`auth/check-referral-code`, payload),
  // change-password
};

export default AuthApi;
