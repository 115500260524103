import React, { useEffect, useState } from "react";
import { img } from "react-lazy-load-image-component";

const ImageGallery = ({ productData, selectedVariant }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (selectedVariant && selectedVariant.primary_image) {
      setSelectedImage(selectedVariant.primary_image);
    } else if (productData && productData.primary_image) {
      setSelectedImage(productData.primary_image);
    }
  }, [productData, selectedVariant]);

  const images = selectedVariant
    ? [
        selectedVariant?.primary_image,
        ...selectedVariant.secondary_image.map((image) => image),
      ]
    : [
        productData?.primary_image,
        ...productData.secondary_image.map((image) => image.image),
      ];

  const handleMouseHover = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left - window.scrollX) / width) * 100;
    const y = ((e.pageY - top - window.scrollY) / height) * 100;
    setPosition({ x, y });
  };

  return (
    <div className="flex ">
      <div className="flex flex-col gap-3 pr-10 ">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            effect="blur"
            alt={`product-thumbnail-${index}`}
            className={`w-[75px] h-[75px] object-contain cursor-pointer  ${
              selectedImage === image ? "border-2 border-blue-600" : ""
            }`}
            onMouseEnter={() => setSelectedImage(image)}
          />
        ))}
      </div>
      <div
        onMouseEnter={() => setShowMagnifier(true)}
        onMouseLeave={() => setShowMagnifier(false)}
        onMouseMove={handleMouseHover}
        className=" flex flex-col w-full h-full cursor-crosshair "
      >
        <img
          src={selectedImage}
          effect="blur"
          alt="Selected product"
          className="w-full h-[40rem] xl:h-full object-contain  "
          placeholderSrc="https://via.placeholder.com/300"
        />

        {showMagnifier && selectedImage && (
          <div
            className="absolute hidden xl:block pointer-events-none shadow-lg border-2 border-gray-300 top-28 rounded-sm right-8 "
            style={{
              width: "50%",
              height: "85vh",
              border: "1px solid gray",
              // top: `${position.y - 75}px`,
              // left: `${position.x - 75}px`,
              backgroundImage: `url(${selectedImage})`,
              backgroundSize: "150%",
              backgroundPosition: `${position.x}% ${position.y}%`,
              backgroundRepeat: "no-repeat",
              zIndex: 10,
              backgroundColor: "white",
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
