import { useFormikContext } from "formik";
import React, { useState } from "react";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const ToggleField = ({ text, name, value, toggaleHandleChange }) => {
  const { setFieldValue } = useFormikContext();

  const [toggle, setToggle] = useState(value);

  const handleClick = (e) => {
    e.target.name = name;
    e.target.value = !toggle;
    setToggle(!toggle);
    console.log("target", toggle);
    toggle ? setFieldValue(name, false) : setFieldValue(name, true);
    toggaleHandleChange && toggaleHandleChange(e);
  };

  return (
    <label
      className="flex gap-2 cursor-pointer "
      htmlFor="toggle"
      onClick={handleClick}
    >
      {toggle ? (
        <FaToggleOn className=" hover:text-hoverColor text-xl" id="toggle" />
      ) : (
        <FaToggleOff className=" hover:text-hoverColor text-xl" id="toggle" />
      )}
      {text}
    </label>
  );
};

export default ToggleField;
