import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdDeleteOutline, MdDeleteForever } from "react-icons/md";

const Card = ({ item, handleProductClick, openProductDeletModal }) => {
  const { theme } = useSelector((state) => state.theme);
  const [mouseEnter, setMouseEnter] = useState(false);
  console.log("item:  ",{item})
  return (
    <div
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
      className={`${
        theme === "light" ? "bg-secondaryColor" : "bg-darkThirsary"
      } 
        
        relative w-70 h-50  text-center rounded overflow-hidden shadow-lg gap-4 px-3 py-3`}
    >
      {mouseEnter && (
        <i
          className=" absolute right-3 top-2   p-2 rounded-full cursor-pointer"
          // onClick={() => {
          //   setDeleteId(item._id);
          //   setDeleteModalOpen(true);
          // }}
        >
          <MdDeleteForever
            onClick={() => openProductDeletModal("product", item)}
            className=" text-2xl  text-red-500 "
          />
        </i>
      )}

      <img
        onClick={() => handleProductClick(item)}
        src={item.primary_image}
        alt="img"
        className="w-28 h-24 object-fit inline-block vertical-align cursor-pointer"
      />

      <p
        onClick={() => handleProductClick(item)}
        className="font-bold cursor-pointer text-black  text-l mb-2"
      >
        {item.name}
      </p>
      <p
        onClick={() => handleProductClick(item)}
        className=" cursor-pointer line-clamp-2 text-justify text-base"
      >
        {item.description}
      </p>
    </div>
  );
};

export default Card;

//shadow-2xl cursor-pointer flex items-center gap-5  rounded-xl w-full px-3 py-5
