import React, { useState } from 'react'
import Profile from '../Account/Profile'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Topbar from '../../components/Topbar';

function ApprovalCheck() {
    const accessToken = localStorage.getItem("accessToken");
    const { token,currentUser } = useSelector((state) => state.store);
    const [sidebar, setSidebar] = useState(true);
    const [subSidebar, setSubSidebar] = useState(false);
  

  const handleSidebar = () => {
    if (subSidebar) {
      setSubSidebar(false);
    }
    if (!subSidebar) {
      setSidebar(false);
    }
    if (!sidebar) {
      setSidebar(true);
    }
  };

    if (!accessToken && !token) {
        return <Navigate to={"/"} />;
      }
    
    return (
        <div>
            <Topbar handleSidebar={handleSidebar} /> 
            <Profile />
        </div>
    )
}

export default ApprovalCheck