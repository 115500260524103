
import React from "react";

const ExtraDetailsPage = ({ productData, selectedVariant }) => {
  const specification = selectedVariant
    ? selectedVariant?.specification
    : productData?.specification;

  const additionalInfo = selectedVariant
    ? selectedVariant?.additional_info
    : productData?.additional_info;

  return (
    <div className="mt-8">
      <div className="mt-8 mb-8">
        <h2 className="text-2xl font-semibold mb-2">Product Description</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedVariant?.description
              ? selectedVariant?.description
              : productData?.description || "<p>No description available.</p>",
          }}
          className="text-sm text-gray-700"
        ></div>
      </div>
      {specification && additionalInfo && (
        <p className="text-4xl font-bold mb-8 ">Important Informations</p>
      )}
      {/* {!specification && !additionalInfo && <p></p>} */}

      {specification && (
        <div className="mb-4">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2 ">
            Specifications:
          </h2>
          <div
            className="text-sm text-gray-700"
            dangerouslySetInnerHTML={{ __html: specification }}
          ></div>
        </div>
      )}

      {additionalInfo && (
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">
            Additional Information:
          </h2>
          <div
            className="text-sm text-gray-700"
            dangerouslySetInnerHTML={{
              __html:
                additionalInfo || "<p>No additional information available.</p>",
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default ExtraDetailsPage;
