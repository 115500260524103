export const user_role = {
  admin: 1,
  vendor: 2,
  deliveryman: 3,
  customer: 4,
};

// Local
const API_CONFIG_LOCAL = {
  baseURL: "http://192.168.29.39:5000/api/v1/",
};

// live
export const API_CONFIG_LIVE = {
  baseURL: "https://edelivery.nablean.com/api/v1/",
};

const getServer = (isLive) => {
  if (isLive) {
    return API_CONFIG_LIVE.baseURL;
  } else {
    return API_CONFIG_LOCAL.baseURL;
  }
};

const isLive = true;

export const server = getServer(isLive);
