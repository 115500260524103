import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDcXF5Ri_DPJPVPLUSU7O94acY03XAOql0",
  authDomain: "e-delivery-a0a51.firebaseapp.com",
  projectId: "e-delivery-a0a51",
  storageBucket: "e-delivery-a0a51.appspot.com",
  messagingSenderId: "215449653167",
  appId: "1:215449653167:web:bf22b1fc5e1603481c695a",
  measurementId: "G-KB5GVPFKQY",
};

const app = initializeApp(firebaseConfig);
let messaging;

const isClient = typeof window !== "undefined";

if (isClient && "serviceWorker" in navigator) {
  messaging = getMessaging(app);
}
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const db = getFirestore(app);

export { db };

export {
  auth,
  googleProvider,
  facebookProvider,
  messaging,
  getToken,
  onMessage,
};
