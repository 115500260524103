import React, { useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import OrderComp from "./OrderComp.jsx";

const AllOrder = () => {
  const [pagination,setPagination]=useState({
    "page": 1,
    "limit": 5,
    "sortOrder": "desc",
    "filter": {}
  })
  const handlePagination=(data)=>{
    console.log(data);
    if (data.clearPagination) {
      setPagination(data.clearPagination)
      return
    }
    setPagination((prev)=>{
        let copyfilter={...prev.filter}
        // copyfilter[]
       
        if (data.filter) {
          // console.log("Object.keys[copyfilter]",Object.entries(copyfilter))
          // console.log("Object.keys[copyfilter]",Object.keys(data.filter))
          
          if (copyfilter[Object.keys(data.filter)[0]]) {
            copyfilter[Object.keys(data.filter)[0]]=Object.values(data.filter)[0]
            
          }else{

            copyfilter={...data?.filter,...copyfilter}
          }
        
        }

        
  
        return{
          page:data.page?data.page:prev.page,
          limit:data.limit?data.limit:prev.limit,
          sortOrder:data.sortOrder?data.sortOrder:prev.sortOrder,
          filter:copyfilter
        }
  
      })
    }
  

  return (
    <div className="p-5">
      <BreadCrumb title={"All Order"} />
      {/* <BasicTable
        data={value?.data?.orders}
        // data={[]}
        columns={columns}
        SearchBy={searchBy}
        filters={filters}
        WeekRange={false}
        MonthRange={true}
        handleRowClick={(row) => handleRowClick(row)}
      /> */}
       <OrderComp pagination={pagination} handlePagination={handlePagination} />
    </div>
  );
};

export default AllOrder;

// {
//   "title": "Current Week",
//   "path": "history/current-week",
//   "icon": "SlCalender"
// },
// {
//   "title": "Current Month",
//   "path": "history/current-month",
//   "icon": "VscCalendar"
// },

/*
"submenu": [
      {
        "icon": "IoMdCart",
        "title": "Create Order",
        "path": "order/create-order"
      }
    ],
 */
