import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import {lang} from './index.js'

 const currentLang=localStorage.getItem("lang") && localStorage.getItem("lang")!==undefined?localStorage.getItem("lang"):"en"
console.log("currentlang",currentLang);


i18n.use(initReactI18next).init({
 resources: {lang }, 
 lng:currentLang||"en",   
});