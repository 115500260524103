import React from "react";
import { Carousel } from "primereact/carousel";
import VariantPage from "../products/VariantsPage";
 
export default function PrimeCarousel({ variants, handleVariantSelected }) {
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
 
  const variantTemplate = (variant) => {
    return (
      <VariantPage
        key={variant._id}
        product={variant}
        handleVariantSelected={handleVariantSelected}
      />
    );
  };
 
  return (
    <div className="card custom-carousel">
      <Carousel
        value={variants}
        numScroll={1}
        numVisible={3}
        responsiveOptions={responsiveOptions}
        itemTemplate={variantTemplate}
      />
    </div>
  );
}