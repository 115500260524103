import { useState, useEffect, useContext } from "react";
import axios from "axios";
import LoaderContext from "../components/loader";



// Hook for fetching all data
export const useFetchAll = (fetchFunc) => {
  const [value, setValue] = useState([]);
  const { handleLoading,isLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  // const [isCaching, setIsCaching] = useState(false);

  const fetchAllData = async () => {
    // if (isCaching) return;
    handleLoading(true);
    try {
      const response = await fetchFunc();
      console.log('response',response);
      
      setValue(response.data)
      // setIsCaching(true);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  },[]);

  return { value,handleLoading,isLoading, error, fetchAllData };
};

// Hook for fetching single data by ID
export const useFetchSingle = (url, id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${url}/${id}`);
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, id]);

  return { data, loading, error };
};
