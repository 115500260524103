import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const accessToken = localStorage.getItem("accessToken");

const ProtectedRoute = ({ children }) => {
  const { token,currentUser } = useSelector((state) => state.store);

  if (!accessToken && !token) {
    return <Navigate to={"/"} />;
  }
  return Boolean(currentUser?.status==="Unapproved"||currentUser?.status==='Unapprove')?<Navigate to={"/store/approval-check"} />: children;
};

export { ProtectedRoute };

const AuthProtected = ({ children }) => {
  const { token,currentUser} = useSelector((state) => state.store);
console.log("token++++++++",token);  

  if (token) {
    return Boolean(currentUser?.status==="Unapproved"||currentUser?.status==='Unapprove')?<Navigate to={"/store/approval-check"} />: <Navigate to={"store/dashboard"} />;
  }
  
  return children;
};

export { AuthProtected };
