import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const FoodCard = ({ openProductDeletModal, handleProductClick, data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    primary_image,
    name,
    rating,
    subCategory,
    price,
    additional_info,
    description,
  } = data;

  const ratingStars = Array.from({ length: 5 }, (_, index) => (
    <FaStar
      key={index}
      className={
        index < Math.round(rating) ? "text-yellow-400" : "text-gray-300"
      }
    />
  ));

  return (
    <div
      className="max-w-sm bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl"
      onClick={() => handleProductClick(data)} // Pass item as the data
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Image Section */}
      <div className="relative bg-gray-200 h-56 w-full">
        <img
          src={primary_image}
          alt={name}
          className="object-cover h-full w-full"
        />
        {/* Category Tag */}
        <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full">
          {subCategory?.name}
        </span>
        {/* Delete Button */}
        {isHovered && (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering card click event
              openProductDeletModal(data); // Call delete modal function
            }}
            className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
          >
            <MdDelete size={20} />
          </button>
        )}
      </div>

      {/* Content Section */}
      <div className="px-3 py-4">
        {/* Food Name */}
        <h3 className="text-2xl font-semibold text-gray-800">{name}</h3>

        {/* Rating and Price */}
        <div className="flex justify-between items-center mt-2">
          {/* Price */}
          <div className="text-lg font-semibold text-gray-600">
            {/* <span className="line-through text-red-500 mr-2">₹{}</span> */}
            <span className="text-[#e34e4e]">₹{price?.$numberDecimal}</span>
          </div>
          {/* Rating */}
          <div className="flex items-center">
            {ratingStars}
            <span className="text-gray-500 text-sm ml-1">({rating})</span>
          </div>
        </div>

        {/* Additional Info */}
        <div
          className="mt-4 text-gray-600"
          dangerouslySetInnerHTML={{ __html: additional_info }}
        />

        {/* Description */}
        <div
          className="mt-2 text-gray-500"
          dangerouslySetInnerHTML={{
            __html: description.substring(0, 150) + "...",
          }}
        />

        {/* Policies Section */}
      </div>
    </div>
  );
};

export default FoodCard;
