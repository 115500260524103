import { ErrorMessage, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/Button";
import { MultiSelectDropDown } from "../../components/Dropdown";
import { FormField } from "../../components/FormField";
import SidebarField from "../../components/Sidebar/SidebarField";
import TextInput from "../../components/TextInput";
import ToggleField from "../../components/ToggleField";
import ToggleRightField from "../../components/ToggleRightField";
import { FaSearch } from "react-icons/fa";
import Lable from "../../components/Lable";
import { CategoryApi, SubCategoryApi } from "../../apis/ProductListing.apis";
import LoaderContext from "../../components/loader/LoaderContext";
import { CgArrowTopRightO } from "react-icons/cg";
import { MdDeleteOutline } from "react-icons/md";
import DeleteFieldModal from "../../components/DeleteFieldModal";
import { SubCategoryForm } from "./ItemList";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useErrorBoundary } from "react-error-boundary";
import PaginatorCom from "../../components/Paginator/PaginatorCom";

const Category = () => {
  const { currentUser } = useSelector((state) => state.store);
  console.log("currentUser", currentUser);
  const debounceTimeout = useRef(null);
  const [visible, setVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const { handleLoading } = useContext(LoaderContext);
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryPagination, setCategoryPagination] = useState({
    page: 1,
    limit: 8,
    sortOrder: "desc",
    filter: {
      categoryType:
        currentUser?.delivery_business?.name === "Restourant "
          ? "Food"
          : "Ecommerce",
    },
    totalPages: 1,
    totalCount: 5,
  });
  const [currentItems, setCurrentItems] = useState();
  const [subcategoryModal, setSubcategoryModalOpen] = useState(false);
  const [subCategoryFields, setSubCategoryFields] = useState([]);
  const [val, setVal] = useState([]);
  const { showBoundary } = useErrorBoundary();
  // const [editData, setEditData] = useState("");

  const id = currentItems?._id;

  const initialValues = {
    name: currentItems?.name || "",
    image: currentItems?.image || null,
    sequence: currentItems?.sequence || "",
    is_visible: currentItems?.is_visible || false,
    categoryType:
      currentUser?.delivery_business?.name === "Restourant "
        ? "Food"
        : "Ecommerce",
    subCategories: (() => {
      const currentSubCategoryIds =
        currentItems?.subCategories.map((item) => item._id) || [];

      // Scenario 1: val has values, and both currentItems and subCategoryFields have data
      if (
        val.length > 0 &&
        currentSubCategoryIds.length &&
        subCategoryFields.length
      ) {
        return [
          ...new Set([...val, ...currentSubCategoryIds, ...subCategoryFields]),
        ];
      }

      // Scenario 2: val is empty, but both currentItems and subCategoryFields have data
      if (
        val.length === 0 &&
        currentSubCategoryIds.length &&
        subCategoryFields.length
      ) {
        return [...new Set([...currentSubCategoryIds, ...subCategoryFields])];
      }

      // Scenario 3: Only currentItems have subcategories
      if (currentSubCategoryIds.length) {
        return currentSubCategoryIds;
      }

      // Scenario 4: Only subCategoryFields and val have data
      if (subCategoryFields.length && val.length > 0) {
        return [...new Set([...val, ...subCategoryFields])];
      }

      // Scenario 5: Only subCategoryFields have data
      if (subCategoryFields.length) {
        return subCategoryFields;
      }

      // Default case: return an empty array
      return [];
    })(),
  };

  const handleCategoryPagination = (data) => {
    console.log("data", data);
    if (data.clearPagination) {
      setCategoryPagination(data.clearPagination);
      return;
    }

    setCategoryPagination((prev) => {
      let copyPagination = { ...prev.filter };

      if (data.filter) {
        Object.keys(data?.filter).forEach((key, i) => {
          copyPagination[key] = data?.filter[key];
        });
      }

      return {
        page: data.page ? data.page : 1,
        limit: data.limit ? data.limit : 5,
        sortOrder: data.sortOrder ? data.sortOrder : "desc",
        filter: copyPagination,
      };
    });
  };

  const subCategoryOptions = async () => {
    handleLoading(true);
    try {
      // const res = await SubCategoryApi.getSubCategory();
      // setSubCategoriesOptions(res.data?.data);
    } catch (err) {
      console.log(err);
      showBoundary(err);
    } finally {
      handleLoading(false);
    }
  };

  const fetchingCategoryItems = async () => {
    handleLoading(true);
    try {
      const res = await CategoryApi.getCategory(
        categoryPagination,
        searchQuery
      );
      const resData = res.data?.data;
      // console.log("resData: ", resData);
      // const data =
      //   currentUser?.delivery_business?.name === "Restourant "
      //     ? resData?.categories?.filter((item) => item?.categoryType === "Food")
      //     : resData?.categories?.filter(
      //         (item) => item?.categoryType === "Ecommerce"
      //       );
      //     data.categories=data
      // console.log("data filter", data);
      setCategoryData(resData);
    } catch (err) {
      console.log(err);
      showBoundary(err);
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    subCategoryOptions();
  }, []);
  useEffect(() => {
    fetchingCategoryItems();
  }, [categoryPagination, searchQuery]);

  const handleClose = () => {
    setFile(null);
    setPreview(null);
    setVisible(false);
    setCurrentItems();
    setSubCategoryFields([]);
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log("valuese", values);
    handleLoading(true);

    try {
      const res = id
        ? await CategoryApi.updateCategory(values, id)
        : await CategoryApi.createCategory(values);
      console.log(values);
      // console.log(res.data?.data);
    } catch (err) {
      console.log(err);
      showBoundary(err);
    } finally {
      handleLoading(false);
      resetForm();
      fetchingCategoryItems();
      handleClose();
    }
  };

  const handleOpen = (item) => {
    setCurrentItems();
    if (item) {
      setCurrentItems(item);
      setPreview(item?.image);
    }
    // setVisible(true);
  };

  const handleSearch = useCallback((s) => {
    // console.log('Search at OrderCom', s);
    setSearchQuery(s);
  }, []);

  const debouncedSearch = useCallback(
    (s) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        handleSearch(s.target.value);
      }, 1500);
    },
    [handleSearch]
  );
  const handleFileUpload = (e, setFieldValue) => {
    const f = e.target.files[0];
    setFile(f);
    setFieldValue("image", f);

    if (f) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result);
      };

      reader.readAsDataURL(f);
    }
  };

  // subcategory modal functions added here

  // const handleCloseSubcategoryModal = () => {
  //   setSubcategoryModalOpen(false);
  //   setVisible(true);
  // };

  const handleSubmitSubcategoryModal = async (values, { resetForm }) => {
    handleLoading(true);
    try {
      const createRes = await SubCategoryApi.createSubCategory(values);
      console.log(createRes.data?.data);
      const newSubCategory = createRes.data?.data._id;
      setSubCategoryFields((prevFields) => [...prevFields, newSubCategory]);
    } catch (err) {
      console.log(err);
      showBoundary(err);
    } finally {
      handleLoading(false);
      resetForm();
      // handleCloseSubcategoryModal();
      subCategoryOptions();
    }
  };

  return (
    <div className="p-5 w-[100%] ">
      <div className="flex justify-between items-center flex-wrap mb-2">
        <BreadCrumb title="Category" />
        <Button
          text="Add New"
          type="button"
          className="float-end"
          onClick={() => {
            setVisible(true);
            setCurrentItems();
            subCategoryOptions();
          }}
        />
      </div>
      <div className="mb-5 border-b pb-2">
        <div className="border rounded-full px-3 py-1.5 text-xs bg-transparent w-fit flex justify-between items-center">
          <input
            type="search"
            placeholder="search..."
            // value={searchQuery}
            onChange={debouncedSearch}
            className="bg-transparent outline-none"
          />
          <FaSearch />
        </div>
      </div>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
        {categoryData?.categories?.map((item) => (
          <Card
            item={item}
            handleOpen={handleOpen}
            fetchingCategoryItems={fetchingCategoryItems}
            handleClose={handleClose}
          />
        ))}
      </div>

      {visible && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ handleChange, values, handleSubmit, setFieldValue }) => (
            <SidebarField
              handleClose={handleClose}
              title={id ? `Update Category` : `Add New Category`}
              button1={
                <Button text="Cancel" type="button" onClick={handleClose} />
              }
              button2={<Button text="Submit" onClick={handleSubmit} />}
            >
              <Form className="w-full gap-4 flex flex-col bg-transparent py-5 px-3">
                <FormField label="Category Name" tooltip="enter your category">
                  <div className="flex items-center">
                    <span className="border p-2 pl-3 pr-5 text-sm border-r-none">
                      EN
                    </span>
                    <TextInput
                      name="name"
                      type="text"
                      placeholder="Category"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <ErrorMessage name="name" component="div" className="error" />
                </FormField>

                {/* {preview ? (
                  <LazyLoadImage
                    src={preview}
                    alt="category-item"
                    className="w-full h-[50%] "
                    effect="blur"
                    wrapperProps={{
                      style: { transitionDelay: "1s" },
                    }}
                  />
                ) : (
                  <LazyLoadImage
                    src="https://storeedelivery.elluminatiinc.net/assets/img/default_images/category.png"
                    alt="category-item"
                    effect="blur"
                    wrapperProps={{
                      style: { transitionDelay: "1s" },
                    }}
                  />
                )}

                <span className="text-[12px] text-gray-400 my-10 flex w-full">
                  <label htmlFor="image-upload" className="w-full flex ">
                    <TextInput
                      name="image"
                      type="file"
                      placeholder="Choose File"
                      id="image-upload"
                      onChange={(e) => handleFileUpload(e, setFieldValue)}
                    />
                    <button
                      className="border px-5 cursor-pointer z-5 "
                      type="file"
                      id="image-upload"
                    >
                      Browse
                    </button>
                  </label>
                </span> */}

                {/* <FormField
                  label="Sub Category"
                  tooltip="choose your subcategory"
                >
                  <div className=" flex align-items-center justify-between w-full gap-4  ">
                    <MultiSelectDropDown
                      options={subCategoriesOptions}
                      name="subCategories"
                      value={values.subCategories}
                      setVal={setVal}
                    />
                    <ErrorMessage
                      name="subCategories"
                      component="div"
                      className="error"
                    />
                    <CgArrowTopRightO
                      className=" text-4xl  cursor-pointer"
                      title="Add Subcategory"
                      onClick={() => {
                        setSubcategoryModalOpen(true);
                      }}
                    />
                  </div>
                </FormField> */}
                <FormField
                  label="Sequence Number"
                  tooltip="enter your sequence number"
                >
                  <TextInput
                    name="sequence"
                    type="number"
                    value={values.sequence}
                    onChange={handleChange}
                  />
                </FormField>
                {/* <ToggleField
                  text="Is category visible to user?"
                  name="is_visible"
                  value={values.is_visible}
                /> */}
                {/* <Lable lable={"Category Time Settings"} />
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((item) => (
                  <ToggleRightField
                    text={item}
                    subtext={"Open Full Day"}
                    border={true}
                    padding={true}
                    subtextoption={true}
                  />
                ))} */}
              </Form>
            </SidebarField>
          )}
        </Formik>
      )}

      {subcategoryModal && (
        <SubCategoryForm
          handleSubmit={handleSubmitSubcategoryModal}
          handleClose={() => setSubcategoryModalOpen(false)}
          initialValues={{
            name: "",
            sequence: "",
            is_visible: false,
          }}
        />
      )}

      <PaginatorCom
        paginatorData={{
          page: categoryData?.page ? categoryData?.page : 1,
          rows: categoryData?.limit ? categoryData?.limit : 5,
          totalPages: categoryData?.totalPages ? categoryData?.totalPages : 1,
          totalCount: categoryData?.totalCount ? categoryData?.totalCount : 1,
        }}
        onPaginatorClick={(e) => {
          if (e) {
            const data = {
              limit: e.rows,
              page: +e.page + 1,
            };
            handleCategoryPagination(data);
          }
        }}
      />
    </div>
  );
};

export default Category;

const Card = ({ item, handleOpen, fetchingCategoryItems, handleClose }) => {
  const { theme } = useSelector((state) => state.theme);
  const [deleteId, setDeleteId] = useState();
  const { handleLoading } = useContext(LoaderContext);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const deleteApiFunc = async () => {
    handleLoading(true);
    try {
      await CategoryApi.deleteCategory(deleteId);
    } catch (err) {
      console.log(err);
      showBoundary(err);
    } finally {
      handleLoading(false);
      fetchingCategoryItems();
      setDeleteModalOpen(false);
    }
  };

  return (
    <main className="relative group">
      {/* <i
        className="absolute right-3 top-2 p-2 rounded-full cursor-pointer bg-red-600 bg-opacity-70 hover:bg-opacity-100 transition-all duration-300 group-hover:z-20 border border-gray-200 "
        onClick={() => {
          setDeleteId(item._id);
          setDeleteModalOpen(true);
        }}
      >
        <MdDeleteOutline className=" text-3xl text-white " />
      </i> */}

      <div
        className={`rounded-xl shadow-2xl overflow-hidden transition-transform transform group-hover:scale-105 cursor-pointer ${
          theme === "light"
            ? "bg-secondaryColor text-textColorLight"
            : "bg-darkThirsary text-darkSecondary"
        }`}
        onClick={() => {
          handleClose();
          setTimeout(() => {
            handleOpen(item);
          }, [200]);
        }}
      >
        <div className="h-[15rem] overflow-y-hidden">
          <LazyLoadImage
            src={
              item.image
                ? item.image
                : "https://storeedelivery.elluminatiinc.net/assets/img/default_images/category.png"
            }
            alt="category_image"
            className="rounded-t-xl h-4 object-cover overflow-hidden"
            effect="blur"
            wrapperProps={{
              style: { transitionDelay: "0.1s" },
            }}
          />
        </div>
        <h1 className="h-[5rem] p-4 text-lg font-semibold capitalize tracking-wide line-clamp-1 ">
          {item.name}
        </h1>
      </div>

      {deleteModalOpen ? (
        <DeleteFieldModal
          isOpen={true}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={() => deleteApiFunc()}
        />
      ) : null}
    </main>
  );
};
