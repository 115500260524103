import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageGallery from "./ImageGallary";
import ProductInfo from "./ProductInfo";
import ExtraDetailsPage from "./ExtraDetailsPage";
import ReviewsSection from "./ReviewsSection";
import { productApi } from "../../apis/ProductListing.apis";
import LoaderContext from "../loader";
import PrimeCarousel from "../ImageCarousel/PrimeCarousel";

const ProductPage = () => {
  const { productId } = useParams(); // Getting productId from the route params
  const [productData, setProductData] = useState();
  const [reviewData, setReviewData] = useState();
  const { handleLoading } = useContext(LoaderContext);
  const [data, setData] = useState([]);
  const [reviewPage, setReviewPage] = useState(1);
  const [variant, setVariant] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState();
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  // Fetching variant ID from query parameters
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  const variantId = getQueryParam("variant");

  // Fetching single product data
  const fetchSingleProduct = async () => {
    handleLoading(true);
    if (productId) {
      try {
        const res = await productApi.getSingleProduct(productId);
        const { product, product_varient } = res.data.data;
        setVariant([product, ...product_varient]);
        setProductData(product);
      } catch (err) {
        console.log(err);
      } finally {
        handleLoading(false);
      }
    }
  };

  // Fetching product reviews
  const fetchSingleProductReview = async () => {
    handleLoading(true);
    const payload = {
      page: reviewPage,
      limit: 10,
      sortOrder: "desc",
      filter: {},
    };
    if (productId) {
      try {
        const res = await productApi.getReviewForProduct(productId, payload);
        setReviewData(res.data?.data?.reviews);
        setData(res.data?.data);
      } catch (err) {
        console.log(err);
      } finally {
        handleLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchSingleProductReview();
  }, [reviewPage]);

  useEffect(() => {
    fetchSingleProduct();
    fetchSingleProductReview();
  }, [productId]);

  const handleVariantSelected = (selectedVariant) => {
    setSelectedColor(null);
    setSelectedSize(null);
    if (selectedVariant && !selectedVariant.created_by) {
      setSelectedVariant(selectedVariant);
    } else {
      setSelectedVariant(null);
    }
  };

  if (!productData || !reviewData) {
    return <div className="h-[100vh]">loading....</div>;
  }

  return (
    <div className="container mx-auto px-2 py-8">
      <div className="flex flex-col lg:flex-row mb-10">
        <div className="lg:w-1/2 h-full ">
          <ImageGallery
            productData={productData}
            selectedVariant={selectedVariant}
          />
        </div>
        <div className="lg:w-[60%] lg:pl-8">
          <ProductInfo
            productData={productData}
            totalRating={data?.totalRating}
            variant={variant}
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            variantId={variantId}
          />
        </div>
      </div>

      {variant.length > 1 && (
        <div className="mt-16 pt-10">
          <p className="text-[#212121] text-3xl font-semibold mb-6">
            Product Variants
          </p>
          <PrimeCarousel
            variants={variant}
            handleVariantSelected={handleVariantSelected}
          />
        </div>
      )}

      <div className="lg:w-full border-t border-gray-300 mt-12">
        <ExtraDetailsPage
          productData={productData}
          selectedVariant={selectedVariant}
        />
      </div>
      <div className="w-full border-t border-gray-300 mt-16"></div>
      <div className="mt-12">
        <ReviewsSection
          reviewData={reviewData}
          totalRating={data?.totalRating}
          totalReview={data?.totalReview}
          averageRating={data?.averageRating}
          ratingDistribution={data?.ratingDistribution}
          totalPages={data?.totalPages}
          setReviewPage={setReviewPage}
          currentPage={reviewPage}
        />
      </div>
    </div>
  );
};

export default ProductPage;
